<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field"
    >
        <div v-if="config.label" class="label">
            <div class="main-label">
                <span class="whitespace-pre-line">{{ config.label }}</span>
                <span v-if="config.rules && config.rules.required" class="star">
                    *
                </span>
            </div>
            <div
                class="description-label"
                :style="{
                    'font-size': `${
                        config.supplement && config.supplement.fontSize
                    }px`,
                }"
            >
                {{ config.supplement && config.supplement.label }}
            </div>
        </div>
        <div
            v-if="isEditMode && config.prefix"
            class="prefix"
            v-html="config.prefix"
        ></div>
        <el-form-item v-if="isEditMode" :prop="config.fieldName" :rules="rules">
            <el-input
                :value="value"
                class="input"
                :style="{ width: `${config.width}px` }"
                :placeholder="config.placeholder || '請輸入'"
                @input="$emit('input', $event)"
                @change="$emit('change', value)"
            ></el-input>
        </el-form-item>
        <div v-else-if="value" class="input-preview">
            <div
                v-if="value && config.prefix"
                class="prefix"
                v-html="config.prefix"
            ></div>
            <div class="text">{{ value }}</div>
            <div
                v-if="value && config.suffix"
                class="suffix"
                v-html="config.suffix"
            ></div>
        </div>
        <div
            v-if="isEditMode && config.suffix"
            class="suffix"
            v-html="config.suffix"
        ></div>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralQuestionField';

export default {
    mixins: [GeneralMixins],
};
</script>

<style lang="scss" scope>
.input-preview{
    .text{
        word-break: break-all;
    }
}

</style>
