<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field combo-field"
    >
        <div v-if="config.label" class="label">
            <div class="main-label">
                <span>{{ config.label }}</span>
                <span v-if="config.rules && config.rules.required" class="star">
                    *
                </span>
            </div>
            <div
                class="description-label"
                :style="{
                    'font-size': `${
                        config.supplement && config.supplement.fontSize
                    }px`,
                }"
            >
                {{ config.supplement && config.supplement.label }}
            </div>
        </div>
        <div class="combo-child-field" :class="{ column: config.column }">
            <component
                v-for="(render, i) in config.children"
                :key="i"
                v-model="data[render.fieldName]"
                :is="render.component"
                :config="render"
                :mode="mode"
                class="combo-child"
                @change="handleChange"
            ></component>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    props: {
        value: undefined,
        mode: {
            type: String,
            default: '',
        },
        config: {
            type: Object,
            default: () => {},
        },
        depend: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            data: {},
        };
    },
    created() {
        _.forEach(this.value, (value, key) => {
            this.$set(this.data, `${key}`, value);
        });
    },
    methods: {
        handleChange() {
            this.$emit('change', this.data);
        },
    },
};
</script>
