<template>
    <h1
        v-if="isDependExist"
        class="sm:text-3xl text-xl font-bold sm:mt-12 sm:mb-8"
        v-html="label"
    ></h1>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralDisplay';

export default {
    mixins: [GeneralMixins],
    computed: {
        isDependExist() {
            if (!this.config.depend) return true;

            let RETURN;
            this.config.depend.forEach(fieldName => {
                if (!RETURN && this.value[fieldName]) RETURN = true;
            });
            return RETURN;
        }
    }
};
</script>
