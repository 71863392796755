const LIMIT = 20;

export default {
    namespaced: true,
    state: {
        // sales
        salesList: [],
        caseList: [],
        // general
        state: undefined,
        query: '',
        target: undefined,
        page: 1,
        infiniteId: +new Date(),
        // filter
        filterState: {
            label: 'state',
            value: undefined,
        },
        filterText: {
            label: 'text',
            value: undefined,
        },
    },
    mutations: {
        updateFilterState(state, change) {
            state.filterState.value = change;
        },
        updateFilterText(state, change) {
            state.filterText.value = change;
        },
        updateFilterTextLabel(state, change) {
            state.filterText.label = change;
        },
        setSalesList(state, data) {
            state.salesList.push(...data);
        },
        setCaseList(state, data) {
            state.caseList.push(...data);
        },
        updateQuery(state, query) {
            state.query = query;
        },
        increasePage(state) {
            state.page += 1;
        },
        updateInfiniteId(state) {
            state.infiniteId += 1;
        },
        setTarget(state, change) {
            state.target = change;
        },
        clear(state) {
            // sales
            state.salesList = [];
            state.caseList = [];
            // general
            state.state = undefined;
            state.query = '';
            state.target = undefined;
            state.page = 1;
            state.infiniteId = +new Date();
            // filter
            state.filterState = {
                label: 'state',
                value: undefined,
            };
            state.filterText = {
                label: 'text',
                value: undefined,
            };
        },
        resetList(state) {
            state.page = 1;
            state.caseList = [];
            state.salesList = [];
            state.query = `?limit=${LIMIT}&page=${state.page}`;
        },
    },
    actions: {
        async createSales({ commit, dispatch }, data) {
            const PAYLOAD = {
                category: 'Account',
                function: 'createSales',
                successMsg: '成功創立PJP',
                data,
            };
            const RES = await dispatch('api/invoke', PAYLOAD, { root: true });
            if (RES) {
                commit('popup/updateCreateSalesPopUpState', false, { root: true });
                dispatch('updateFilter');
            }
        },
        async updateSales({ commit, dispatch }, data) {
            const PAYLOAD = {
                category: 'Account',
                function: 'updateSales',
                successMsg: '成功創立PJP',
                data,
            };
            const RES = await dispatch('api/invoke', PAYLOAD, { root: true });
            if (RES) {
                commit('popup/updateCreateSalesPopUpState', false, { root: true });
                dispatch('updateFilter');
            }
        },
        async transferCase({ dispatch }, data) {
            const PAYLOAD = {
                category: 'Account',
                function: 'transferCase',
                successMsg: '案例已轉移',
                data,
            };
            await dispatch('api/invoke', PAYLOAD, { root: true });
            dispatch('updateFilter');
        },
        async onSearchCase({ state, commit, dispatch }) {
            const PAYLOAD = {
                category: 'Case',
                function: 'searchManagerCase',
                query: state.query,
            };
            const RES = await dispatch('api/invokeSearch', PAYLOAD, { root: true });
            commit('setCaseList', RES.data.rows);
            return RES.data.rows.length;
        },
        async onSearchSales({ state, commit, dispatch }) {
            const PAYLOAD = {
                category: 'Account',
                function: 'searchSales',
                query: state.query,
            };
            const RES = await dispatch('api/invokeSearch', PAYLOAD, { root: true });
            commit('setSalesList', RES.data);
            return RES.data.length;
        },
        fetchQuery({ state, commit }) {
            let text = `?limit=${LIMIT}&page=${state.page}`;
            const ITEMS = [
                state.filterState,
                state.filterText,
            ];
            ITEMS.forEach((item) => {
                if (item.label !== 'organizationId' && !item.value) return;
                if (text.length !== 1) text += `&${item.label}=${item.value}`;
                else text += `${item.label}=${item.value}`;
            });
            commit('updateQuery', text);
        },
        updateFilter({ commit, dispatch }) {
            commit('resetList');
            commit('updateInfiniteId');
            dispatch('fetchQuery');
        },
        checkState({ state, commit }, caseState) {
            state.state = caseState;
            switch (caseState) {
                case 'review':
                case 'approve':
                case 'display':
                    commit('form/setMode', 'VIEW', { root: true });
                    break;
                default:
            }
        },
    },
};
