<template>
    <pop-up
        background-color="rgba(0, 0, 0, 0.7)"
        width="700px"
        :on-close="onCancel"
    >
        <div class="text">
            <div class="title">編輯圖片</div>
        </div>
        <div class="main">
            <cropper :src="img" @change="change" class="cropper" />
            <div class="button-group">
                <button-wrapper type="modal" color="primary" @click="onSubmit">
                    確定
                </button-wrapper>
            </div>
        </div>
    </pop-up>
</template>

<script>
import PopUp from '@/components/Popup/PopUpWrapper.vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { mapActions } from 'vuex';

function dataURI2File(dataURI, fileName, canvas, rate) {
    const byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }

    const BLOB = new Blob([ab], { type: mimeString });
    const LIMITED = 200000;
    if (BLOB.size > LIMITED && rate === 1) {
        const RATE = rate * 0.5;
        const URL = canvas.toDataURL('image/jpeg', RATE);
        return dataURI2File(URL, fileName, canvas, RATE);
    }
    return new File([BLOB], fileName, { type: mimeString });
}

export default {
    components: { PopUp, Cropper },
    props: {
        img: String,
        fileName: String,
    },

    data() {
        return {
            imgUrl: 'https://norserium.github.io/vue-advanced-cropper/assets/img/photo.59242653.jpeg',
            imgDataUrl: undefined,
            canvasEl: undefined,
        };
    },
    methods: {
        ...mapActions({
            apiClient: 'api/invoke',
        }),
        onSubmit() {
            this.onConfirm(this.imgDataUrl);
        },
        async onConfirm(raw) {
            try {
                const FILE = dataURI2File(raw, this.fileName, this.canvasEl, 1);
                const data = new FormData();
                data.append('file', FILE);
                const PAYLOAD = {
                    category: 'Util',
                    function: 'uploadImage',
                    data,
                };
                const RES = await this.apiClient(PAYLOAD);
                this.$emit('confirm', RES.data.url);

                if (this.value) {
                    const EMIT = this.value;
                    EMIT.url = RES.data.url;
                    this.$emit('change', EMIT);
                    this.$emit('input', EMIT);
                } else {
                    this.$emit('change', { url: RES.data.url });
                    this.$emit('input', { url: RES.data.url });
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.cropperPopup = false;
            }
        },
        change({ canvas }) {
            this.imgDataUrl = canvas.toDataURL();
            this.canvasEl = canvas;
        },
        onCancel() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    color: #000000;
    font-size: 24px;
    line-height: 35px;
}
.main {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .button-group {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
    }
    .cropper {
        width: fit-content;
        height: 300px;
    }
}
</style>
