const LIMIT = 20;

export default {
    namespaced: true,
    state: {
        // sales
        caseList: [],
        // general
        accountId: '',
        query: '',
        target: undefined,
        page: 1,
        infiniteId: +new Date(),
        // filter
        filterState: {
            label: 'state',
            value: undefined,
        },
        filterText: {
            label: 'text',
            value: undefined,
        },
    },
    mutations: {
        updateFilterState(state, change) {
            state.filterState.value = change;
        },
        updateFilterText(state, change) {
            state.filterText.value = change;
        },
        setCaseList(state, data) {
            state.caseList.push(...data);
        },
        updateQuery(state, query) {
            state.query = query;
        },
        increasePage(state) {
            state.page += 1;
        },
        updateInfiniteId(state) {
            state.infiniteId += 1;
        },
        updateAccountId(state, change) {
            state.accountId = change;
        },
        setTarget(state, change) {
            state.target = change;
        },
        clear(state) {
            // sales
            state.caseList = [];
            // general
            state.accountId = '';
            state.query = '';
            state.target = undefined;
            state.page = 1;
            state.infiniteId = +new Date();
        },
        resetList(state) {
            state.page = 1;
            state.caseList = [];
            state.query = `?limit=${LIMIT}&page=${state.page}`;
        },
    },
    actions: {
        async createCase({ commit, dispatch }, data) {
            const PAYLOAD = {
                category: 'Case',
                function: 'create',
                successMsg: '案例已新增',
                data,
            };
            const RES = await dispatch('api/invoke', PAYLOAD, { root: true });
            commit('setTarget', RES.data);
            dispatch('updateFilter');
        },
        async onSearchCase({ state, commit, dispatch }) {
            const PAYLOAD = {
                category: 'Case',
                function: 'searchSalesSelfCase',
                query: state.query,
            };
            const RES = await dispatch('api/invokeSearch', PAYLOAD, { root: true });
            commit('setCaseList', RES.data.rows);
            return RES.data.rows.length;
        },
        fetchQuery({ state, commit, rootState }) {
            let text = `?limit=${LIMIT}&page=${state.page}&accountId=${rootState.auth.user.id}`;
            const ITEMS = [
                state.filterState,
                state.filterText,
            ];
            ITEMS.forEach((item) => {
                if (item.label !== 'organizationId' && !item.value) return;
                if (text.length !== 1) text += `&${item.label}=${item.value}`;
                else text += `${item.label}=${item.value}`;
            });
            commit('updateQuery', text);
        },
        updateFilter({ commit, dispatch }) {
            commit('resetList');
            commit('updateInfiniteId');
            dispatch('fetchQuery');
        },
    },
};
