<template>
    <div :id="config.fieldName" class="question-field radio-select">
        <el-radio-group
            v-if="isEditMode"
            v-model="selection"
            :class="{ column: config.column, row: !config.column }"
            @change="handleStoreData"
        >
            <el-radio
                v-for="(item, i) in config.selections"
                :key="i"
                :label="item.value"
                class="radio"
            >
                <img
                    :src="require(`@/assets/view/uc/${item.value}.png`)"
                    alt=""
                    width="150px"
                />
            </el-radio>
        </el-radio-group>
        <div v-else-if="`${value}`" class="input-preview">
            <img
                :src="require(`@/assets/view/uc/${value}.png`)"
                alt=""
                width="150px"
            />
        </div>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralQuestionField';
import SelectMixins from '@/mixins/SelectQuestion';

export default {
    mixins: [GeneralMixins, SelectMixins],
    data() {
        return {
            selection: null,
            customAnswer: {},
        };
    },
    created() {
        this.initialSelection();
    },
    methods: {
        initialSelection() {
            const SELECTIONS = this.config.selections;
            if (!this.value) {
                // set first option
                const FIRST_OPTION_VALUE = SELECTIONS && SELECTIONS[0].value;
                this.$emit('input', FIRST_OPTION_VALUE);
                this.selection = FIRST_OPTION_VALUE;
                return;
            }

            const RADIO = this.originalOption(SELECTIONS, this.value);
            if (RADIO) {
                this.selection = this.value;
            } else {
                const DROPDOWN = this.dropdownOption(SELECTIONS, this.value);
                const DATE = this.dateOption(SELECTIONS, this.value);
                if (DROPDOWN) {
                    this.selection = DROPDOWN.value;
                    this.$set(this.customAnswer, this.selection, this.value);
                } else if (DATE) {
                    const VALUE = this.value.split(', ')[1];
                    this.selection = DATE.value;
                    this.$set(this.customAnswer, this.selection, VALUE);
                } else {
                    const TEXT = this.textOption(SELECTIONS);
                    this.selection = TEXT.value;
                    this.$set(this.customAnswer, this.selection, this.value);
                }
            }
        },
        handleStoreData(label) {
            if (this.customAnswer[label]) this.$emit('change', this.customAnswer[label]);
            else this.$emit('change', label);
        },
    },
};
</script>

<style lang="scss" scoped>
.radio-select {
    margin-bottom: 30px !important;
    .column {
        max-width: 100%;
    }
    .row {
        flex: 1;
        margin: -30px 0 0 -30px;
        > * {
            margin: 30px 0 0 30px;
        }
        .el-radio {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    /deep/ .el-radio__label {
        word-break: break-all;
        white-space: pre-line;
        line-height: 1.5;
    }
    /deep/ .el-radio__input {
        margin-bottom: 20px;
    }
}
</style>
