export default {
    namespaced: true,
    state: {
        list: [],
        target: undefined,
    },
    mutations: {
        setTarget(state, change) {
            state.target = change;
        },
        updateList(state, list) {
            state.list = list;
        },
    },
    actions: {
        async createKeyMessage({ dispatch }, data) {
            const PAYLOAD = {
                category: 'KeyMessage',
                function: 'create',
                successMsg: '成功創立Key Message',
                data,
            };
            await dispatch('api/invoke', PAYLOAD, { root: true });
            await dispatch('getKeyMessageList');
        },
        async updateKeyMessage({ dispatch }, data) {
            const PAYLOAD = {
                category: 'KeyMessage',
                function: 'update',
                successMsg: 'Key Message已更新',
                data,
            };
            await dispatch('api/invoke', PAYLOAD, { root: true });
            await dispatch('getKeyMessageList');
        },
        async getKeyMessageList({ commit, dispatch }) {
            const PAYLOAD = {
                category: 'KeyMessage',
                function: 'search',
            };
            const RES = await dispatch('api/invoke', PAYLOAD, { root: true });
            commit('updateList', RES.data);
        },
    },
};
