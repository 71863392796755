<template>
    <div class="overview-data-table">
        <div v-for="(cell, i) in config" :key="i" class="cell">
            <div class="label">{{ cell.label }}</div>
            <div class="value">
                <span v-if="cell.type === 'exist'">
                    {{ calculateExistValue(cell.sample) }}
                </span>
                <span v-else-if="Array.isArray(value[cell.fieldName])">
                    {{ value[cell.fieldName].join(", ") }}
                </span>
                <span v-else>{{ value[cell.fieldName] || 'N/A'}}</span>
                <span> {{ cell.suffix }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        config: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        calculateExistValue(sample) {
            const RETURN = [];
            sample.forEach((element) => {
                if (this.value[element.fieldName]) RETURN.push(element.label);
            });
            return RETURN.length ? RETURN.join(', ') : '無';
        },
    },
};
</script>

<style lang="scss" scoped>
.overview-data-table {
    > :first-child {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        border-top: 2px solid #e9e9e9;
    }
    > :last-child {
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
    }
    > div {
        border-left: 2px solid #e9e9e9;
        border-right: 2px solid #e9e9e9;
        border-bottom: 2px solid #e9e9e9;
    }
    > :nth-child(even) {
        background-color: #e9e9e9;
    }
    .cell {
        display: flex;
        font-size: 15px;
        height: 25px;
        > div {
            flex: 1;
            box-sizing: border-box;
            overflow: auto;
            padding: 0 5px;
        }
        > :first-child {
            border-right: 3px solid #e9e9e9;
        }
    }
}
</style>
