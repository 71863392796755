<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field amount-change-field"
    >
        <div v-if="config.label" class="label">
            <div class="main-label">
                <span>{{ config.label }}</span>
                <span v-if="config.rules && config.rules.required" class="star">
                    *
                </span>
            </div>
            <div
                class="description-label"
                :style="{
                    'font-size': `${
                        config.supplement && config.supplement.fontSize
                    }px`,
                }"
            >
                {{ config.supplement && config.supplement.label }}
            </div>
        </div>
        <div class="question-column">
            <div
                v-for="(children, i) in column"
                :key="i"
                class="question-row"
                :class="{ 'question-field': isEditMode }"
            >
                <div
                    v-if="config.indexLabel && column.length !== 1"
                    class="index-label"
                >
                    {{ i + 1 }}.
                </div>
                <div class="questions" :class="{ column: config.column }" :style="{ '--cols': config.cols }">
                    <component
                        v-for="(render, j) in children"
                        :key="j"
                        v-model="data[i][render.fieldName]"
                        :is="render.component"
                        :config="render"
                        :mode="mode"
                        @change="(input) => handleChange(input, i, render.fieldName)"
                    ></component>
                </div>
                <el-button
                    v-if="isEditMode && column.length > 1"
                    class="danger-bg-button custom-button"
                    icon="el-icon-minus"
                    circle
                    @click="handleDecreaseColumn(i)"
                >
                </el-button>
                <div v-else></div>
            </div>
            <div v-if="isEditMode" class="add-button">
                <el-button
                    class="save-bg-button custom-button"
                    icon="el-icon-plus"
                    circle
                    @click="handleAddColumn"
                ></el-button>
                <span class="label">{{ config.addButtonLabel }}</span>
            </div>
        </div>
    </div>
</template>

<script>
// import _ from 'lodash';

export default {
    props: {
        value: undefined,
        mode: {
            type: String,
            default: '',
        },
        config: {
            type: Object,
            default: () => {},
        },
        depend: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        isEditMode() {
            return this.mode === 'EDIT';
        },
    },
    data() {
        return {
            data: [],
            column: [],
            componentPartner: [],
        };
    },
    created() {
        if (this.value) {
            this.config.children.forEach((child) => {
                this.componentPartner.push(child);
            });
            this.value.forEach((col) => {
                this.data.push(col);
                this.column.push([...this.componentPartner]);
            });
        } else {
            const DATA_CHILD = {};
            this.column = [this.config.children];
            this.config.children.forEach((child) => {
                this.componentPartner.push(child);
                DATA_CHILD[child.fieldName] = undefined;
            });
            this.data.push(DATA_CHILD);
        }
    },
    methods: {
        handleChange(value, i, fieldName) {
            if (value instanceof Array) {
                // v-model 不知道為何失效，手動set值
                this.data[i][fieldName] = value;
            }
            this.$emit('change', this.data);
        },
        handleAddColumn() {
            this.column.push([]);
            this.data.push({});
            const LAST_INDEX = this.column.length - 1;
            this.componentPartner.forEach((item) => {
                this.column[LAST_INDEX].push(item);
                this.$set(
                    this.data[LAST_INDEX],
                    `${item.fieldName}`,
                    undefined,
                );
            });
        },
        handleDecreaseColumn(row) {
            this.column.splice(row, 1);
            this.data.splice(row, 1);
            this.$emit('change', this.data);
        },
        isDataEmpty(sample) {
            return JSON.stringify(sample) !== '{}';
        },
    },
};
</script>

<style lang="scss" scoped>
.amount-change-field {
    .question-row {
        display: flex;
        // align-items: center;
        > * {
            margin-right: 10px;
            margin-top: 0;
            margin-bottom: 0;
        }
        > :last-child {
            margin-right: 0px;
            margin-left: 30px;
        }
        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }
    .questions {
        display: grid;
        grid-template-columns: repeat(var(--cols), auto);
        margin: -10px 0 0 -10px;
        &.column {
            flex-direction: column;
            > * {
                height: unset;
            }
        }
        > * {
            margin: 10px 0 0 10px;
            &:empty {
                margin: 0;
            }
        }
    }
    .custom-button {
        width: fit-content;
        height: fit-content;
        padding: 5px;
        color: #fff;
    }
    .index-label {
        width: 20px;
        margin-top: 0.4em;
    }
    .save-bg-button {
        margin-left: 30px;
        margin-top: 0.4em;
    }
    .add-button .label {
        margin-left: 20px;
    }
}
</style>
