export default {
    path: '/:disease/doctor/:id',
    redirect: { name: 'Doctor_Login' },
    component: () => import('../views/Doctor/index.vue'),
    children: [
        {
            name: 'Doctor_Login',
            path: 'login',
            component: () => import('../views/Doctor/Login.vue'),
        },
        {
            name: 'Doctor_Intro',
            path: 'introduction',
            component: () => import('../views/Doctor/Introduction.vue'),
        },
        {
            name: 'Doctor_Form',
            path: 'form',
            component: () => import('../views/Doctor/Form.vue'),
        },
        {
            path: '*',
            redirect: { name: 'Doctor_Login' },
        },
    ],
};
