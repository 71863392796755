import Vue from 'vue';
import VueRouter from 'vue-router';

import Manager from './manager';
import Doctor from './doctor';
import DoctorLine from './doctor-line';
import Sales from './sales';


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue'),
    },
    {
        path: '/:disease/clm-login',
        name: 'CLMLogin',
        component: () => import('../views/CLMLogin.vue'),
    },
    Doctor,
    DoctorLine,
    Manager,
    Sales,
    {
        path: '/super',
        name: 'Super',
        component: () => import('../views/SuperAdmin/index.vue'),
        children: [
            {
                path: 'console',
                name: 'Super_Console',
                component: () => import('../views/SuperAdmin/Console.vue'),
            },
        ],
    },
    {
        path: '*',
        redirect: '/',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
