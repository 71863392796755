<template>
    <div class="uc-pre-post-compare">
        <div class="pre-treat-section">
            <div class="title">Pre-treat</div>
            <div class="data-section">
                <div
                    v-for="(block, index) in pre"
                    :key="index"
                    class="treat-block"
                    :class="{ fluid: pre.length === 1 }"
                >
                    <div class="date">{{ block.date || "-" }}</div>
                    <div class="image">
                        <img :src="block.url" alt="" width="100%" />
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow">
            <img src="@/assets/view/uc/logo.png" alt="" width="100%" />
            <img src="@/assets/view/uc/arrow.png" alt="" width="100%" />
            <div class="from-date">From {{ month }}, {{ day }}</div>
        </div>
        <div class="post-treat-section">
            <div class="title">Post-treat</div>
            <div class="data-section">
                <div
                    v-for="(block, index) in post"
                    :key="index"
                    class="treat-block"
                    :class="{ fluid: post.length === 1 }"
                >
                    <div class="date">{{ block.date || "-" }}</div>
                    <div class="image">
                        <img :src="block.url" alt="" width="100%" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralDisplay';
import moment from 'moment';
import _ from 'lodash';

export default {
    mixins: [GeneralMixins],
    computed: {
        pre() {
            const DATASET = [];
            _.forEach(this.value.preCT_MRI, (value) => {
                DATASET.push(value);
            });
            return DATASET;
        },
        post() {
            const DATASET = [];
            _.forEach(this.value.postCT_MRI, (value) => {
                DATASET.push(value);
            });
            return DATASET;
        },
        month() {
            if (this.value.dateDisplayAbove) {
                return moment(this.value.dateDisplayAbove).format('MMM');
            }
            return '-';
        },
        day() {
            if (this.value.dateDisplayAbove) {
                return moment(this.value.dateDisplayAbove).format('YYYY');
            }
            return '-';
        },
    },
};
</script>

<style lang="scss" scoped>
.uc-pre-post-compare {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.pre-treat-section,
.post-treat-section {
    width: 42%;
    .title {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 25px;
        margin-bottom: 40px;
    }
    .data-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        > :nth-child(3),
        > :nth-child(4) {
            margin-top: 20px;
        }
    }
}

.arrow {
    flex: 1;
    margin-top: 80px;
    max-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.treat-block {
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.fluid {
        width: 100%;
    }
    .date {
        margin-bottom: 10px;
    }
}

.from-date {
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    word-break: keep-all;
}
</style>
