<template>
    <pop-up
        background-color="rgba(0, 0, 0, 0.7)"
        :on-close="onCancel"
    >
        <div class="text">
            <div class="title">{{ title }}</div>
            <div class="subtitle">{{ subtitle }}</div>
        </div>
        <div class="button-set">
            <button-wrapper v-if="cancel" type="modal" color="cancel" @click="onCancel">
                {{ cancel }}
            </button-wrapper>
            <button-wrapper type="modal" color="primary" @click="onConfirm">
                {{ confirm }}
            </button-wrapper>
        </div>
    </pop-up>
</template>

<script>
import PopUp from '@/components/Popup/PopUpWrapper.vue';
import { mapState, mapMutations } from 'vuex';

export default {
    components: { PopUp },
    computed: {
        ...mapState({
            cancel: (state) => state.popup.confirmationText.cancel,
            confirm: (state) => state.popup.confirmationText.confirm,
            title: (state) => state.popup.confirmationText.title,
            subtitle: (state) => state.popup.confirmationText.subtitle,
            sucessMessage: (state) => state.popup.confirmationText.sucessMessage,

            confirmationFunction: (state) => state.popup.confirmationFunction,
        }),
    },
    methods: {
        ...mapMutations({
            // updateLoadingPopUpState: 'popup/updateLoadingPopUpState',
            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationFunction:
                'popup/updateConfirmationFunction',
        }),
        async onConfirm() {
            try {
                // this.updateLoadingPopUpState(true);
                await this.confirmationFunction();
                this.updateConfirmationFunction(undefined);
                this.updateConfirmationState(false);
                if (this.sucessMessage) {
                    this.$message({
                        message: this.sucessMessage,
                        type: 'success',
                    });
                }
            } catch (e) {
                this.$message({
                    message: e.response.data.msg,
                    type: 'error',
                });
            } finally {
                // this.updateLoadingPopUpState(false);
            }
        },
        onCancel() {
            this.updateConfirmationState(false);
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    color: #000000;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 4px;
}
.subtitle {
    color: #000000;
    margin-top: 15px;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: 4px;
}
</style>
