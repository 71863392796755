<template>
    <div class="pop-up">
        <div class="mask"></div>
        <div class="content">
            <div>
                <loading />
            </div>
        </div>
    </div>
</template>
<script>
import Loading from '@/components/Loading.vue';
export default {
    components: {
        Loading
    }
};
</script>
<style lang="scss" scoped>
.pop-up {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    .mask {
        width: 100%;
        height: 100%;
    }
    .close {
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        position: absolute;
        z-index: 6;
    }
    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
