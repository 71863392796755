export default {
    headline: true,
    table: [
        {
            label: '醫師',
            fieldName: 'doctorName',
        },
        {
            label: '1L/2L',
            fieldName: '1L2L',
        },
        {
            label: '腎上老人',
            fieldName: 'category',
        },
        {
            label: 'OS',
            fieldName: 'OS.value',
        },
        {
            label: 'Date',
            fieldName: 'date',
        },
    ],
    tags: [
        {
            label: '腎',
            destination: {
                fieldName: 'category',
                value: '腎',
            },
        },
        {
            label: '上',
            destination: {
                fieldName: 'category',
                value: '上',
            },
        },
        {
            label: '老',
            destination: {
                fieldName: 'category',
                value: '老',
            },
        },
        {
            label: '人',
            destination: {
                fieldName: 'category',
                value: '人',
            },
        },
        {
            label: 'CR',
            destination: {
                fieldName: 'category',
                value: 'CR',
            },
        },
        {
            label: 'PR',
            destination: {
                fieldName: 'category',
                value: 'PR',
            },
        },
    ],
};
