<template>
    <div
        class="overview-data-graphic"
        :class="{
            'justify-between': config.data.length > 0,
            'justify-center': config.data.length === 0
        }"
    >
        <img
            v-if="config.image"
            :src="require(`@/assets/view/${disease}/graphic.png`)"
            alt=""
            class="image"
            width="100%"
        />
        <div class="data-block" v-if="config.data.length > 0">
            <div
                v-for="(data, i) in config.data"
                :key="i"
                class="data"
                :class="{ highlight: !i && config.highlight }"
                :style="{
                    'background-color': !i && config.highlight && config.highlight.bg_color,
                    color: !i && config.highlight && config.highlight.color
                }"
            >
                <span v-if="data.type === 'length'">
                    {{ data.label
                    }}{{ (value[data.fieldName] && value[data.fieldName].length) || 0 }}
                </span>
                <span v-else-if="data.type === 'exist'">
                    {{ data.label }}{{ calculateExistValue(data.sample) }}
                </span>
                <span v-else-if="data.type === 'description'" v-html="data.label"> </span>
                <span v-else-if="data.type === 'input-boolean'" class="input-boolean">
                    {{ data.label }} <br />
                    {{ value[data.fieldName].value }}
                    <span v-if="value[data.fieldName].boolean">{{ data.symbol }}</span>
                </span>
                <span v-else-if="data.type === 'number'">
                    {{ `${data.label} ${value[data.fieldName] || 0}` }}
                </span>
                <span v-else>
                    {{ `${data.label} ${value[data.fieldName]}` }}
                </span>
                <span> {{ data.suffix }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        disease() {
            return this.$route.params.disease;
        }
    },
    methods: {
        calculateExistValue(sample) {
            const RETURN = [];
            sample.forEach(element => {
                if (this.value[element.fieldName]) RETURN.push(element.label);
            });
            return RETURN.length ? RETURN.join(', ') : '無';
        }
    }
};
</script>

<style lang="scss" scoped>
.overview-data-graphic {
    width: 100%;
    height: 240px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    .image {
        width: 45%;
        margin-right: 5%;
    }
    .data-block {
        flex: 1;
        padding: 5px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .data {
            font-size: 16px;
            text-align: center;
            width: 100%;
            font-weight: 700;
        }
        .highlight {
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            padding: 3px 10px;
            border-radius: 16px;
            white-space: nowrap;
        }
        > :nth-child(2) {
            border-top: 1px dashed;
            border-bottom: 1px dashed;
            padding: 10px 0;
            margin: 10px 0;
        }
    }
}
</style>
