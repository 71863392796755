<template>
    <div class="overview-data">
        <div class="upper-block">
            <div class="date">{{ value.date }}</div>
            <div class="disclaimer">
                未經同意請勿轉載，僅供經驗分享參考，不能取代臨床醫師專業判斷
            </div>
            <div class="title">{{ value.title }}</div>
        </div>
        <div class="divider"></div>
        <div class="bottom-block">
            <div class="left-block">
                <div class="doctor-info">
                    <div class="avatar">
                        <img
                            :src="
                                value.doctorAvatar ||
                                require('@/assets/logo.png')
                            "
                            alt=""
                        />
                    </div>
                    <div class="text">
                        <div class="doctor-name">
                            {{ value.doctorName }}
                        </div>
                        <div class="hospital">
                            {{ value.hospital }}
                        </div>
                    </div>
                </div>
                <div class="description">
                    <div>&#9679;</div>
                    Patient demographic
                </div>
                <div class="table-section">
                    <OverviewDataTable
                        :config="config.tableData"
                        :value="value"
                    />
                </div>
            </div>
            <div class="right-block">
                <OverviewDataTag :config="config.tags" :value="value" />
                <OverviewDataLogo :config="config.logo" :value="value" />
                <OverviewDataGraphic :config="config.graphic" :value="value" />
            </div>
        </div>
        <transition name="fade" ease="in-out">
            <Fireworks v-if="isOsWorthIt && !isSkip" @skip="onSkipFireworks" />
        </transition>
    </div>
</template>

<script>
import Fireworks from '@/components/UC/Fireworks.vue';

import OverviewDataTable from '@/components/UC/OverviewDataTable.vue';
import OverviewDataTag from '@/components/UC/OverviewDataTag.vue';
import OverviewDataLogo from '@/components/UC/OverviewDataLogo.vue';
import OverviewDataGraphic from '@/components/UC/OverviewDataGraphic.vue';

import GeneralMixins from '@/mixins/GeneralDisplay';

export default {
    components: {
        Fireworks,
        OverviewDataTable,
        OverviewDataTag,
        OverviewDataLogo,
        OverviewDataGraphic,
    },
    mixins: [GeneralMixins],
    data() {
        return {
            isSkip: false,
        };
    },
    computed: {
        isOsWorthIt() {
            return Number(this.value.OS.value) >= 24;
        },
    },
    methods: {
        onSkipFireworks() {
            this.isSkip = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.overview-data {
    .upper-block {
        display: grid;
        grid-template-columns: 100px auto;
        grid-template-rows: 40px auto;
        align-items: center;
        .date {
            font-size: 18px;
        }
        .title {
            font-size: 25px;
            font-weight: bold;
            grid-column: 1/-1;
            height: 36px;
            overflow: auto;
        }
        .disclaimer {
            font-size: 16px;
            color: #868686;
            justify-self: end;
        }
    }
    .divider {
        margin: 15px 0 10px 0;
        border-bottom: 1px solid #39425a;
    }
    .bottom-block {
        display: flex;
        .left-block {
            display: flex;
            flex-direction: column;
            .doctor-info {
                display: flex;
                margin-bottom: 10px;
                height: 100px;
                width: 520px;
                background: #ffffff;
                border-radius: 7px;
                align-items: center;
                .avatar {
                    margin: 0 15px;
                    display: flex;
                    img {
                        border-radius: 50%;
                        width: 70px;
                        height: 70px;
                    }
                }
                .text {
                    font-size: 22px;
                    font-weight: bold;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    > div {
                        height: 33px;
                        overflow: auto;
                    }
                }
            }
            .description {
                display: flex;
                align-items: center;
                font-size: 22px;
                margin-bottom: 20px;
                div {
                    font-size: 12px;
                    margin-right: 10px;
                }
            }
            .table-section {
                width: 520px;
                height: 335px;
                overflow: auto;
            }
        }
        .right-block {
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 100%;
            margin-left: 30px;
        }
    }
}
</style>
