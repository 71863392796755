export default [
    [
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'category',
                placeholder: '類型',
                options: [
                    {
                        label: 'CR',
                        value: 'CR',
                        type: 'normal',
                    },
                    {
                        label: 'PR',
                        value: 'PR',
                        type: 'normal',
                    },
                    {
                        label: 'SD且打滿10 cycles（以上）',
                        value: 'SD且打滿10 cycles↑',
                        type: 'normal',
                    },
                    {
                        label: 'ABC conversion',
                        value: 'ABC conversion',
                        type: 'normal',
                    }
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'gender',
                placeholder: 'Gender',
                options: [
                    {
                        label: '男',
                        value: 'Male',
                        type: 'normal',
                    },
                    {
                        label: '女',
                        value: 'Female',
                        type: 'normal',
                    },
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'BCLC',
                placeholder: 'Stage',
                options: [
                    {
                        label: 'A',
                        value: 'A',
                        type: 'normal',
                    },
                    {
                        label: 'B',
                        value: 'B',
                        type: 'normal',
                    },
                    {
                        label: 'C',
                        value: 'C',
                        type: 'normal',
                    }
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'cancerCategory',
                placeholder: 'Etiology',
                options: [
                    {
                        label: 'HBV',
                        value: 'HBV',
                        type: 'normal',
                    },
                    {
                        label: 'HCV',
                        value: 'HCV',
                        type: 'normal',
                    },
                    {
                        label: 'non-viral',
                        value: 'non-viral',
                        type: 'normal',
                    },
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'preTreat',
                placeholder: '先前做過的治療',
                options: [
                    {
                        label: 'TACE',
                        value: {
                            column: 'preTACE',
                            value: 'not null',
                        },
                        type: 'operator',
                    },
                    {
                        label: 'RFA',
                        value: {
                            column: 'preRFA',
                            value: 'not null',
                        },
                        type: 'operator',
                    },
                    {
                        label: 'Surgery',
                        value: {
                            column: 'preSurgery',
                            value: 'not null',
                        },
                        type: 'operator',
                    },
                    {
                        label: 'Other',
                        value: {
                            column: 'preOther',
                            value: 'not null',
                        },
                        type: 'operator',
                    },
                ],
            },
        },
    ],
];
