<template>
    <div v-if="!isAnimationComplete" class="fireworks">
        <div ref="lottie" />
    </div>
</template>

<script>
import lottie from 'lottie-web';

let anim;

export default {
    data() {
        return {
            isAnimationComplete: false,
            loopTime: 0,
        };
    },
    mounted() {
        this.setAnimation();
    },
    methods: {
        setAnimation() {
            anim = lottie.loadAnimation({
                container: this.$refs.lottie, // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '/UC_Firework/data.json', // the path to the animation json
            });
            anim.addEventListener('loopComplete', this.onLoopComplete);
        },
        onLoopComplete() {
            this.loopTime += 1;
            if (this.loopTime === 2) {
                this.isAnimationComplete = true;
                anim.stop();
                anim.removeEventListener('loopComplete', this.onLoopComplete);
            }
        },
        // skip function
        onSkipFireworksAnimation() {
            anim.removeEventListener('loopComplete', this.onLoopComplete);
            setTimeout(() => {
                anim.destroy();
            }, 3000);
            this.$emit('skip');
        },
    },
};
</script>

<style lang="scss" scoped>
.fireworks {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1025px;
    height: 80%;
}
</style>
