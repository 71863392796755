export default [
    [
        {
            component: 'uc-overview-data',
            tableData: [
                {
                    label: 'Gender',
                    fieldName: 'gender',
                },
                {
                    label: 'Age',
                    fieldName: 'age',
                },
                {
                    label: 'Other medical history',
                    fieldName: 'otherMedicalHistory',
                },
                {
                    label: 'eGFR',
                    fieldName: 'eGFR',
                },
                {
                    label: 'ECOG PS',
                    fieldName: 'ECOGPS',
                },
                {
                    label: 'PDL1 SP142 IC',
                    fieldName: 'PDL1SP142IC',
                },
                {
                    label: 'Site',
                    fieldName: 'site',
                },
                {
                    label: 'Pathology',
                    fieldName: 'pathology',
                },
            ],
            tags: ['category'],
            logo: {
                fieldName: 'dateDisplayAbove',
            },
            graphic: {
                data: [
                    {
                        fieldName: 'PFS',
                        label: 'PFS',
                        suffix: 'months',
                    },
                    {
                        fieldName: 'OS',
                        label: 'OS',
                        type: 'input-boolean',
                        symbol: '+',
                        suffix: 'months',
                    },
                ],
            },
        },
    ],
    [
        {
            component: 'uc-pre-post-compare',
        },
    ],
    [
        {
            component: 'title-block',
            label:
                'KeyMessage',
        },
        {
            component: 'key-message-display-block',
        },
    ],
    [
        {
            component: 'title-block',
            label: '我有話想說',
        },
        {
            fieldName: 'impression',
            component: 'original-data',
            column: true,
        },
    ],
];
