import Vue from 'vue';
import Vuex from 'vuex';

import form from './form';
import popup from './popup';
import api from './api';
import auth from './auth';
import manager from './manager';
import sales from './sales';
import display from './display';
import doctor from './doctor';
import config from './config';
import keyMessage from './keyMessage';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        form,
        popup,
        api,
        auth,
        manager,
        sales,
        doctor,
        config,
        display,
        keyMessage,
    },
});
