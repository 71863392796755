var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex gap-5 my-7 leading-10",class:{
        'flex-col': _vm.config.column
    }},[(_vm.config.label)?_c('span',{staticClass:"flex-1"},[_vm._v(_vm._s(_vm.config.label))]):_vm._e(),(_vm.config.type === 'simple')?_c('span',{staticClass:"flex-1 sm:flex-2"},[_vm._v(_vm._s(_vm.value && _vm.value.length && _vm.value.join(', ')))]):_c('ol',{staticClass:"flex-2",class:{
            'list-decimal': _vm.config.indexLabel,
            'pl-7': _vm.config.indexLabel
        }},_vm._l((_vm.value),function(child,i){return _c('li',{key:i},_vm._l((_vm.config.children),function(childConfig,j){return _c('span',{key:j},[(
                        j > 0 &&
                            child[childConfig.fieldName] &&
                            /[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(child[childConfig.fieldName])
                    )?_c('span',[_vm._v(" · ")]):_vm._e(),(childConfig.prefix && child[childConfig.fieldName])?_c('span',{staticClass:"text-gray-question"},[_vm._v(" "+_vm._s(childConfig.prefix)+" ")]):_vm._e(),(child[childConfig.fieldName])?_c('span',{staticClass:"mx-1",domProps:{"innerHTML":_vm._s(_vm.previewText(child[childConfig.fieldName]))}}):_vm._e(),(childConfig.suffix && child[childConfig.fieldName])?_c('span',{staticClass:"text-gray-question"},[_vm._v(" "+_vm._s(childConfig.suffix)+" ")]):_vm._e()])}),0)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }