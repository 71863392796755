<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field amount-change-checkbox-select"
    >
        <div v-if="config.label" class="label">
            <div class="main-label">
                <span>{{ config.label }}</span>
                <span v-if="config.rules && config.rules.required" class="star">
                    *
                </span>
            </div>
            <div
                class="description-label"
                :style="{
                    'font-size': `${
                        config.supplement && config.supplement.fontSize
                    }px`,
                }"
            >
                {{ config.supplement && config.supplement.label }}
            </div>
        </div>
        <div v-if="config.prefix" class="prefix" v-html="config.prefix"></div>
        <el-checkbox-group
            v-if="isEditMode"
            v-model="selections"
            @change="handleChange"
        >
            <div :class="{ column: config.column }">
                <el-checkbox
                    v-for="item in config.selections"
                    :key="item.value"
                    :label="item.value"
                >
                    {{ item.label }}
                </el-checkbox>
            </div>
            <div :class="{ column: config.column }">
                <el-checkbox
                    v-for="(item, i) in column"
                    :key="item.value"
                    :label="item.value"
                >
                    {{ item.label }}
                    <el-input
                        v-model="customAnswer[item.value]"
                        :style="{ 'max-width': `${item.width || 150}px` }"
                        :disabled="!selections.includes(item.value)"
                        @change="handleChange"
                    >
                    </el-input>
                    <el-button
                        v-if="column.length > 1 && i === column.length - 1"
                        class="danger-bg-button custom-button"
                        icon="el-icon-minus"
                        circle
                        @click="handleDecreaseColumn(i)"
                    >
                    </el-button>
                </el-checkbox>
                <el-button
                    class="save-bg-button custom-button"
                    icon="el-icon-plus"
                    circle
                    @click="handleAddColumn"
                >
                </el-button>
            </div>
        </el-checkbox-group>
        <div v-else-if="value" class="input-preview">
            <div v-for="(item, index) in value" :key="index">
                {{ item }}
            </div>
        </div>
        <div v-if="config.suffix" class="suffix" v-html="config.suffix"></div>
    </div>
</template>

<script>
import _ from 'lodash';
import GeneralMixins from '@/mixins/GeneralQuestionField';
import SelectMixins from '@/mixins/SelectQuestion';

export default {
    mixins: [GeneralMixins, SelectMixins],
    created() {
        this.fetchValue();
        this.init();
    },
    data() {
        return {
            selections: [],
            customAnswerCount: 1,
            customAnswer: {},
            column: [],
        };
    },
    methods: {
        init() {
            this.column.push({
                label: 'other',
                value: `other${this.customAnswerCount}`,
            });
            this.customAnswerCount += 1;
        },
        fetchValue() {
            if (!this.value) return;
            const SELECTIONS = this.config.selections;
            const OTHER_ANS = [];
            this.value.forEach((item) => {
                const ORIGINAL = this.originalOption(SELECTIONS, item);
                if (ORIGINAL) this.selections.push(item);
                else {
                    OTHER_ANS.push(item);
                }
            });
            OTHER_ANS.forEach((item, i) => {
                this.handleAddColumn();
                this.customAnswer[`other${i + 1}`] = item;
            });
        },
        handleChange() {
            const ANS = this.getCurrentSelectionValue();
            this.$emit('change', ANS);
        },
        getCurrentSelectionValue() {
            const ANS = [];
            this.selections.forEach((item) => {
                const SELECTION = this.getSelectionByValue(item);
                if (SELECTION) ANS.push(item);
                else ANS.push(this.customAnswer[item]);
            });
            return ANS;
        },
        getSelectionByValue(value) {
            return _.find(this.config.selections, ['value', value]);
        },
        handleAddColumn() {
            this.column.push({
                label: 'other',
                value: `other${this.customAnswerCount}`,
            });
            this.selections.push(`other${this.customAnswerCount}`);
            this.customAnswerCount += 1;
        },
        handleDecreaseColumn() {
            const COLUMN_LAST_INDEX = this.customAnswerCount - 2;
            const VALUE = this.column[this.customAnswerCount - 2].value;
            const SELECTIONS_TARGET_INDEX = this.selections.indexOf(VALUE);

            this.customAnswer[VALUE] = undefined;
            this.column.splice(COLUMN_LAST_INDEX, 1);
            this.selections.splice(SELECTIONS_TARGET_INDEX, 1);
            this.customAnswerCount -= 1;

            this.handleChange();
        },
    },
};
</script>

<style lang="scss" scoped>
.amount-change-checkbox-select {
    .custom-button {
        width: fit-content;
        height: fit-content;
        padding: 5px;
        color: #fff;
    }
    .danger-bg-button {
        margin-left: 10px;
    }
    .input-preview {
        display: flex;
        flex-direction: column;
        > * {
            margin-bottom: 10px;
        }
        :last-child {
            margin-bottom: 0;
        }
    }
}
</style>
