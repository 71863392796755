<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field picture-picker"
    >
        <el-date-picker
            v-if="isEditMode && config.date"
            v-model="date"
            :type="config.dateType || 'date'"
            class="date-picker"
            :value-format="dateFormat"
            style="width: 215px"
            :placeholder="config.placeholder || '請選擇'"
            @change="onChangeDate"
        >
        </el-date-picker>

        <div v-if="isEditMode" class="avatar-uploader">
            <i
                v-if="value && value.url"
                class="close el-icon-close danger-bg-button"
                @click="onDeleteImage"
            >
            </i>
            <el-upload
                v-if="isEditMode"
                action
                :http-request="uploadImage"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
            >
                <img v-if="imgUrl" :src="imgUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </div>
        <div v-else class="input-answer">
            <img :src="imgUrl" class="avatar" />
        </div>
        <div v-if="config.label" class="label">{{ config.label }}</div>

        <div v-if="config.suffix" class="suffix" v-html="config.suffix"></div>
        <transition name="fade" ease="in-out">
            <Cropper
                v-if="cropperPopup"
                :img="cropperImg"
                :fileName="fileName"
                @confirm="onConfirm"
                @close="cropperPopup = false"
            />
        </transition>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralQuestionField';
import Cropper from '@/components/Popup/Cropper.vue';

export default {
    components: { Cropper },
    mixins: [GeneralMixins],
    data() {
        return {
            date: '',
            imgUrl: '',
            cropperImg: '',
            cropperPopup: false,
            fileName: '',
        };
    },
    computed: {
        dateFormat() {
            if (this.config.dateType === 'month') return 'yyyy-MM';
            return 'yyyy-MM-dd';
        },
    },
    created() {
        this.date = this.value && this.value.date;
        this.imgUrl = this.value && this.value.url;
    },
    methods: {
        onChangeDate(val) {
            if (this.value) {
                const EMIT = this.value;
                EMIT.date = val;
                this.$emit('change', EMIT);
                this.$emit('input', EMIT);
            } else {
                this.$emit('change', { date: val });
                this.$emit('input', { date: val });
            }
        },
        async uploadImage(raw) {
            const reader = new FileReader();
            reader.readAsDataURL(raw.file);
            reader.onload = () => {
                this.cropperImg = reader.result;
                this.cropperPopup = true;
                this.fileName = raw.file.name;
            };
        },
        async onConfirm(url) {
            if (this.value) {
                const EMIT = this.value;
                EMIT.url = url;
                this.$emit('change', EMIT);
                this.$emit('input', EMIT);
            } else {
                this.$emit('change', { url });
                this.$emit('input', { url });
            }
            this.imgUrl = url;
            this.cropperPopup = false;
        },
        beforeAvatarUpload(file) {
            const LIMIT_SIZE = 15;
            const isValidateFormat = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < LIMIT_SIZE;

            if (!isValidateFormat) {
                this.$message.error('請選擇jpg或png格式');
            }
            if (!isLt2M) {
                this.$message.error(`檔案大小不可超過 ${LIMIT_SIZE}MB!`);
            }
            return isValidateFormat && isLt2M;
        },
        onDeleteImage() {
            if (this.value) {
                const EMIT = this.value;
                EMIT.url = undefined;
                this.$emit('change', EMIT);
                this.$emit('input', EMIT);
            } else {
                this.$emit('change', { url: undefined });
                this.$emit('input', { url: undefined });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.date-picker {
    margin-bottom: 20px;
}
.picture-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    .label {
        margin: 20px 0 0 0;
        text-align: center;
        width: 215px;
    }
}
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    .close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        width: fit-content;
        border-radius: 50%;
        padding: 5px;
        transform: translate(50%, -50%);
    }
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 215px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 215px;
    height: 178px;
    display: block;
}
</style>
