export default {
    data() {
        return {
            rules: [
                {
                    required: this.$route.name === 'Doctor_Form' && this.config.rules && this.config.rules.required,
                    message:
                        (this.config.rules && this.config.rules.message)
                        || '此欄位必填',
                },
                {
                    validator: (rule, value, callback) => {
                        const REG = this.config.rules && this.config.rules.regex;
                        const MSG = this.config.rules && this.config.rules.regexMsg;
                        if (REG && !value.match(REG)) {
                            callback(
                                new Error(MSG || '請輸入正確格式'),
                            );
                        } else callback();
                    },
                },
            ],
        };
    },
    props: {
        value: undefined,
        config: {
            type: Object,
            default: () => { },
        },
        mode: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        depend: {
            type: Object,
            default: () => { },
        },
    },
    computed: {
        isEditMode() {
            return this.mode === 'EDIT';
        },
    },
};
