<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field radio-select"
    >
        <div v-if="config.label" class="label">
            <span class="whitespace-pre-line">{{ config.label }}</span>
            <span v-if="config.rules && config.rules.required" class="star">
                *
            </span>
        </div>
        <div v-if="config.prefix" class="prefix" v-html="config.prefix"></div>
        <el-radio-group
            v-if="isEditMode"
            v-model="selection"
            :class="{ column: config.column, row: !config.column }"
            @change="handleStoreData"
        >
            <el-radio
                v-for="(item, i) in config.selections"
                :key="i"
                :label="item.value"
                class="radio"
            >
                {{ item.label }}
                <el-input
                    v-if="item.type === 'text'"
                    v-model="customAnswer[item.value]"
                    :style="`max-width: 200px; width: ${item.width}px`"
                    :disabled="selection !== item.value"
                    @change="handleChangeCustomAnswer"
                    placeholder="請輸入"
                ></el-input>
                <el-input
                    v-if="item.type === 'combo-text'"
                    v-model="customAnswer[item.value]"
                    :style="`max-width: 200px; width: ${item.width}px`"
                    :disabled="selection !== item.value"
                    placeholder="請輸入"
                    @change="
                        handleChangeCustomAnswer(
                            item.value.replace('{value}', customAnswer[item.value])
                        )
                    "
                ></el-input>
                <el-input
                    v-if="item.type === 'number'"
                    type="number"
                    v-model="customAnswer[item.value]"
                    style="max-width: 200px"
                    :disabled="selection !== item.value"
                    @change="handleChangeCustomAnswer"
                ></el-input>
                <el-date-picker
                    v-if="item.type === 'date'"
                    v-model="customAnswer[item.value]"
                    type="date"
                    value-format="yyyy-MM-dd"
                    style="max-width: 200px; margin-left: 20px"
                    placeholder="請選擇"
                    :disabled="selection !== item.value"
                    @change="input => handleChangeCustomAnswer(`${item.value}, ${input}`)"
                >
                </el-date-picker>
                <el-select
                    v-if="item.type === 'dropdown'"
                    v-model="customAnswer[item.value]"
                    style="max-width: 200px"
                    :disabled="selection !== item.value"
                    placeholder=""
                    @change="handleChangeCustomAnswer"
                >
                    <el-option
                        v-for="selectItem in item.selections"
                        :key="selectItem.value"
                        :label="selectItem.label"
                        :value="selectItem.value"
                    >
                    </el-option>
                </el-select>
                {{ item.suffix }}
            </el-radio>
        </el-radio-group>
        <div v-else-if="`${value}`" class="input-preview">
            <div class="value">{{ value }}</div>
        </div>
        <div v-if="config.suffix" class="suffix" v-html="config.suffix"></div>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralQuestionField';
import SelectMixins from '@/mixins/SelectQuestion';

export default {
    mixins: [GeneralMixins, SelectMixins],
    data() {
        return {
            selection: null,
            customAnswer: {}
        };
    },
    created() {
        this.initialSelection();
    },
    methods: {
        initialSelection() {
            const SELECTIONS = this.config.selections;
            if (!this.value) {
                const firstOption = SELECTIONS && SELECTIONS[0];
                const firstOptionValue = firstOption.value;
                const value =
                    firstOption.type === 'combo-text'
                        ? firstOptionValue.replace('{value}', '')
                        : firstOptionValue;
                this.$emit('input', value);
                this.selection = firstOptionValue;
                return;
            }
            const RADIO = this.originalOption(SELECTIONS, this.value);
            if (RADIO) {
                this.selection = this.value;
            } else {
                const DROPDOWN = this.dropdownOption(SELECTIONS, this.value);
                const DATE = this.dateOption(SELECTIONS, this.value);
                const NUMBER = this.numberOption(SELECTIONS);
                const COMBO_TEXT = this.comboTextOption(SELECTIONS, this.value);
                const TEXT = this.textOption(SELECTIONS);
                if (DROPDOWN) {
                    this.selection = DROPDOWN.value;
                    this.$set(this.customAnswer, this.selection, this.value);
                } else if (COMBO_TEXT) {
                    this.selection = COMBO_TEXT.value;
                    const pattern = COMBO_TEXT.value.replace('{value}', '(.*)');
                    const customAnswerValue = this.value.match(pattern);
                    if (customAnswerValue) {
                        this.$set(this.customAnswer, this.selection, customAnswerValue[1]);
                    }
                } else if (DATE) {
                    const VALUE = this.value.split(', ')[1];
                    this.selection = DATE.value;
                    this.$set(this.customAnswer, this.selection, VALUE);
                } else if (NUMBER) {
                    this.selection = NUMBER.value;
                    this.$set(this.customAnswer, this.selection, this.value);
                } else if (TEXT) {
                    this.selection = TEXT.value;
                    this.$set(this.customAnswer, this.selection, this.value);
                }
            }
        },
        handleStoreData(label) {
            if (this.customAnswer[label]) this.$emit('change', this.customAnswer[label]);
            else this.$emit('change', label);
        },
        handleChangeCustomAnswer(val) {
            this.$emit('change', val);
        }
    }
};
</script>

<style lang="scss" scoped>
.radio-select {
    margin-bottom: 30px !important;
    .column {
        max-width: 100%;
    }
    .row {
        flex: 1;
        margin: -30px 0 0 -30px;
        > * {
            margin: 30px 0 0 30px;
        }
    }
    /deep/ .el-radio__label {
        word-break: break-all;
        white-space: pre-line;
        line-height: 1.5;
    }
}
.input-preview {
    .value {
        word-break: break-all;
    }
}
</style>
