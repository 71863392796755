export default {
    namespaced: true,
    state: {
        confirmationPopUp: false,
        confirmationText: {},
        confirmationFunction: undefined,

        // create sales
        createSalesPopUp: false,

        // transfer case
        transferCasePopUp: false,

        // generate link and password
        generateLinkAndPasswordPopUp: false,

        // Disclaimer
        disclaimerPop: false,
        shareDisclaimerPop: false,

        // AEDisclaimer
        aeDisclaimerPopUp: false,

        revealInformationPopup: false,

        // loading
        loadingPopUp: false,
    },
    mutations: {
        updateConfirmationState(state, status) {
            state.confirmationPopUp = status;
        },
        updateConfirmationText(state, purpose) {
            state.confirmationText = purpose;
        },
        updateConfirmationFunction(state, func) {
            state.confirmationFunction = func;
        },

        // create sales
        updateCreateSalesPopUpState(state, status) {
            state.createSalesPopUp = status;
        },
        // transfer case
        updateTransferCasePopUpState(state, status) {
            state.transferCasePopUp = status;
        },

        // generate link and password
        updateGenerateLinkAndPasswordPopUpState(state, status) {
            state.generateLinkAndPasswordPopUp = status;
        },

        // Disclaimer
        updateDisclaimerPop(state, change) {
            state.disclaimerPop = change;
        },
        // ShareDisclaimer
        updateShareDisclaimerPop(state, change) {
            state.shareDisclaimerPop = change;
        },

        // AE Disclaimer
        updateAEDisclaimerPopUpState(state, status) {
            state.aeDisclaimerPopUp = status;
        },

        updateRevealInformationPopupState(state, status) {
            state.revealInformationPopup = status;
        },

        // loading
        updateLoadingPopUpState(state, status) {
            state.loadingPopUp = status;
        },
    },
};
