<template>
    <div
        class="flex my-7 gap-5"
        :class="{
            'flex-col': config.column
        }"
    >
        <span class="flex-1">{{ config.label }}</span>
        <div class="flex-1 sm:flex-2">
            <span v-if="config.prefix" class="prefix text-gray-question mr-1">
                {{ config.prefix }}
            </span>
            <span v-html="previewText(value)"></span>
            <span
                v-if="config.suffix"
                class="suffix text-gray-question ml-1"
                v-html="config.suffix"
            ></span>
        </div>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralDisplay';

export default {
    mixins: [GeneralMixins],
    methods: {
        previewText(value) {
            const RES = value?.replaceAll('\n', '<br />') || '';
            return RES;
        }
    }
};
</script>
