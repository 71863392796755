export default {
    table: [
        {
            label: '醫師',
            fieldName: 'doctorName',
        },
        {
            label: 'CR/PR',
            fieldName: 'response',
        },
        {
            label: 'Stage',
            fieldName: 'category',
        },
        {
            label: 'PFS',
            fieldName: 'PFS.value',
        },
        {
            label: 'Date',
            fieldName: 'date',
        },
    ],
    tags: [
        {
            label: 'Stage 3 Optimal',
            destination: {
                fieldName: 'category',
                type: 'normal',
                value: 'Stage 3 Optimal',
            },
        },
        {
            label: 'Stage 3 sub-optimal',
            destination: {
                fieldName: 'category',
                type: 'normal',
                value: 'Stage 3 sub-optimal',
            },
        },
        {
            label: 'Stage 4',
            destination: {
                fieldName: 'category',
                type: 'normal',
                value: 'Stage 4',
            },
        },
        {
            label: 'CR',
            destination: {
                fieldName: 'response',
                type: 'normal',
                value: 'CR',
            },
        },
        {
            label: 'PR',
            destination: {
                fieldName: 'response',
                type: 'normal',
                value: 'PR',
            },
        },
        {
            label: 'Clear-cell',
            destination: {
                fieldName: 'histologyType',
                type: 'normal',
                value: 'Clear-cell',
            },
        },
        {
            label: 'Mucinous',
            destination: {
                fieldName: 'histologyType',
                type: 'normal',
                value: 'Mucinous',
            },
        },
        {
            label: 'BRCAm',
            destination: {
                fieldName: 'BRCAStatus',
                type: 'normal',
                value: 'BRCAm',
            },
        },
        {
            label: 'BRCAmt',
            destination: {
                fieldName: 'BRCAStatus',
                type: 'normal',
                value: 'BRCAmt',
            },
        },
    ],
};
