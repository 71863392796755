export default [
    [
        {
            fieldName: 'date',
            component: 'date-picker',
            label: '紀錄日期',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'CTNumber',
            component: 'text-field',
            label: 'CT Number',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'doctorAvatar',
            component: 'avatar-picker',
            label: '醫師照片',
        },
        {
            fieldName: 'doctorName',
            component: 'text-field',
            label: '醫師姓名',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'displayDoctorName',
            component: 'radio-select',
            label: '顯示醫師姓名',
            selections: [
                {
                    label: '顯示',
                    value: 'display',
                },
                {
                    label: '不顯示',
                    value: 'hidden',
                },
            ],
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'hospital',
            component: 'text-field',
            label: '醫院',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'specialty',
            component: 'text-field',
            label: '科別',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'title',
            component: 'drop-down',
            label: '文章標題',
            width: 500,
            selections: [
                { label: '接棒手術與化療，早期肺癌新治療', value: '接棒手術與化療，早期肺癌新治療'},
            ],
            rules: {
                required: true,
            },
        },
    ],
    [
        {
            component: 'title-block',
            label: '<div class="sm:text-2xl">Patient, disease and treatment characteristics(依照病理報告記載)</div>',
        },
        {
            fieldName: 'gender',
            component: 'radio-select',
            label: 'Gender',
            selections: [
                {
                    label: 'Male',
                    value: 'Male',
                },
                {
                    label: 'Female',
                    value: 'Female',
                },
                {
                    label: 'others',
                    value: 'others',
                },
            ],
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'age',
            component: 'radio-select',
            label: 'Age',
            selections: [
                { label: '<30', value: '<30' },
                { label: '30-40', value: '30-40' },
                { label: '40-50', value: '40-50' },
                { label: '50-60', value: '50-60' },
                { label: '60-70', value: '60-70' },
                { label: '70-80', value: '70-80' },
                { label: '>80', value: '>80' },
            ],
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'diagnoseCancer',
            component: 'text-field',
            label: '診斷肺癌時間(年)',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'ecog',
            component: 'drop-down',
            label: 'ECOG',
            selections: [
                { label: '0', value: 0 },
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 },
            ],
        },
        {
            fieldName: 'smokingHistory',
            component: 'radio-select',
            label: '抽菸史',
            selections: [
                { label: 'Never', value: 'Never' },
                { label: 'Previous', value: 'Previous' },
                { label: 'Current', value: 'Current' },
            ],
            rules: {
                required: true,
            }
        },
        {
            fieldName: 'histologicalType',
            component: 'radio-select',
            label: 'Histological type',
            column: true,
            selections: [
                { label: 'Adenocarcinoma', value: 'Adenocarcinoma' },
                { label: 'Squamous cell carcinoma', value: 'Squamous cell carcinoma' },
                { label: 'Large cell carcinoma', value: 'Large cell carcinoma' },
                { label: 'Other', type: 'text', value: 'Other', width: 130 },
            ],
            rules: {
                required: true,
            }
        },
        {
            fieldName: 'tDescriptorSize',
            component: 'radio-select',
            label: 'Size (T descriptor)',
            column: true,
            selections: [
                { label: '≤1 cm', value: '≤1 cm' },
                { label: '>1 ~ ≤2 cm', value: '>1 ~ ≤2 cm' },
                { label: '>2 ~ ≤3 cm', value: '>2 ~ ≤3 cm' },
                { label: '>3 ~ ≤4 cm', value: '>3 ~ ≤4 cm' },
                { label: '>4 ~ ≤5 cm', value: '>4 ~ ≤5 cm' },
                { label: '>5 ~ ≤7 cm', value: '>5 ~ ≤7 cm' },
                { label: '>7 cm', value: '>7 cm' },
            ],
            rules: {
                required: true,
            }
        },
        {
            fieldName: 'clinicalStaging',
            component: 'radio-select',
            label: 'Staging (Clinical)\n(per 7th edition)',
            selections: [
                { label: 'IIA', value: 'IIA' },
                { label: 'IIB', value: 'IIB' },
                { label: 'IIIA', value: 'IIIA' },
                { label: 'Other', type: 'text', value: 'Other', width: 100 },
                { label: 'Unknown', value: 'Unknown' },
            ],
            rules: {
                required: true,
            }
        },
        {
            fieldName: 'pathologyStaging',
            component: 'radio-select',
            label: 'Staging (Pathology)\n(per 7th edition)',
            selections: [
                { label: 'IIA', value: 'IIA' },
                { label: 'IIB', value: 'IIB' },
                { label: 'IIIA', value: 'IIIA' },
            ],
            rules: {
                required: true,
            }
        },
        {
            fieldName: 'cNLaymphNodeStatus',
            component: 'radio-select',
            label: 'Lymph node status(cN)',
            prefix: '(cN)',
            selections: [
                { label: 'N0', value: 'N0' },
                { label: 'N1', value: 'N1' },
                { label: 'N2', value: 'N2' },
                { label: 'Unknown', value: 'Unknown' },
            ],
            rules: {
                required: true,
            }
        },
        {
            fieldName: 'pNLaymphNodeStatus',
            component: 'radio-select',
            label: 'Lymph node status(pN)',
            prefix: '(pN)',
            selections: [
                { label: 'N0', value: 'N0' },
                { label: 'N1', value: 'N1' },
                { label: 'N2', value: 'N2' },
                { label: 'Unknown', value: 'Unknown' },
            ],
            rules: {
                required: true,
            }
        },
        {
            fieldName: 'lymphNodeCount',
            component: 'text-field',
            label: '淋巴結數量/顆',
        },
        {
            fieldName: 'location',
            component: 'text-field',
            label: 'Location\n(依照病理報告記載)',
            rules: {
                required: true
            },
        },
        {
            component: 'subtitle-block',
            label: '<b>High risk*</b>',
        },
        {
            fieldName: 'invadesVisceralPleura',
            component: 'radio-select',
            label: 'Invades visceral pleura',
            column: true,
            selections: [
                { label: 'Present : PL1', value: 'Present : PL1' },
                { label: 'Present : PL2', value: 'Present : PL2' },
                { label: 'Present : PL3', value: 'Present : PL3' },
                { label: 'Not present', value: 'Not present' },
                { label: 'Unknown', value: 'Unknown' },
            ]
        },
        {
            fieldName: 'nerveInvasion',
            component: 'radio-select',
            label: 'Nerve invasion',
            selections: [
                { label: 'Positive', value: 'Positive' },
                { label: 'Negative', value: 'Negative' },
                { label: 'Unknown', value: 'Unknown' },
            ]
        },
        {
            fieldName: 'vascularInvasion',
            component: 'radio-select',
            label: 'Vascular invasion',
            selections: [
                { label: 'Positive', value: 'Positive' },
                { label: 'Negative', value: 'Negative' },
                { label: 'Unknown', value: 'Unknown' },
            ]
        },
        {
            fieldName: 'pdl1',
            component: 'number-field',
            label: 'PD-L1表現量',
            prefix: 'PD-L1',
            suffix: '%',
        },
        {
            fieldName: 'egfrMutationStatus',
            component: 'radio-select',
            label: 'EGFR mutation status',
            column: true,
            selections: [
                { label: 'Del 19', value: 'Del 19' },
                { label: 'L858R', value: 'L858R' },
                { label: '其他', type: 'text', value: '其他', width: 130 },
                { label: 'Wild type', value: 'Wild type' },
                { label: 'Unknown', value: 'Unknown' },
            ]
        },
        {
            fieldName: 'otherGenomicAlteration',
            component: 'radio-select',
            label: 'Other genomic alteration',
            column: true,
            selections: [
                { label: 'Yes', value: 'Yes' },
                { label: 'by NGS, results', type: 'combo-text', value: 'by NGS, results {value}' },
                { label: 'by other platform, results', type: 'combo-text', value: 'by other platform, results {value}' },
                { label: 'No', value: 'No' },
                { label: 'Unknown', value: 'Unknown' },
            ]
        },
        {
            fieldName: 'operationTimeAndMethod',
            component: 'combo-field',
            label: '手術時間/術式',
            column: true,
            children: [
                {
                    fieldName: 'time',
                    component: 'text-field',
                    suffix: '年'
                },
                {
                    fieldName: 'method',
                    component: 'radio-select',
                    column: true,
                    selections: [
                        { label: 'Lobectomy', value: 'Lobectomy' },
                        { label: 'Pneumonectomy', value: 'Pneumonectomy' },
                        { label: 'Bilobectomy', value: 'Bilobectomy' },
                        { label: 'Segmentectomy', value: 'Segmentectomy' },
                        { label: 'Others', type: 'text', value: '其他', width: 130 },
                    ]
                },
            ],
            rules: {
                required: true,
            },
        },
    ],
    [
        {
            component: 'title-block',
            label: '術後化學輔助療法及免疫療法',
        },
        {
            fieldName: 'adjuvantChemotherapy',
            component: 'radio-select',
            label: 'Adjuvant chemotherapy',
            column: true,
            selections: [
                { label: 'Cisplatin-docetaxel', value: 'Cisplatin-docetaxel' },
                { label: 'Cisplatin-gemcitabine', value: 'Cisplatin-gemcitabine' },
                { label: 'Cisplatin-vinorelbine', value: 'Cisplatin-vinorelbine' },
                { label: 'Cisplatin-pemetrexed', value: 'Cisplatin-pemetrexed' },
                { label: 'Other platinum-based adjuvant chemotherapy', value: 'Other platinum-based adjuvant chemotherapy' },
            ],
            rules: {
                required: true,
            }
        },
        {
            fieldName: 'adjuvantChemotherapyCycles',
            component: 'text-field',
            label: 'Adjuvant chemotherapy cycles',
            prefix: 'cycle數'
        },
        {
            component: 'subtitle-block',
            label: '術後化學輔助療法後，在接受Atezolizumab adjuvant therapy (癌自禦術後免疫輔助療法) 治療之前的腫瘤指數 (首次數值):',
        },
        {
            fieldName: 'tumorIndex',
            component: 'check-select',
            label: '',
            selections: [
                { label: 'CEA', type: 'combo-text', value: 'CEA {value} ng/ML', width: 130, suffix: 'ng/ML' },
                { label: 'SCC', type: 'combo-text', value: 'scc {value} ng/ML', width: 130, suffix: 'ng/ML' },
                { label: 'CA125', type: 'combo-text', value: 'ca125 {value} U/ML', width: 130, suffix: 'U/ML' },
                { label: 'CA153', type: 'combo-text', value: 'ca153 {value} U/ML', width: 130, suffix: 'U/ML' },
                { label: 'ctDNA(VAF%)', type: 'combo-text', value: 'ctDNA(VAF%) {value} %', width: 130, suffix: '%' },
            ],
        },
        {
            fieldName: 'atezolizumabCyclesAmount',
            component: 'drop-down',
            label: 'Atezolizumab\n治療cycle數(4~16):',
            selections: Array.from({ length: 13 }, (_, i) => ({
                label: String(i + 4),
                value: i + 4
            })),
            rules: {
                required: true,
            }
        },
        {
            component: 'subtitle-block',
            label: '接受Atezolizumab adjuvant therapy (癌自禦術後免疫輔助療法) 治療後腫瘤指數*:',
        },
        {
            fieldName: 'cycles',
            component: 'amount-change-field',
            label: 'Cycles',
            indexLabel: true,
            column: true,
            children: [
                {
                    fieldName: 'cyclesNo',
                    component: 'drop-down',
                    label: 'Cycle數',
                    selections: Array.from({ length: 13 }, (_, i) => ({
                        label: String(i + 4),
                        value: i + 4
                    })),
                },
                {
                    fieldName: 'tumorIndex',
                    component: 'check-select',
                    label: '',
                    selections: [
                        { label: 'CEA', type: 'combo-text', value: 'CEA {value} ng/ML', width: 130, suffix: 'ng/ML' },
                        { label: 'SCC', type: 'combo-text', value: 'scc {value} ng/ML', width: 130, suffix: 'ng/ML' },
                        { label: 'CA125', type: 'combo-text', value: 'ca125 {value} U/ML', width: 130, suffix: 'U/ML' },
                        { label: 'CA153', type: 'combo-text', value: 'ca153 {value} U/ML', width: 130, suffix: 'U/ML' },
                        { label: 'CA199', type: 'combo-text', value: 'ca199 {value} U/ML', width: 130, suffix: 'U/ML' },
                        { label: 'ctDNA(VAF%)', type: 'combo-text', value: 'ctDNA(VAF%) {value} %', width: 130, suffix: '%' },
                    ],
                },
            ],
        },
        {
            component: 'subtitle-block',
            label: '個案提問'
        },
        {
            fieldName: 'caseInquiry1',
            component: 'text-area',
            label: '1.在病人選擇上, 請問您在考量使用術前(neoadjuvant)或術後(adjuvant)輔助治療時的臨床因素有那些?',
            column: true,
            rows: 5,
            rules: {
                required: true,
            }
        },
        {
            fieldName: 'caseInquiry2',
            component: 'text-area',
            label: '2.請問您是否有考量過使用術前輔助治療(neoadjuvant therapy)於此患者?最終選擇術後輔助治療(adjuvant therapy)的決定點為何?',
            column: true,
            rows: 5,
            rules: {
                required: true,
            }
        },
        {
            fieldName: 'caseInquiry3',
            component: 'text-area',
            label: '3.請問您針對此患者的預期治療目標為何?',
            column: true,
            rows: 5,
            rules: {
                required: true,
            }
        },
        {
            fieldName: 'caseInquiry4',
            component: 'text-area',
            label: '4.請問您通常如何追蹤這些已結束術後輔助治療療程(adjuvant therapy)的早期肺癌患者?',
            column: true,
            rows: 5,
            rules: {
                required: true,
            }
        },
        {
            fieldName: 'caseInquiry5',
            component: 'text-area',
            label: '5.在 IMpower010 目前已發表的研究數據中, 您認為還有什麼缺乏的 evidence gap(s)?為什麼?',
            column: true,
            rows: 5,
            rules: {
                required: true,
            }
        },
    ],
];
