export default [
    [
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'pathologyStaging',
                placeholder: 'Staging',
                options: [
                    { label: 'IIA', value: 'IIA', type: 'normal' },
                    { label: 'IIB', value: 'IIB', type: 'normal' },
                    { label: 'IIIA', value: 'IIIA', type: 'normal' },
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'pdl1',
                placeholder: 'PD-L1表現量',
                options: [
                    { label: 'PD-L1 1-49%', value: { column: 'pdl1', value: '<50' }, type: 'operator' },
                    { label: 'PD-L1 ≧50%', value: { column: 'pdl1', value: '>=50' }, type: 'operator' },
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'pNLaymphNodeStatus',
                placeholder: 'Lymph node status',
                options: [
                    { label: 'N0', value: 'N0', type: 'normal' },
                    { label: 'N1', value: 'N1', type: 'normal' },
                    { label: 'N2', value: 'N2', type: 'normal' },
                    { label: 'Unknown', value: 'Unknown', type: 'normal' },
                ],
                class: 'small-textarea',
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'pathologyStaging',
                placeholder: 'High risk',
                options: [
                    { label: 'Invades visceral pleura', value: {
                        column: 'invadesVisceralPleura', value: 'not:Not present,Unknown'
                    }, type: 'operator' },
                    { label: 'Nerve invasion', value: {
                        column: 'nerveInvasion', value: 'not:Negative,Unknown'
                    }, type: 'operator' },
                    { label: 'Vascular invasion', value: {
                        column: 'vascularInvasion', value: 'not:Negative,Unknown'
                    }, type: 'operator' },
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'atezolizumabCyclesAmount',
                placeholder: 'Atezo治療cycle數',
                options: Array.from({ length: 13 }, (_, i) => ({
                    label: String(i + 4),
                    value: i + 4,
                    type: 'normal'
                })),
                class: 'small-textarea',
            },
        }
    ],
];
