<template>
    <div class="overview-data-graphic">
        <div class="animation">
            <div ref="lottie" />
        </div>
        <div class="data-block">
            <div v-for="(data, i) in config.data" :key="i" class="data">
                <span
                    v-if="data.type === 'input-boolean'"
                    class="input-boolean"
                >
                    {{ data.label }} <br />
                    {{ value[data.fieldName].value }}
                    <span v-if="value[data.fieldName].boolean">{{
                        data.symbol
                    }}</span>
                </span>
                <span v-else>
                    {{ `${data.label} ${value[data.fieldName]}` }}
                </span>
                <span> {{ data.suffix }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import lottie from 'lottie-web';

export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        config: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        disease() {
            return this.$route.params.disease;
        },
    },
    mounted() {
        const TYPE = this.value.running;
        this.setAnimation(TYPE);
    },
    methods: {
        calculateExistValue(sample) {
            const RETURN = [];
            sample.forEach((element) => {
                if (this.value[element.fieldName]) RETURN.push(element.label);
            });
            return RETURN.length ? RETURN.join(', ') : '無';
        },
        setAnimation(type) {
            lottie.loadAnimation({
                container: this.$refs.lottie, // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: `/UC_Run/${type}/data.json`, // the path to the animation json
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.overview-data-graphic {
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    .animation {
        width: 45%;
        margin-right: 5%;
    }
    .data-block {
        flex: 1;
        padding: 5px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .data {
            font-size: 16px;
            text-align: center;
            font-weight: 700;
        }
        > :nth-child(2) {
            border-top: 1px dashed;
            padding: 10px 0;
            margin: 10px 0;
        }
    }
}
</style>
