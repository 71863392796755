export default [
    {
        fieldName: 'date',
        label: '日期',
    },
    {
        fieldName: 'CTNumber',
        label: 'CT Number',
    },
    {
        fieldName: 'doctorAvatar',
        label: '醫師照片',
    },
    {
        fieldName: 'doctorName',
        label: '醫師姓名',
    },
    {
        fieldName: 'hospital',
        label: '醫院',
    },
    {
        fieldName: 'title',
        label: '案例標題',
    },
    {
        fieldName: 'frontLineType',
        label: 'Avastin用於front-line臨床案例為下列哪一類型?',
    },
    {
        fieldName: 'category',
        label: '此病人是屬於哪一類？',
    },
    {
        fieldName: 'response',
        label: 'Response',
    },
    {
        fieldName: 'PFS',
        label: 'PFS(months)',
        booleanLabel: '尚未PD',
    },
    {
        fieldName: 'OS',
        label: 'OS(months)',
        booleanLabel: 'Still alive',
    },
    {
        fieldName: 'age',
        label: 'Age',
    },
    {
        fieldName: 'ECOG',
        label: 'ECOG',
    },
    {
        fieldName: 'PreTreatmentCA125',
        label: 'Pre treatment CA125',
    },
    {
        fieldName: 'histologyType',
        label: 'Histology Type',
    },
    {
        fieldName: 'BRCAStatus',
        label: 'BRCA Status',
    },
    {
        fieldName: 'HRStatus',
        label: 'HR Status',
    },
    {
        fieldName: 'otherMedicalHistory',
        label: 'Other Medical History',
    },
    {
        fieldName: 'pathology',
        label: 'Pathology',
    },
    {
        fieldName: 'treatmentHistory',
        label: 'Treatment history',
        // column: true,
        children: [
            {
                fieldName: 'treatmentHistory_item1',
            },
            {
                fieldName: 'treatmentHistory_item2',
            },
        ],
    },
    {
        fieldName: 'totalAvastinCycles',
        label: 'Total Avastin Cycles',
    },
    {
        fieldName: 'PreTreatmentCA125',
        label: 'CA125',
    },
    {
        fieldName: 'preCT_MRI',
        label: 'CT/MRI',
        children: [
            {
                fieldName: 'preCT_MRI1',
            },
            {
                fieldName: 'preCT_MRI2',
            },
            {
                fieldName: 'preCT_MRI3',
            },
            {
                fieldName: 'preCT_MRI4',
            },
        ],
    },
    {
        fieldName: 'postTreatmentCA125',
        label: 'CA125',
    },
    {
        fieldName: 'response',
        label: 'Response',
    },
    {
        fieldName: 'postCT_MRI',
        label: 'CT/MRI',
        children: [
            {
                fieldName: 'postCT_MRI1',
            },
            {
                fieldName: 'postCT_MRI2',
            },
            {
                fieldName: 'postCT_MRI3',
            },
            {
                fieldName: 'postCT_MRI4',
            },
        ],
    },
    {
        fieldName: 'CA125Pic',
        label: 'CA125',
        children: [
            {
                fieldName: 'CA125Pic',
            },
        ],
    },
    {
        fieldName: 'isTreatmentAfterL1Exist',
        label: '是否有Treatment after L1相關數據',
    },
    {
        fieldName: 'pdImage',
        label: 'PD image',
        children: [
            {
                fieldName: 'pdImage1',
            },
            {
                fieldName: 'pdImage2',
            },
            {
                fieldName: 'pdImage3',
            },
            {
                fieldName: 'pdImage4',
            },
        ],
    },
    {
        fieldName: 'TreatmentAfterPD',
        label: 'Treatment after PD',
        children: [
            {
                fieldName: 'TreatmentAfterPD_item1',
            },
            {
                fieldName: 'TreatmentAfterPD_item2',
            },
        ],
    },
    {
        fieldName: 'question',
        label: 'Question',
    },
    {
        fieldName: 'answer',
        label: 'Answer',
    },
    {
        fieldName: 'impression',
        label: '我有話想說',
    },
];
