import Api from '@/models/api';
import { Message } from 'element-ui';

export default {
    namespaced: true,
    mutations: {
        setDisease(context, disease) {
            Api.setDisease(disease);
        },
        setToken(context, token) {
            Api.setToken(token);
        },
    },
    actions: {
        async apiProxy(context, payload) {
            let RES;
            try {
                RES = await Api[`${payload.category}`][`${payload.function}`](payload.data || null);
                return RES;
            } catch (e) {
                Message({
                    message: (e && e.response.data.msg) || '未知的錯誤',
                    type: 'error',
                });
                return RES;
            }
        },
        async invoke({ commit }, payload) {
            let RES;
            try {
                commit('popup/updateLoadingPopUpState', true, { root: true });
                RES = await Api[`${payload.category}`][`${payload.function}`](payload.data || null);
                if (payload.successMsg) {
                    Message({
                        message: (payload.successMsg),
                        type: 'success',
                    });
                }
                return RES;
            } catch (e) {
                if (!payload.hideMessage) {
                    Message({
                        message: (e && e.response.data.msg) || '未知的錯誤',
                        type: 'error',
                    });
                }

                return RES;
            } finally {
                commit('popup/updateLoadingPopUpState', false, { root: true });
            }
        },
        async invokeSearch(context, payload) {
            let RES;
            try {
                RES = await Api[`${payload.category}`][`${payload.function}`](payload.query);
                return RES;
            } catch (e) {
                Message({
                    message: (e && e.response.data.msg) || '未知的錯誤',
                    type: 'error',
                });
                return RES;
            }
        },
    },
};
