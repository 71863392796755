export default {
    path: '/:disease/manager',
    name: 'Manager_Index',
    component: () => import('../views/Manager/index.vue'),
    children: [
        {
            name: 'Manager_Case_List',
            path: 'case',
            component: () => import('../views/Manager/CaseList.vue'),
        },
        {
            name: 'Manager_Sales_List',
            path: 'sales',
            component: () => import('../views/Manager/SalesList.vue'),
        },
        {
            name: 'Manager_Form',
            path: 'form/:id',
            component: () => import('../views/Manager/Form.vue'),
        },
        {
            name: 'Manager_Key_Message',
            path: 'key-message',
            component: () => import('../views/Manager/KeyMessage.vue'),
        },
        {
            name: 'Manager_Headline',
            path: 'headline',
            component: () => import('../views/Manager/Headline.vue'),
        },
        {
            name: 'Disclaimer_Manage',
            path: 'disclaimer',
            component: () => import('../views/Manager/DisclaimerManage.vue'),
        },
        {
            path: '*',
            redirect: { name: 'Manager_Case_List' },
        },
    ],

};
