export default {
    data: () => ({
        hasLoadConsentScript: false,
    }),
    computed: {
        shouldLoadConsentScript() {
            const hasNotLoaded = !this.hasLoadConsentScript;
            const isNotLineLoginPage = !location.pathname.includes('/hcc/line');
            return hasNotLoaded && isNotLineLoginPage;
        },
    },
    methods: {
        loadConsentScript() {
            const head = document.head;
            if (!document.getElementById('consent-script-1')) {
                let script1 = document.createElement('script');
                script1.id = 'consent-script-1';
                script1.src = "https://cdn.cookielaw.org/consent/d9a56a45-f9b6-489c-9e60-199a63b1465c/OtAutoBlock.js";
                head.appendChild(script1);
            }
            if (!document.getElementById('consent-script-2')) {
                let script2 = document.createElement('script');
                script2.id = 'consent-script-2';
                script2.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
                script2.setAttribute("data-document-language", "true");
                script2.setAttribute("charset", "UTF-8");
                script2.setAttribute("data-domain-script", "d9a56a45-f9b6-489c-9e60-199a63b1465c");
                head.appendChild(script2);
            }
            if (!document.getElementById('consent-script-3')) {
                let inlineScript = document.createElement('script');
                inlineScript.id = 'consent-script-3';
                inlineScript.textContent = 'function OptanonWrapper() {}';
                head.appendChild(inlineScript);
            }
            this.hasLoadConsentScript = true;
        },
    },
    mounted() {
        if (process.env.NODE_ENV !== 'development') {
            if (this.shouldLoadConsentScript) {
                this.loadConsentScript();
            }
            this.$root.$on('loadConsent', this.loadConsentScript);
        }
    },
}
