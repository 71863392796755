<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field combo-field album-picker"
        :class="{ column: !isEditMode }"
    >
        <div v-if="config.label" class="label">
            <div class="main-label">
                <span>{{ config.label }}</span>
                <span v-if="config.rules && config.rules.required" class="star">
                    *
                </span>
            </div>
            <div
                class="description-label"
                :style="{
                    'font-size': `${
                        config.supplement && config.supplement.fontSize
                    }px`,
                }"
            >
                {{ config.supplement && config.supplement.label }}
            </div>
        </div>
        <div v-if="isEditMode" class="combo-child-field">
            <component
                v-for="(render, i) in config.children"
                :key="i"
                v-model="data[render.fieldName]"
                :is="render.component"
                :config="render"
                :mode="mode"
                class="combo-child"
                @change="handleChange"
            ></component>
        </div>
        <div v-else class="album-preview">
            <div
                v-for="(render, i) in validData"
                :key="i"
                class="combo-child"
                :class="{ fluid: validData.length === 1 }"
                @change="handleChange"
            >
                <div v-if="render.config.date" class="date">
                    {{ (render.value && render.value.date) || "-" }}
                </div>
                <img
                    v-if="render.value && render.value.url"
                    :src="render.value && render.value.url"
                    class="avatar"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    props: {
        value: undefined,
        mode: {
            type: String,
            default: '',
        },
        config: {
            type: Object,
            default: () => {},
        },
        depend: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        isEditMode() {
            return this.mode === 'EDIT';
        },
        validData() {
            const VALID = [];
            this.config.children.forEach((item) => {
                if (this.data[item.fieldName]) {
                    VALID.push({
                        value: this.data[item.fieldName],
                        config: item,
                    });
                }
            });
            return VALID;
        },
    },
    data() {
        return {
            data: {},
        };
    },
    created() {
        _.forEach(this.value, (value, key) => {
            this.$set(this.data, `${key}`, value);
        });
    },
    methods: {
        handleChange() {
            this.$emit('change', this.data);
        },
    },
};
</script>

<style lang="scss" scoped>
.album-picker {
    &.column {
        display: flex;
        flex-direction: column;
    }
    .album-preview {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .combo-child {
            margin: 0;
            width: 48%;
            &.fluid {
                width: 100%;
            }
            .date {
                width: 100%;
                margin-bottom: 20px;
                text-align: center;
            }
            img {
                width: 100%;
            }
        }
    }
}
</style>
