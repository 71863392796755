export default [
    [
        {
            component: 'overview-data',
            tableData: [
                {
                    label: 'Age',
                    fieldName: 'age',
                },
                {
                    label: 'ECOG',
                    fieldName: 'ECOG',
                },
                {
                    label: 'Pre treatment CA125',
                    fieldName: 'PreTreatmentCA125',
                },
                {
                    label: 'Histology Type',
                    fieldName: 'histologyType',
                },
                {
                    label: 'BRCA Status',
                    fieldName: 'BRCAStatus',
                },
                {
                    label: 'HR Status',
                    fieldName: 'HRStatus',
                },
            ],
            tags: ['category', 'response', 'histologyType'],
            graphic: {
                image: true,
                data: [
                    {
                        fieldName: 'totalAvastinCycles',
                        label: 'Avastin total ',
                        suffix: 'cycles',
                    },
                    {
                        fieldName: 'PFS',
                        label: 'PFS',
                        type: 'input-boolean',
                        symbol: '+',
                        suffix: 'months',
                    },
                    {
                        fieldName: 'OS',
                        label: 'OS',
                        type: 'input-boolean',
                        symbol: '+',
                        suffix: 'months',
                    },
                ],
            },
        },
    ],
    [
        {
            component: 'title-block',
            label:
                'Patient demographic',
        },
        {
            fieldName: 'age',
            component: 'original-data',
            label: 'Age',
        },
        {
            fieldName: 'ECOG',
            component: 'original-data',
            label: 'ECOG',
        },
        {
            fieldName: 'PreTreatmentCA125',
            component: 'original-data',
            label: 'Pre treatment CA125',
        },
        {
            fieldName: 'histologyType',
            component: 'original-data',
            label: 'Histology Type',
        },
        {
            fieldName: 'BRCAStatus',
            component: 'original-data',
            label: 'BRCA Status',
        },
        {
            fieldName: 'HRStatus',
            component: 'original-data',
            label: 'HR Status',
        },
        {
            fieldName: 'otherMedicalHistory',
            component: 'original-data',
            label: 'Other Medical History',
        },
        {
            fieldName: 'pathology',
            component: 'original-data',
            label: 'Pathology',
        },
    ],
    [
        {
            component: 'title-block',
            label:
                '1L treatment',
        },
        {
            fieldName: 'treatmentHistory',
            component: 'array-data',
            label: 'Treatment history',
            column: true,
            children: [
                {
                    fieldName: 'treatmentHistory_item1',
                },
                {
                    fieldName: 'treatmentHistory_item2',
                },
            ],
        },
        {
            fieldName: 'totalAvastinCycles',
            component: 'original-data',
            label: 'Total Avastin Cycles',
        },
        {
            component: 'title-block',
            label:
                'Pretreatment',
        },
        {
            fieldName: 'PreTreatmentCA125',
            component: 'original-data',
            label: 'CA125',
        },
        {
            fieldName: 'preCT_MRI',
            component: 'album-display',
            label: 'CT/MRI',
            children: [
                {
                    fieldName: 'preCT_MRI1',
                },
                {
                    fieldName: 'preCT_MRI2',
                },
                {
                    fieldName: 'preCT_MRI3',
                },
                {
                    fieldName: 'preCT_MRI4',
                },
            ],
        },
    ],
    [
        {
            component: 'title-block',
            label:
                'Post 1L treatment',
        },
        {
            fieldName: 'postTreatmentCA125',
            component: 'original-data',
            label: 'CA125',
        },
        {
            fieldName: 'response',
            component: 'original-data',
            label: 'Response',
        },
        {
            fieldName: 'postCT_MRI',
            component: 'album-display',
            label: 'CT/MRI',
            children: [
                {
                    fieldName: 'postCT_MRI1',
                },
                {
                    fieldName: 'postCT_MRI2',
                },
                {
                    fieldName: 'postCT_MRI3',
                },
                {
                    fieldName: 'postCT_MRI4',
                },
            ],
        },
        {
            fieldName: 'CA125Pic',
            component: 'album-display',
            label: 'CA125',
            children: [
                {
                    fieldName: 'CA125Pic',
                },
            ],
        },
        {
            component: 'title-block',
            label:
                'Treatment after 1L',
        },
        {
            fieldName: 'isTreatmentAfterL1Exist',
            component: 'original-data',
            label: '是否有Treatment after L1相關數據',
        },
        {
            fieldName: 'pdImage',
            component: 'album-display',
            label: 'PD image',
            children: [
                {
                    fieldName: 'pdImage1',
                },
                {
                    fieldName: 'pdImage2',
                },
                {
                    fieldName: 'pdImage3',
                },
                {
                    fieldName: 'pdImage4',
                },
            ],
        },
        {
            fieldName: 'TreatmentAfterPD',
            component: 'array-data',
            label: 'Treatment after PD',
            column: true,
            children: [
                {
                    fieldName: 'TreatmentAfterPD_item1',
                },
                {
                    fieldName: 'TreatmentAfterPD_item2',
                },
            ],
        },
        {
            component: 'title-block',
            label:
                'Key Message',
        },
        {
            component: 'key-message-display-block',
        },
        {
            fieldName: 'question',
            component: 'original-data',
            label: 'Question',
        },
        {
            fieldName: 'answer',
            component: 'original-data',
            label: 'Answer',
        },
        {
            fieldName: 'impression',
            component: 'original-data',
            label: '我有話想說',
            column: true,
        },
    ],
];
