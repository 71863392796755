<template>
    <div class="overview-data-logo">
        <img
            v-if="config"
            :src="require(`@/assets/view/uc/logo.png`)"
            alt=""
            width="50%"
        />
        <div class="from-date">From {{ month }}, {{ day }}</div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        config: {
            type: Object,
            default: () => {},
        },
        value: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        month() {
            if (this.value[this.config.fieldName]) {
                return moment(this.value[this.config.fieldName]).format('MMM');
            }
            return '-';
        },
        day() {
            if (this.value[this.config.fieldName]) {
                return moment(this.value[this.config.fieldName]).format('YYYY');
            }
            return '-';
        },
    },
};
</script>

<style lang="scss" scoped>
.overview-data-logo {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    justify-self: flex-end;
    .from-date {
        width: 50%;
        font-weight: bold;
        margin-bottom: 10px;
    }
}
</style>
