export default {
    namespaced: true,
    state: {
        target: undefined,
        state: undefined,
        mode: 'EDIT',
    },
    mutations: {
        setTarget(state, change) {
            state.target = change;
        },
        setState(state, change) {
            state.state = change;
        },
        setMode(state, change) {
            state.mode = change;
        },
    },
    actions: {
        async setState({ commit, dispatch }, ID) {
            const PAYLOAD = {
                category: 'Case',
                function: 'find',
                data: ID,
            };
            const RES = await dispatch('api/invoke', PAYLOAD, { root: true });
            commit('setState', RES.data.state);
            const DISEASE = window.location.pathname.split('/')[1];
            // eslint-disable-next-line
            const REQ = require(`@/config/${DISEASE}/question`);
            const CONFIG = [...REQ.default];
            commit('config/setQuestion', CONFIG, { root: true });
            if (RES.data.state === 'doctor-save'
                || RES.data.state === 'draft'
                || RES.data.state === 'reject') {
                commit('setMode', 'EDIT');
            } else commit('setMode', 'VIEW');

            commit('setTarget', RES.data);
        },
    },
};
