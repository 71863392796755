export default [
    [
        {
            fieldName: 'date',
            component: 'date-picker',
            label: '日期',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'CTNumber',
            component: 'original-data',
            label: 'CT Number',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'doctorAvatar',
            component: 'avatar-picker',
            label: '醫師照片',
        },
        {
            fieldName: 'doctorName',
            component: 'text-field',
            label: '醫師姓名',
        },
        {
            fieldName: 'hospital',
            component: 'text-field',
            label: '醫院',
        },
        {
            fieldName: 'title',
            component: 'radio-select',
            label: '文章標題',
            column: true,
            selections: [
                {
                    label: '腫瘤縮小，還給病人好的生活品質',
                    value: '腫瘤縮小，還給病人好的生活品質',
                },
                {
                    label: '腫瘤長期得到控制',
                    value: '腫瘤長期得到控制',
                },
                {
                    label: '其他',
                    type: 'text',
                    value: '其他',
                },
            ],
            rules: {
                required: true,
            },
        },
        {
            component: 'desc-block',
            label:
                '#Tecentriq+Avastin在台灣肝癌核准適應症與Avastin (bevacizumab)併用，適用於治療未曾接受全身性療法且無法切除或轉移之肝細胞癌病人，且肝功能為Child-Pugh A。',
        },
        {
            fieldName: 'category',
            component: 'radio-select',
            label: 'Q：此病人是屬於哪一類？',
            selections: [
                {
                    label: 'CR',
                    value: 'CR',
                },
                {
                    label: 'PR',
                    value: 'PR',
                },
                {
                    label: 'SD且打滿10 cycles（以上）',
                    value: 'SD且打滿10 cycles↑',
                },
                {
                    label: 'Atezolizumab+Bevacizumab Curative Conversion (ABC conversion)',
                    value: 'ABC conversion',
                }
            ],
        },
    ],
    [
        {
            component: 'title-block',
            label:
                'Pre-treatment<br />Patient demographic',
        },
        {
            fieldName: 'gender',
            component: 'radio-select',
            label: 'Gender',
            selections: [
                {
                    label: 'Male',
                    value: 'Male',
                },
                {
                    label: 'Female',
                    value: 'Female',
                },
            ],
        },
        {
            fieldName: 'age',
            component: 'number-field',
            label: 'Age',
            placeholder: '請輸入',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'diagnoseCancer',
            component: 'date-picker',
            label: '診斷肝癌的日期',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'cancerCategory',
            component: 'radio-select',
            label: 'Etiology',
            selections: [
                {
                    label: 'HBV',
                    value: 'HBV',
                },
                {
                    label: 'HCV',
                    value: 'HCV',
                },
                {
                    label: 'non-viral',
                    value: 'non-viral',
                },
            ],
        },
        {
            component: 'desc-block',
            label:
                '先前做過的治療：',
        },
        {
            fieldName: 'preTACE',
            component: 'amount-change-field',
            indexLabel: true,
            label: 'TACE',
            children: [
                {
                    fieldName: 'preTACE_item1',
                    component: 'date-picker',
                },
            ],
        },
        {
            fieldName: 'preRFA',
            component: 'amount-change-field',
            indexLabel: true,
            label: 'RFA',
            children: [
                {
                    fieldName: 'preRFA_item1',
                    component: 'date-picker',
                },
            ],
        },
        {
            fieldName: 'preSurgery',
            component: 'amount-change-field',
            indexLabel: true,
            label: 'Surgery',
            children: [
                {
                    fieldName: 'preSurgery_item1',
                    component: 'date-picker',
                },
            ],
        },
        {
            fieldName: 'preOther',
            component: 'amount-change-field',
            indexLabel: true,
            label: '其他',
            children: [
                {
                    fieldName: 'preOther_item2',
                    component: 'date-picker',
                },
                {
                    fieldName: 'preOther_item_text',
                    component: 'text-field',
                },
            ],
        },
        {
            fieldName: 'history',
            component: 'check-select',
            label: '肝臟相關病史',
            selections: [
                {
                    label: '脂肪肝',
                    value: '脂肪肝',
                },
                {
                    label: '肝硬化',
                    value: '肝硬化',
                },
                {
                    label: '酒精性肝炎',
                    value: '酒精性肝炎',
                },
                {
                    label: '其他',
                    type: 'text',
                    value: '其他',
                    width: 130,
                },
            ],
        },
        {
            component: 'title-block',
            label:
                '接受Tecentriq + Avastin治療之前的病人表徵',
        },
        {
            fieldName: 'afp',
            component: 'text-field',
            label: 'AFP',
            suffix: 'ng/mL',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'albumin',
            component: 'number-field',
            label: 'Albumin',
            suffix: 'g/dl',
        },
        {
            fieldName: 'wbc',
            component: 'number-field',
            label: 'WBC',
            suffix: '10<sup>3</sup>/mm<sup>3</sup>',
        },
        {
            fieldName: 'plt',
            component: 'number-field',
            label: 'PLT',
            suffix: '10<sup>3</sup>/uL',
        },
        {
            fieldName: 'totalBilirubin',
            component: 'number-field',
            label: 'Total Bilirubin',
            suffix: 'mg/dL',
        },
        {
            fieldName: 'ast',
            component: 'number-field',
            label: 'AST',
            suffix: 'U/L',
        },
        {
            fieldName: 'alt',
            component: 'number-field',
            label: 'ALT',
            suffix: 'U/L',
        },
        {
            fieldName: 'ptInr',
            component: 'text-field',
            label: 'PT-INR',
            placeholder: '請輸入',
        },
        {
            fieldName: 'BCLC',
            component: 'radio-select',
            label: 'BCLC',
            selections: [
                {
                    label: 'A',
                    value: 'A',
                },
                {
                    label: 'B',
                    value: 'B',
                },
                {
                    label: 'C',
                    value: 'C',
                },
            ],
        },
        {
            fieldName: 'esophagealVarices',
            component: 'radio-select',
            label: 'Esophageal Varices',
            selections: [
                {
                    label: 'Yes',
                    value: 'Yes',
                },
                {
                    label: 'No',
                    value: 'No',
                },
            ],
        },
        {
            fieldName: 'liverTumorSize',
            component: 'text-field',
            label: 'Liver Tumor size',
            supplement: {
                label: '(請填最大顆腫瘤的size)',
            },
            suffix: 'cm',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'numbersOfTumor',
            component: 'text-field',
            label: '肝臟內的腫瘤數目',
            suffix: '顆',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'site',
            component: 'amount-changeable-check-select',
            label: '是否有肝外轉移的器官',
            column: true,
            selections: [
                {
                    label: '無',
                    value: '無',
                },
                {
                    label: 'Bone',
                    value: 'Bone',
                },
                {
                    label: 'Lung',
                    value: 'Lung',
                },
            ],
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'tumorSize',
            component: 'text-field',
            label: '轉移器官處的tumor size',
            supplement: {
                label: '(請填最大顆腫瘤)',
            },
            suffix: 'cm',
        },
        {
            fieldName: 'preCT_MRI',
            component: 'album-picker',
            label: 'CT/MRI(至多三張)',
            supplement: {
                label: '請確認圖片上所有病患資訊已去除',
                fontSize: 14,
            },
            rules: {
                required: true,
            },
            children: [
                {
                    fieldName: 'preCT_MRI1',
                    label: 'CT/MRI照上傳',
                    component: 'picture-picker',
                    date: true,
                },
                {
                    fieldName: 'preCT_MRI2',
                    label: 'CT/MRI照上傳',
                    component: 'picture-picker',
                    date: true,
                },
                {
                    fieldName: 'preCT_MRI3',
                    label: 'CT/MRI照上傳',
                    component: 'picture-picker',
                    date: true,
                },
            ],
        },
    ],
    [
        {
            component: 'title-block',
            label:
                '接受Tecentriq + Avastin治療期間AFP 或 PIVKA-II 之變化',
        },
        {
            fieldName: 'cycles',
            component: 'amount-change-field',
            label: 'Cycles',
            indexLabel: true,
            cols: 2,
            children: [
                {
                    fieldName: 'cycles_item1',
                    component: 'date-picker',
                    width: 170,
                },
                {
                    fieldName: 'cycles_item2',
                    component: 'number-field',
                    width: 150,
                    placeholder: '請輸入數值',
                    prefix: 'AFP',
                    suffix: 'ng/ml',
                    compact: true,
                },
                {
                    fieldName: 'cycles_item3',
                    component: 'date-picker',
                    width: 170,
                },
                {
                    fieldName: 'cycles_item4',
                    component: 'number-field',
                    width: 150,
                    placeholder: '請輸入數值',
                    prefix: 'PIVKA',
                    suffix: 'mAU/mL',
                    compact: true,
                },
            ],
        },
        {
            fieldName: 'cycleTimes',
            component: 'number-field',
            label: 'Total cycles',
            suffix: 'times',
        },
        {
            component: 'title-block',
            label:
                '接受Tecentriq + Avastin治療之後的病人表徵',
        },
        {
            fieldName: 'response',
            component: 'radio-select',
            label: '病人產生response的時間日期',
            column: true,
            selections: [
                {
                    label: 'CR',
                    type: 'date',
                    value: 'CR',
                },
                {
                    label: 'PR',
                    type: 'date',
                    value: 'PR',
                },
                {
                    label: 'SD',
                    type: 'date',
                    value: 'SD',
                },
            ],
        },
        {
            component: 'desc-block',
            label:
                '病人產生response時的生化數值：',
        },
        {
            fieldName: 'postAfp',
            component: 'text-field',
            label: 'AFP',
            suffix: 'ng/mL',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'postAlbumin',
            component: 'number-field',
            label: 'Albumin',
            suffix: 'g/dl',
        },
        {
            fieldName: 'postWbc',
            component: 'number-field',
            label: 'WBC',
            suffix: '10<sup>3</sup>/mm<sup>3</sup>',
        },
        {
            fieldName: 'postPlt',
            component: 'number-field',
            label: 'PLT',
            suffix: '10<sup>3</sup>/uL',
        },
        {
            fieldName: 'postTotalBilirubin',
            component: 'number-field',
            label: 'Total Bilirubin',
            suffix: 'mg/dL',
        },
        {
            fieldName: 'postAst',
            component: 'number-field',
            label: 'AST',
            suffix: 'U/L',
        },
        {
            fieldName: 'postAlt',
            component: 'number-field',
            label: 'ALT',
            suffix: 'U/L',
        },
        {
            fieldName: 'postPtInr',
            component: 'text-field',
            label: 'PT-INR',
            placeholder: '請輸入',
        },
        {
            fieldName: 'postLiverTumorSize',
            component: 'text-field',
            label: 'Liver Tumor size',
            suffix: 'cm',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'postNumbersOfTumor',
            component: 'text-field',
            label: '肝臟內的腫瘤數目',
            suffix: '顆',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'postSite',
            component: 'amount-changeable-check-select',
            label: '是否有肝外轉移的器官',
            column: true,
            selections: [
                {
                    label: '無',
                    value: '無',
                },
                {
                    label: 'Bone',
                    value: 'Bone',
                },
                {
                    label: 'Lung',
                    value: 'Lung',
                },
            ],
        },
        {
            fieldName: 'postTumorSize',
            component: 'text-field',
            label: '轉移器官處的tumor size',
            supplement: {
                label: '(請填最大顆腫瘤)',
            },
            suffix: 'cm',
        },
        {
            fieldName: 'postCT_MRI',
            component: 'album-picker',
            label: 'CT/MRI(至多三張)',
            supplement: {
                label: '請確認圖片上所有病患資訊已去除',
                fontSize: 14,
            },
            rules: {
                required: true,
            },
            children: [
                {
                    fieldName: 'postCT_MRI1',
                    label: 'CT/MRI照上傳',
                    component: 'picture-picker',
                    date: true,
                },
                {
                    fieldName: 'postCT_MRI2',
                    label: 'CT/MRI照上傳',
                    component: 'picture-picker',
                    date: true,
                },
                {
                    fieldName: 'postCT_MRI3',
                    label: 'CT/MRI照上傳',
                    component: 'picture-picker',
                    date: true,
                },
            ],
        },
        {
            component: 'desc-block',
            label:
                'T+A治療期間是否合併其他療法：',
        },
        {
            fieldName: 'combineTACE',
            component: 'amount-change-field',
            indexLabel: true,
            label: 'TACE',
            children: [
                {
                    fieldName: 'combineTACE_item1',
                    component: 'date-picker',
                },
            ],
        },
        {
            fieldName: 'combineRFA',
            component: 'amount-change-field',
            indexLabel: true,
            label: 'RFA',
            children: [
                {
                    fieldName: 'combineRFA_item1',
                    component: 'date-picker',
                },
            ],
        },
        {
            fieldName: 'combineSurgery',
            component: 'amount-change-field',
            indexLabel: true,
            label: 'Surgery',
            children: [
                {
                    fieldName: 'combineSurgery_item1',
                    component: 'date-picker',
                },
            ],
        },
        {
            fieldName: 'combineOther',
            component: 'amount-change-field',
            indexLabel: true,
            label: '其他',
            children: [
                {
                    fieldName: 'combineOther_item2',
                    component: 'date-picker',
                },
                {
                    fieldName: 'combineOther_item_text',
                    component: 'text-field',
                },
            ],
        },
        {
            fieldName: 'combineCT_MRI',
            component: 'album-picker',
            label: 'CT/MRI(至多三張)',
            supplement: {
                label: '請確認圖片上所有病患資訊已去除',
                fontSize: 14,
            },
            children: [
                {
                    fieldName: 'combineCT_MRI1',
                    label: 'CT/MRI照上傳',
                    component: 'picture-picker',
                    date: true,
                },
                {
                    fieldName: 'combineCT_MRI2',
                    label: 'CT/MRI照上傳',
                    component: 'picture-picker',
                    date: true,
                },
                {
                    fieldName: 'combineCT_MRI3',
                    label: 'CT/MRI照上傳',
                    component: 'picture-picker',
                    date: true,
                },
            ],
        },
        {
            fieldName: 'impression',
            component: 'text-area',
            placeholder: '我對於這位病人治療後的描述及感動',
            label: '我有話想說',
            column: true,
            rows: 10,
        },
    ],
];
