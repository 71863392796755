<template>
    <h2 class="subtitle" v-html="label"></h2>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralDisplay';

export default {
    mixins: [GeneralMixins],
};
</script>

<style lang="scss" scoped>
.subtitle{
    margin-top: 0;
    margin-bottom: 30px;
}
</style>
