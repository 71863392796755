export default {
    path: '/:disease/sales',
    name: 'Sales_Index',
    component: () => import('../views/Sales/index.vue'),
    children: [
        {
            name: 'Sales_Case_Index',
            path: 'my-case',
            component: () => import('../views/Sales/CaseIndex.vue'),
        },
        {
            name: 'Sales_Case_List',
            path: 'case',
            component: () => import('../views/Sales/CaseList.vue'),
        },
        {
            name: 'Sales_Case_Leader_Board',
            path: 'leader-board',
            component: () => import('../views/Sales/LeaderBoard.vue'),
        },
        {
            name: 'Sales_Form',
            path: 'form/:id',
            component: () => import('../views/Sales/Form.vue'),
        },
        {
            name: 'Sales_Case',
            path: 'case/:id',
            component: () => import('../views/Sales/Case.vue'),
        },
        {
            path: '*',
            redirect: { name: 'Sales_Case_List' },
        },
    ],

};
