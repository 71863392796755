<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field"
    >
        <div v-if="config.label" class="label">
            <div class="main-label">
                <span>{{ config.label }}</span>
                <span v-if="config.rules && config.rules.required" class="star">
                    *
                </span>
            </div>
            <div
                class="description-label"
                :style="{
                    'font-size': `${
                        config.supplement && config.supplement.fontSize
                    }px`,
                }"
            >
                {{ config.supplement && config.supplement.label }}
            </div>
        </div>
        <div
            v-if="isEditMode && config.prefix"
            class="prefix"
            v-html="config.prefix"
        ></div>
        <el-form-item v-if="isEditMode" :prop="config.fieldName" :rules="rules">
            <el-input
                v-if="isEditMode"
                :value="value"
                type="number"
                class="input"
                :style="{ width: `${config.width}px` }"
                :placeholder="config.placeholder || '請輸入數值'"
                @input="$emit('input', $event)"
                @change="$emit('change', value)"
            ></el-input>
        </el-form-item>
        <div
            v-else-if="value"
            class="input-preview"
            :class="{ compact: isLayoutCompact }"
        >
            <div
                v-if="value && config.prefix"
                class="prefix"
                v-html="config.prefix"
            ></div>
            <div>{{ value }}</div>
            <div
                v-if="value && config.suffix"
                class="suffix"
                v-html="config.suffix"
            ></div>
        </div>
        <div
            v-if="isEditMode && config.suffix"
            class="suffix"
            v-html="config.suffix"
        ></div>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralQuestionField';

export default {
    mixins: [GeneralMixins],
    computed: {
        isLayoutCompact() {
            return !!this.config.compact;
        },
    }
};
</script>
<style lang="scss" scoped>
.input-preview.compact {
    > div {
        width: auto !important;
    }
}
</style>
