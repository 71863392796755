import _ from 'lodash';

export default {
    methods: {
        originalOption(selections, value) {
            return _.chain(selections)
                .filter((o) => o.type === undefined)
                .filter((o) => o.value === value)
                .first()
                .value();
        },
        dropdownOption(selections, value) {
            return _.chain(selections)
                .filter(['type', 'dropdown'])
                .filter((o) => _.find(o.selections, ['value', value]))
                .first()
                .value();
        },
        dateOption(selections, value) {
            const SELECTION_VALUE = value.split(', ')[0];
            return _.chain(selections)
                .filter({ type: 'date', value: SELECTION_VALUE })
                .first()
                .value();
        },
        textOption(selections) {
            return _.chain(selections)
                .filter(['type', 'text'])
                .first()
                .value();
        },
        comboTextOption(selections, value) {
            return _.chain(selections)
                .filter(['type', 'combo-text'])
                .filter((item) => {
                    const pattern = item.value.replace('{value}', '.*');
                    return !!value && !!value.match(pattern);
                })
                .first()
                .value();
        },
        numberOption(selections) {
            return _.chain(selections)
                .filter(['type', 'number'])
                .first()
                .value();
        },
    },
};
