export default [
    [
        {
            component: 'filter-check-box',
            config: {
                fieldName: 'category',
                placeholder: 'Category',
                options: [
                    {
                        label: '腎',
                        value: '腎',
                    },
                    {
                        label: '上',
                        value: '上',
                    },
                    {
                        label: '老',
                        value: '老',
                    },
                    {
                        label: '人',
                        value: '人',
                    },
                    {
                        label: 'CR',
                        value: 'CR',
                    },
                    {
                        label: 'PR',
                        value: 'PR',
                    },
                ],
            },
        },
    ],
    [
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'gender',
                placeholder: 'Gender',
                options: [
                    {
                        label: '男',
                        value: 'Male',
                        type: 'normal',
                    },
                    {
                        label: '女',
                        value: 'Female',
                        type: 'normal',
                    },
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'age',
                placeholder: 'Age',
                options: [
                    {
                        label: '70(含)以下',
                        value: {
                            column: 'age',
                            value: '<=70',
                        },
                        type: 'operator',
                    },
                    {
                        label: '70以上',
                        value: {
                            column: 'age',
                            value: '>70',
                        },
                        type: 'operator',
                    },
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'PDL1SP142IC',
                placeholder: 'PDL1 SP142 IC',
                options: [
                    {
                        label: 'Unknown',
                        value: 'Unknown',
                        type: 'normal',
                    },
                    {
                        label: '≥5%',
                        value: {
                            column: 'PDL1SP142IC',
                            value: '>=5',
                        },
                        type: 'operator',
                    },
                    {
                        label: '<5%',
                        value: {
                            column: 'PDL1SP142IC',
                            value: '0.0000001-5',
                        },
                        type: 'operator',
                    },
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'site',
                placeholder: 'Site',
                options: [
                    {
                        label: 'UTUC',
                        value: 'UTUC',
                        type: 'normal',
                    },
                    {
                        label: 'UBUC',
                        value: 'UBUC',
                        type: 'normal',
                    },
                ],
            },
        },
    ],
];
