<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field"
    >
        <div v-if="config.label" class="label">
            <div class="main-label">
                <span>{{ config.label }}</span>
                <span v-if="config.rules && config.rules.required" class="star">
                    *
                </span>
            </div>
            <div
                class="description-label"
                :style="{
                    'font-size': `${
                        config.supplement && config.supplement.fontSize
                    }px`,
                }"
            >
                {{ config.supplement && config.supplement.label }}
            </div>
        </div>
        <div v-if="config.prefix" class="prefix" v-html="config.prefix"></div>
        <el-form-item v-if="isEditMode" :prop="config.fieldName" :rules="rules">
            <el-date-picker
                :value="value"
                :type="config.type || 'date'"
                class="input"
                :value-format="format"
                :style="{ width: `${config.width}px` }"
                :placeholder="config.placeholder || '請選擇'"
                @input="$emit('input', $event)"
                @change="$emit('change', value)"
            >
            </el-date-picker>
        </el-form-item>
        <div v-else-if="value" class="input-preview">
            <div>{{ value }}</div>
        </div>
        <div v-if="config.suffix" class="suffix" v-html="config.suffix"></div>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralQuestionField';

export default {
    mixins: [GeneralMixins],
    computed: {
        format() {
            if (this.config.type === 'month') return 'yyyy-MM';
            return 'yyyy-MM-dd';
        },
    },
};
</script>
