<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field checkbox-select"
    >
        <div v-if="config.label" class="label">
            <div class="main-label">
                <span>{{ config.label }}</span>
                <span v-if="config.rules && config.rules.required" class="star">
                    *
                </span>
            </div>
            <div
                class="description-label"
                :style="{
                    'font-size': `${config.supplement && config.supplement.fontSize}px`
                }"
            >
                {{ config.supplement && config.supplement.label }}
            </div>
        </div>
        <div v-if="config.prefix" class="prefix" v-html="config.prefix"></div>
        <el-checkbox-group
            class="checkbox-group"
            v-if="isEditMode"
            v-model="selections"
            :class="{ column: config.column }"
            @change="$event => handleChange($event)"
        >
            <el-checkbox v-for="(item, i) in config.selections" :key="i" :label="item.value">
                {{ item.label }}
                <el-input
                    v-if="item.type === 'text'"
                    v-model="customAnswer[item.value]"
                    :style="{ 'max-width': `${item.width || 200}px` }"
                    :disabled="!selections.includes(item.value)"
                    @change="$event => handleChange($event)"
                ></el-input>
                <el-input
                    v-if="item.type === 'combo-text'"
                    v-model="customAnswer[item.value]"
                    :style="{ 'max-width': `${item.width || 200}px` }"
                    :disabled="!selections.includes(item.value)"
                    @change="$event => handleChange($event)"
                ></el-input>
                <el-select
                    v-if="item.type === 'dropdown'"
                    v-model="customAnswer[item.value]"
                    :disabled="!selections.includes(item.value)"
                    style="max-width: 200px"
                    placeholder=""
                    @change="$event => handleChange($event)"
                >
                    <el-option
                        v-for="selectItem in item.selections"
                        :key="selectItem.value"
                        :label="selectItem.label"
                        :value="selectItem.value"
                    >
                    </el-option>
                </el-select>
                {{ item.suffix }}
            </el-checkbox>
        </el-checkbox-group>
        <div v-else-if="value" class="input-preview">
            <div>
                {{ value && value.join(', ') }}
            </div>
        </div>
        <div v-if="config.suffix" class="suffix" v-html="config.suffix"></div>
    </div>
</template>

<script>
import _ from 'lodash';
import GeneralMixins from '@/mixins/GeneralQuestionField';
import SelectMixins from '@/mixins/SelectQuestion';

export default {
    mixins: [GeneralMixins, SelectMixins],
    data() {
        return {
            selections: [],
            customAnswer: {}
        };
    },
    created() {
        this.initialSelection();
    },
    methods: {
        initialSelection() {
            if (!this.value) return;
            const SELECTIONS = this.config.selections;
            this.value.forEach(item => {
                const ORIGINAL = this.originalOption(SELECTIONS, item);
                if (ORIGINAL) this.selections.push(item);
                else {
                    const dropdownOption = this.dropdownOption(SELECTIONS, item);
                    const comboTextOption = this.comboTextOption(SELECTIONS, item);
                    if (dropdownOption) {
                        const VALUE = dropdownOption.value;
                        this.selections.push(VALUE);
                        this.$set(this.customAnswer, VALUE, item);
                    } else if (comboTextOption) {
                        this.selections.push(comboTextOption.value);
                        const pattern = comboTextOption.value.replace('{value}', '(.*)');
                        const customAnswerValue = item.match(pattern);
                        if (customAnswerValue) {
                            this.$set(this.customAnswer, comboTextOption.value, customAnswerValue[1]);
                        }
                    } else {
                        const TEXT = this.textOption(SELECTIONS);
                        const VALUE = TEXT.value;
                        this.selections.push(VALUE);
                        this.$set(this.customAnswer, VALUE, item);
                    }
                }
            });
        },
        handleChange() {
            const ANS = this.getCurrentSelectionValue();
            this.$emit('change', ANS);
        },
        getCurrentSelectionValue() {
            const ANS = [];
            this.selections.forEach(item => {
                const SELECTION = this.getSelectionByValue(item);
                if (!SELECTION.type) ANS.push(item);
                else if (SELECTION.type === 'combo-text') {
                    ANS.push(SELECTION.value.replace('{value}', this.customAnswer[SELECTION.value]));
                } else {
                    ANS.push(this.customAnswer[SELECTION.value]);
                }
            });
            return ANS;
        },
        getSelectionByValue(value) {
            return _.find(this.config.selections, ['value', value]);
        }
    }
};
</script>

<style lang="scss" scoped>
.checkbox-select {
    margin-bottom: 30px !important;
    align-items: center;
}
.checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
</style>
