export default {
    path: '/:disease/line',
    redirect: { name: 'Line_Doctor_Case_List' },
    component: () => import('../views/Doctor/Line/index.vue'),
    children: [
        {
            name: 'LineSplashScreen',
            path: 'splash',
            component: () => import('../views/Doctor/Line/SplashScreen.vue'),
        },
        {
            name: 'Line_NON_HCP',
            path: 'non-hcp',
            component: () => import('../views/Doctor/Line/NonHCP.vue'),
        },
        {
            name: 'Line_Doctor_Case_List',
            path: 'doctor/cases',
            component: () => import('../views/Doctor/Line/CaseList.vue'),
        },
        {
            name: 'Line_Doctor_Case',
            path: 'doctor/case/:id',
            component: () => import('../views/Doctor/Line/Case.vue'),
        },
        {
            path: '*',
            redirect: { name: 'Line_Doctor_Case_List' },
        },
    ],
};
