export default [
    [
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'category',
                placeholder: 'Category',
                options: [
                    {
                        label: 'Stage 3 Optimal',
                        value: 'Stage 3 Optimal',
                        type: 'normal',
                    },
                    {
                        label: 'Stage 3 sub-optimal',
                        value: 'Stage 3 sub-optimal',
                        type: 'normal',
                    },
                    {
                        label: 'Stage 4',
                        value: 'Stage 4',
                        type: 'normal',
                    },
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'response',
                placeholder: 'Response',
                options: [
                    {
                        label: 'CR',
                        value: 'CR',
                        type: 'normal',
                    },
                    {
                        label: 'PR',
                        value: 'PR',
                        type: 'normal',
                    },
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'histologyType',
                placeholder: 'Histology Type',
                options: [
                    {
                        label: 'Clear-cell',
                        value: 'Clear-cell',
                        type: 'normal',
                    },
                    {
                        label: 'Mucinous',
                        value: 'Mucinous',
                        type: 'normal',
                    },
                ],
            },
        },
        {
            component: 'filter-drop-down',
            config: {
                fieldName: 'BRCAStatus',
                placeholder: 'BRCA Status',
                options: [
                    {
                        label: 'BRCAm',
                        value: 'BRCAm',
                        type: 'normal',
                    },
                    {
                        label: 'BRCAmt',
                        value: 'BRCAmt',
                        type: 'normal',
                    },
                ],
            },
        },
    ],
];
