<template>
    <p class="description" v-html="label"></p>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralDisplay';

export default {
    mixins: [GeneralMixins],
};
</script>

<style lang="scss" scoped>
.description {
    line-height: 28px;
    margin-top: 30px;
    margin-bottom: 14px;
}
</style>
