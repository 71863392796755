<template>
    <div id="app">
        <veeva-code />
        <router-view />
        <transition name="fade" ease="in-out">
            <Confirmation v-if="confirmationPopUp" />
        </transition>
        <transition name="fade" ease="in-out">
            <Loading v-if="loadingPopUp" />
        </transition>
    </div>
</template>

<script>
import Confirmation from '@/components/Popup/Confirmation.vue';
import Loading from '@/components/Popup/Loading.vue';
import { mapState } from 'vuex';
import CookieConsentMixin from '@/mixins/CookieConsent';
import VeevaCode from './components/VeevaCode.vue';

export default {
    mixins: [CookieConsentMixin],
    components: { Confirmation, Loading, VeevaCode },
    computed: {
        ...mapState({
            confirmationPopUp: state => state.popup.confirmationPopUp,
            loadingPopUp: state => state.popup.loadingPopUp
        })
    }
};
</script>

<style lang="scss">
@import '/tinymce/css/index.css';
@import '@/scss/main.scss';

html,
body {
    margin: 0;
    padding: 0;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    overflow: auto;
}
</style>
