import axios from 'axios';

const { VUE_APP_API_BASE_URL } = process.env;

class API {
    constructor() {
        this.token = undefined;
        this.disease = undefined;
        this.initInstance();
    }

    initInstance() {
        this.instance = axios.create({
            baseURL: VUE_APP_API_BASE_URL,
            timeout: 20000,
            headers: {
                'X-Custom-Header': 'foobar',
                Authorization: `bearer ${this.token}`,
            },
        });
    }

    setToken(token) {
        this.token = token;
        this.initInstance();
    }

    setDisease(disease) {
        this.disease = disease;
    }

    get Disease() {
        const PREFIX = 'disease';
        return {
            list: () => this.instance.get(`/${PREFIX}/list`),
        };
    }

    get Auth() {
        const PREFIX = 'auth';
        return {
            verifyCaseCode: (data) => this.instance.post(`/${PREFIX}/verify-case-code/${data.id}`, data.code),
            caseTokenVerify: (id) => this.instance.post(`/${PREFIX}/case-token-verify/${id}`),
            verifyOauthToken: (authCode) => this.instance.post(`/${PREFIX}/${this.disease}/verify-oauth-token`, authCode),
            verifyJwtToken: () => this.instance.post(`/${PREFIX}/${this.disease}/verify-jwt-token`),
            CLMLogin: (data) => this.instance.post(`/${PREFIX}/${this.disease}/clm-login`, data),
            LineLogin: (data) => this.instance.post(`/${PREFIX}/${this.disease}/line-login`, data),
        }
    }
    get ShareDisclaimer() {
        const PREFIX = 'share-disclaimer';
        return {
            create: (data) => this.instance.post(`/${PREFIX}/${this.disease}`, data),
            fetch: () => this.instance.get(`/${PREFIX}/${this.disease}`),
        }
    }
    get ViewDisclaimer() {
        const PREFIX = 'view-disclaimer';
        return {
            create: (data) => this.instance.post(`/${PREFIX}/${this.disease}`, data),
            fetch: () => this.instance.get(`/${PREFIX}/${this.disease}`),
        }
    }

    get Account() {
        const PREFIX = 'account';
        return {
            createSales: (submit) => this.instance.post(`/${PREFIX}/${this.disease}/sales`, submit),
            searchSales: (query) => this.instance.get(`/${PREFIX}/${this.disease}/search${query}&auth=sales`),
            updateSales: (data) => this.instance.put(`/${PREFIX}/${this.disease}/update/${data.id}`, data.data),

            deactivate: (ids) => this.instance.post(`/${PREFIX}/${this.disease}/deactivate`, { ids }),
            renewToken: (ids) => this.instance.post(`/${PREFIX}/${this.disease}/renew-token/`, { ids }),

            transferCase: (submit) => this.instance.post(`/${PREFIX}/${this.disease}/transfer-case`, submit),

            // super function
            superSearchSales: (disease) => this.instance.get(`/${PREFIX}/${disease}/search?limit=${999}&page=${1}`),
            superChangeAuth: (data) => this.instance.put(`/${PREFIX}/${data.disease}/change-auth/${data.id}`, { auth: data.toAuth }),
            superCreateSales: (data) => this.instance.post(`/${PREFIX}/${data.disease}/sales`, data.submit),
            superCreateManager: (data) => this.instance.post(`/${PREFIX}/${data.disease}/manager`, data.submit),
            superUpdateAccount: (data) => this.instance.put(`/${PREFIX}/${data.disease}/update/${data.id}`, data.submit),
            superDeactivate: (data) => this.instance.post(`/${PREFIX}/${data.disease}/deactivate`, { ids: data.ids }),
            superRenewToken: (data) => this.instance.post(`/${PREFIX}/${data.disease}/renew-token/`, { ids: data.ids }),
        };
    }

    get Case() {
        const PREFIX = 'case';
        return {
            create: (submit) => this.instance.post(`/${PREFIX}/${this.disease}`, submit),

            searchSalesSelfCase: (query) => this.instance.get(`/${PREFIX}/${this.disease}/sales/self-search${query}`),
            searchManagerCase: (query) => this.instance.get(`/${PREFIX}/${this.disease}/manager/search${query}`),
            searchSalesDisplayCase: (data) => this.instance.post(`/${PREFIX}/${this.disease}/sales/display-search${data.query}`, data.data),

            find: (id) => this.instance.get(`/${PREFIX}/${this.disease}/data/${id}`),
            delete: (id) => this.instance.delete(`/${PREFIX}/${this.disease}/${id}`),
            bulkDelete: (data) => this.instance.post(`/${PREFIX}/${this.disease}/delete`, data),
            bulkOffShelf: (data) => this.instance.put(`/${PREFIX}/${this.disease}/take-off`, data),
            saveDraft: (data) => this.instance.put(`/${PREFIX}/${this.disease}/draft/${data.id}`, data.content),
            doctorSave: (data) => this.instance.put(`/${PREFIX}/${this.disease}/doctor-save/${data.id}`, data.content),
            doctorSend: (data) => this.instance.put(`/${PREFIX}/${this.disease}/doctor-send/${data.id}`, data.content),

            review: (id) => this.instance.put(`/${PREFIX}/${this.disease}/review/${id}`),
            reject: (id) => this.instance.put(`/${PREFIX}/${this.disease}/reject/${id}`),
            approve: (id) => this.instance.put(`/${PREFIX}/${this.disease}/approve/${id}`),
            display: (data) => this.instance.put(`/${PREFIX}/${this.disease}/display/${data.id}`, data.veeva),
            getExcelList: (data) => this.instance.post(`/${PREFIX}/${this.disease}/excel-list`, data),

            aeConfirm: (id) => this.instance.post(`/${PREFIX}/${this.disease}/ae-confirm/${id}`),

            addViewCount: (id) => this.instance.post(`/${PREFIX}/${this.disease}/view-count/${id}`),
            addLineViewCount: (id) => this.instance.post(`/${PREFIX}/${this.disease}/line-view-count/${id}`),
        };
    }

    get CMS() {
        const PREFIX = 'cms-data';
        return {
            search: (name) => this.instance.get(`/${PREFIX}/doctor-list?name=${name}`),
        };
    }

    get Util() {
        const PREFIX = 'util';
        return {
            uploadImage: (data) => this.instance.post(`/${PREFIX}/upload/image`, data),
        };
    }

    get KeyMessage() {
        const PREFIX = 'key-message';
        return {
            create: (data) => this.instance.post(`/${PREFIX}/${this.disease}`, data),
            update: (data) => this.instance.put(`/${PREFIX}/${this.disease}/${data.id}`, data.data),
            delete: (id) => this.instance.delete(`/${PREFIX}/${this.disease}/${id}`),
            search: () => this.instance.get(`/${PREFIX}/${this.disease}/search`),
        };
    }

    get Headline() {
        const PREFIX = 'news-ticker';
        return {
            update: (data) => this.instance.post(`/${PREFIX}/${this.disease}`, data),
            get: () => this.instance.get(`/${PREFIX}/${this.disease}`),
        };
    }
}

export default new API();
