<template>
    <div
        :class="`button-wrapper ${type}-button color-set-${color} ${
            disabled ? 'disabled' : ''
        } ${pressed ? 'pressed' : ''}`"
        :style="{ width: `${width}px` }"
        @click="handleClick"
    >
        <span>
            <i v-if="plus" :class="`plus color-set-${color} el-icon-plus`"> </i>
            <slot />
        </span>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
        },
        disabled: {
            type: Boolean,
        },
        pressed: {
            type: Boolean,
        },
        type: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '',
        },
        plus: {
            type: Boolean,
        },
    },
    methods: {
        handleClick() {
            if (this.disabled || this.pressed) return;
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 0 rgba(88, 88, 88, 0.25);
    &:hover {
        opacity: 0.8;
    }
}
.plus {
    border-radius: 50%;
    margin-right: 7px !important;
    padding: 2px;
    box-sizing: border-box;
}
.navigation-button {
    width: 170px;
    height: 40px;
    border-radius: 20px;
}
.modal-button {
    width: 100px;
    height: 40px;
    border-radius: 5px;
}
.disabled {
    cursor: not-allowed !important;
    opacity: 0.8;
    span {
        opacity: 0.3;
    }
}
.pressed {
    cursor: default;
    opacity: 0.5;
    &:hover {
        opacity: 0.5;
    }
}
</style>
