<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field input-boolean-field"
    >
        <div v-if="config.label" class="label">
            <div class="main-label">
                <span>{{ config.label }}</span>
                <span v-if="config.rules && config.rules.required" class="star">
                    *
                </span>
            </div>
            <div
                class="description-label"
                :style="{
                    'font-size': `${
                        config.supplement && config.supplement.fontSize
                    }px`,
                }"
            >
                {{ config.supplement && config.supplement.label }}
            </div>
        </div>
        <div
            v-if="isEditMode && config.prefix"
            class="prefix"
            v-html="config.prefix"
        ></div>
        <el-form-item v-if="isEditMode" :error="error">
            <el-input
                v-if="isEditMode"
                v-model="data.value"
                :type="config.type"
                class="input"
                :style="{ width: `${config.width}px` }"
                :placeholder="config.placeholder || '請輸入'"
                @change="handleChange"
                @blur="handleBlur"
            ></el-input>
        </el-form-item>
        <div v-else-if="value" class="input-preview">
            <div
                v-if="value && config.prefix"
                class="prefix"
                v-html="config.prefix"
            ></div>
            <div>
                {{ value.value }},
                <span v-if="value.boolean">
                    {{ config.booleanLabel }}
                </span>
            </div>
            <div
                v-if="value && config.suffix"
                class="suffix"
                v-html="config.suffix"
            ></div>
        </div>
        <div
            v-if="isEditMode && config.suffix"
            class="suffix"
            v-html="config.suffix"
        ></div>
        <el-checkbox
            v-if="isEditMode"
            v-model="data.boolean"
            class="boolean"
            label="boolean"
            @change="handleChange"
        >
            {{ config.booleanLabel }}
        </el-checkbox>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralQuestionField';

export default {
    mixins: [GeneralMixins],
    data() {
        return {
            data: {},
            error: '',
        };
    },
    created() {
        if (this.value) {
            this.$set(this.data, 'value', this.value.value);
            this.$set(this.data, 'boolean', this.value.boolean);
        }
    },
    methods: {
        handleChange() {
            const REG = this.config.rules && this.config.rules.regex;
            const MSG = this.config.rules && this.config.rules.regexMsg;
            if (REG && this.data.value && !this.data.value.match(REG)) {
                this.error = MSG;
            } else {
                this.error = '';
                this.$emit('change', this.data);
            }
        },
        handleBlur() {
            // const REQUIRED = this.config.rules && this.config.rules.required;
            // if (REQUIRED && !this.data.value) this.error = '此欄位為必填';
        },
    },
};
</script>

<style lang="scss" scoped>
.input-boolean-field {
    align-items: center;
    .boolean {
        margin-left: 15px;
    }
}
</style>
