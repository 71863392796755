export default [
    [
        {
            fieldName: 'date',
            component: 'date-picker',
            label: '日期',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'CTNumber',
            component: 'original-data',
            label: 'CT Number',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'doctorAvatar',
            component: 'avatar-picker',
            label: '醫師照片',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'doctorName',
            component: 'text-field',
            label: '醫師姓名',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'hospital',
            component: 'text-field',
            label: '醫院',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'title',
            component: 'text-field',
            label: '案例標題',
            column: true,
            width: 400,
            rules: {
                required: true,
            },
        },
        {
            component: 'desc-block',
            label:
                '您好，請問您要登人Avastin用於front-line臨床案例為下列哪一類型?',
        },
        {
            fieldName: 'frontLineType',
            component: 'radio-select',
            label: '',
            column: true,
            selections: [
                {
                    label: '第一次手術結束後第三期上皮性卵巢上皮細胞、輸卵管或原發性腹膜癌，合併化療後單用Avastin維持性療法之病人',
                    value: '第一次手術結束後第三期上皮性卵巢上皮細胞、輸卵管或原發性腹膜癌，合併化療後單用Avastin維持性療法之病人',
                },
                {
                    label: '第一次手術結束後第四期上皮性卵巢上皮細胞、輸卵管或原發性腹膜癌，合併化療後單用Avastin維持性療法之病人',
                    value: '第一次手術結束後第四期上皮性卵巢上皮細胞、輸卵管或原發性腹膜癌，合併化療後單用Avastin維持性療法之病人',
                },
                {
                    label: '不適合手術之第三期上皮性卵巢上皮細胞、輸卵管或原發性腹膜癌，合併化療後單用Avastin維持性療法之病人',
                    value: '不適合手術之第三期上皮性卵巢上皮細胞、輸卵管或原發性腹膜癌，合併化療後單用Avastin維持性療法之病人',
                },
            ],
        },
        {
            fieldName: 'category',
            component: 'radio-select',
            label: '此病人是屬於哪一類？',
            selections: [
                {
                    label: 'Stage 3 Optimal',
                    value: 'Stage 3 Optimal',
                },
                {
                    label: 'Stage 3 sub-optimal',
                    value: 'Stage 3 sub-optimal',
                },
                {
                    label: 'Stage 4',
                    value: 'Stage 4',
                },
            ],
        },
        {
            fieldName: 'response',
            component: 'radio-select',
            label: 'Response',
            selections: [
                {
                    label: 'CR',
                    value: 'CR',
                },
                {
                    label: 'PR',
                    value: 'PR',
                },
                {
                    label: 'SD',
                    value: 'SD',
                },
            ],
        },
        {
            fieldName: 'PFS',
            component: 'input-boolean-field',
            label: 'PFS(months)',
            type: 'number',
            booleanLabel: '尚未PD',
            rules: {
                required: true,
                regex: /[2-9][0-9]/,
                regexMsg: '本次收案條件為PFS>20m.o.',
            },
        },
        {
            fieldName: 'OS',
            component: 'input-boolean-field',
            label: 'OS(months)',
            type: 'number',
            booleanLabel: 'Still alive',
            rules: {
                required: true,
            },
        },
    ],
    [
        {
            component: 'title-block',
            label:
                'Patient demographic',
        },
        {
            fieldName: 'age',
            component: 'number-field',
            label: 'Age',
            placeholder: '請輸入',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'ECOG',
            component: 'radio-select',
            label: 'ECOG',
            selections: [
                {
                    label: '0',
                    value: 0,
                },
                {
                    label: '1',
                    value: 1,
                },
                {
                    label: '2',
                    value: 2,
                },
                {
                    label: '3',
                    value: 3,
                },
                {
                    label: '4',
                    value: 4,
                },
            ],
        },
        {
            fieldName: 'PreTreatmentCA125',
            component: 'number-field',
            label: 'Pre treatment CA125',
            placeholder: '請輸入',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'histologyType',
            component: 'radio-select',
            label: 'Histology Type',
            selections: [
                {
                    label: 'High-grade serous',
                    value: 'High-grade serous',
                },
                {
                    label: 'Endometrioid',
                    value: 'Endometrioid',
                },
                {
                    label: 'Clear cell',
                    value: 'Clear-cell',
                },
                {
                    label: 'Mucinous',
                    value: 'Mucinous',
                },
                {
                    label: 'Others',
                    value: 'Others',
                },
            ],
        },
        {
            fieldName: 'BRCAStatus',
            component: 'radio-select',
            label: 'BRCA status',
            selections: [
                {
                    label: 'BRCAm',
                    value: 'BRCAm',
                },
                {
                    label: 'BRCAwt',
                    value: 'BRCAwt',
                },
                {
                    label: '沒有驗BRCA',
                    value: '沒有驗BRCA',
                },
            ],
        },
        {
            fieldName: 'HRStatus',
            component: 'radio-select',
            label: 'HR status',
            selections: [
                {
                    label: 'HR deficient',
                    value: 'HR deficient',
                },
                {
                    label: 'HR proficient',
                    value: 'HR proficient',
                },
                {
                    label: '沒有驗HR',
                    value: '沒有驗HR',
                },
            ],
        },
        {
            fieldName: 'otherMedicalHistory',
            component: 'text-field',
            label: 'Other medical history',
        },
        {
            fieldName: 'pathology',
            component: 'text-field',
            label: 'Pathology',
        },
    ],
    [
        {
            component: 'title-block',
            label:
                '1L treatment',
        },
        {
            fieldName: 'treatmentHistory',
            component: 'amount-change-field',
            label: 'Treatment history',
            column: true,
            addButtonLabel: '新增一列輸入格',
            rules: {
                required: true,
            },
            children: [
                {
                    fieldName: 'treatmentHistory_item1',
                    component: 'date-picker',
                    placeholder: '月份/年份',
                    type: 'month',
                    width: 170,
                },
                {
                    fieldName: 'treatmentHistory_item2',
                    component: 'text-area',
                    placeholder: '自行輸入任何文字',
                    rows: 3,
                },
            ],
        },
        {
            fieldName: 'totalAvastinCycles',
            component: 'number-field',
            label: 'Total Avastin Cycles',
            suffix: 'cycles',
            rules: {
                required: true,
            },
        },
        {
            component: 'title-block',
            label:
                'Pretreatment',
        },
        {
            fieldName: 'PreTreatmentCA125',
            component: 'number-field',
            label: 'CA125',
            placeholder: '請輸入',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'preCT_MRI',
            component: 'album-picker',
            label: 'CT/MRI(至少上傳一張)',
            supplement: {
                label: '請確認圖片上所有病患資訊已去除',
                fontSize: 14,
            },
            rules: {
                required: true,
            },
            children: [
                {
                    fieldName: 'preCT_MRI1',
                    component: 'picture-picker',
                },
                {
                    fieldName: 'preCT_MRI2',
                    component: 'picture-picker',
                },
                {
                    fieldName: 'preCT_MRI3',
                    component: 'picture-picker',
                },
                {
                    fieldName: 'preCT_MRI4',
                    component: 'picture-picker',
                },
            ],
        },
    ],
    [
        {
            component: 'title-block',
            label:
                'Post 1L treatment',
        },
        {
            fieldName: 'postTreatmentCA125',
            component: 'number-field',
            label: 'CA125',
            placeholder: '請輸入',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'response',
            component: 'text-field',
            label: 'Response',
        },
        {
            fieldName: 'postCT_MRI',
            component: 'album-picker',
            label: 'CT/MRI(至少上傳一張)',
            supplement: {
                label: '請確認圖片上所有病患資訊已去除',
                fontSize: 14,
            },
            rules: {
                required: true,
            },
            children: [
                {
                    fieldName: 'postCT_MRI1',
                    component: 'picture-picker',
                },
                {
                    fieldName: 'postCT_MRI2',
                    component: 'picture-picker',
                },
                {
                    fieldName: 'postCT_MRI3',
                    component: 'picture-picker',
                },
                {
                    fieldName: 'postCT_MRI4',
                    component: 'picture-picker',
                },
            ],
        },
        {
            fieldName: 'CA125Pic',
            component: 'album-picker',
            label: 'CA125',
            rules: {
                required: true,
            },
            children: [
                {
                    fieldName: 'CA125Pic',
                    component: 'picture-picker',
                    label: 'CA125長期追蹤圖上傳',
                },
            ],
        },
        {
            component: 'title-block',
            label:
                'Treatment after 1L',
        },
        {
            fieldName: 'isTreatmentAfterL1Exist',
            component: 'radio-select',
            label: '是否有Treatment after L1相關數據',
            selections: [
                {
                    label: '是',
                    value: '是',
                },
                {
                    label: '否',
                    value: '否',
                },
            ],
        },
        {
            fieldName: 'pdImage',
            depend: {
                fieldName: 'isTreatmentAfterL1Exist',
                rule: /是/,
            },
            component: 'album-picker',
            label: 'PD image(至少上傳一張)',
            supplement: {
                label: '請確認圖片上所有病患資訊已去除',
                fontSize: 14,
            },
            children: [
                {
                    fieldName: 'pdImage1',
                    component: 'picture-picker',
                },
                {
                    fieldName: 'pdImage2',
                    component: 'picture-picker',
                },
                {
                    fieldName: 'pdImage3',
                    component: 'picture-picker',
                },
                {
                    fieldName: 'pdImage4',
                    component: 'picture-picker',
                },
            ],
        },
        {
            fieldName: 'TreatmentAfterPD',
            depend: {
                fieldName: 'isTreatmentAfterL1Exist',
                rule: /是/,
            },
            component: 'amount-change-field',
            label: 'Treatment after PD',
            column: true,
            addButtonLabel: '新增一列輸入格',
            children: [
                {
                    fieldName: 'TreatmentAfterPD_item1',
                    component: 'date-picker',
                    placeholder: '月份/年份',
                    type: 'month',
                    width: 170,
                },
                {
                    fieldName: 'TreatmentAfterPD_item2',
                    component: 'text-area',
                    placeholder: '自行輸入任何文字',
                    rows: 3,
                },
            ],
        },
        {
            component: 'title-block',
            label:
                'Q&A',
        },
        {
            fieldName: 'question',
            component: 'text-field',
            label: 'Question',
            placeholder: 'Question',
            width: 350,
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'answer',
            component: 'text-field',
            label: 'Answer',
            placeholder: 'Answer',
            width: 350,
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'impression',
            component: 'text-area',
            placeholder: '我對於這位病人治療後的描述及感動',
            label: '我有話想說',
            column: true,
            rows: 10,
        },
    ],
];
