import plugins from './plugins';
import view from './view';
import question from './question';
import leaderBoard from './leaderBoard';
import excelLabel from './excelLabel';
import filterTag from './filterTag';

export default {
    plugins,
    view,
    question,
    leaderBoard,
    excelLabel,
    filterTag,

    defaultPage: {
        sales: 'Sales_Case_Leader_Board',
        manager: 'Manager_Case_List',
    },
};
