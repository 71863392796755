<template>
    <div
        class="flex gap-5 my-7 leading-10"
        :class="{
            'flex-col': config.column
        }"
    >
        <span v-if="config.label" class="flex-1">{{ config.label }}</span>
        <span v-if="config.type === 'simple'" class="flex-1 sm:flex-2">{{
            value && value.length && value.join(', ')
        }}</span>
        <ol
            v-else
            class="flex-2"
            :class="{
                'list-decimal': config.indexLabel,
                'pl-7': config.indexLabel
            }"
        >
            <li v-for="(child, i) in value" :key="i">
                <span v-for="(childConfig, j) in config.children" :key="j">
                    <span
                        v-if="
                            j > 0 &&
                                child[childConfig.fieldName] &&
                                /[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(child[childConfig.fieldName])
                        "
                    >
                        ·
                    </span>
                    <span
                        v-if="childConfig.prefix && child[childConfig.fieldName]"
                        class="text-gray-question"
                    >
                        {{ childConfig.prefix }}
                    </span>
                    <span
                        v-if="child[childConfig.fieldName]"
                        v-html="previewText(child[childConfig.fieldName])"
                        class="mx-1"
                    >
                    </span>
                    <span
                        v-if="childConfig.suffix && child[childConfig.fieldName]"
                        class="text-gray-question"
                    >
                        {{ childConfig.suffix }}
                    </span>
                </span>
            </li>
        </ol>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralDisplay';
import _ from 'lodash';

export default {
    mixins: [GeneralMixins],
    methods: {
        valueKeys(item) {
            return Object.keys(item);
        },
        joinValue(obj) {
            const ARR = _.toArray(obj);
            if (this.config.join) return ARR.join(` ${this.config.join} `);
            return ARR.join(' ');
        },
        previewText(value) {
            const RES = value?.replaceAll('\n', '<br />') || '';
            return RES;
        }
    }
};
</script>
