<template>
    <div>
        <div class="flex flex-wrap justify-center sm:justify-between max-sm:text-center">
            <div class="max-w-full">
                <div class="text-lg">{{ value.date }}</div>
                <div class="text-2xl overflow-ellipsis whitespace-nowrap overflow-hidden font-bold">
                    {{ value.title }}
                </div>
            </div>
            <div class="text-base max-sm:text-sm text-gray-info">
                未經同意請勿轉載，僅供經驗分享參考，不能取代臨床醫師專業判斷
            </div>
        </div>
        <div class="my-3 border-b border-solid border-divider"></div>
        <div class="grid grid-cols-5 gap-5">
            <div
                class="col-span-full sm:col-span-3 flex max-sm:flex-col items-center gap-3 bg-white p-2.5 rounded-md"
            >
                <img
                    :src="value.doctorAvatar || require('@/assets/logo.png')"
                    class="w-20 h-20 rounded-full"
                />
                <div class="font-bold text-xl max-sm:text-center">
                    <p>{{ value.doctorName }}</p>
                    <p>{{ value.hospital }}</p>
                </div>
            </div>
            <div class="col-span-full sm:col-span-2 sm:p-5">
                <OverviewDataTag :config="config.tags" :value="value" />
            </div>
            <div class="flex items-center col-span-full gap-2">
                <small class="text-xs">&#9679;</small>
                <span class="text-xl">Patient demographic</span>
            </div>
            <OverviewDataTable
                class="col-span-full sm:col-span-3"
                :config="config.tableData"
                :value="value"
            />
            <div class="col-span-full sm:col-span-2">
                <OverviewDataLogo :config="config.logo" />
                <OverviewDataGraphic :config="config.graphic" :value="value" />
            </div>
        </div>
    </div>
</template>

<script>
import OverviewDataGraphic from '@/components/DisplayField/OverviewDataGraphic.vue';
import OverviewDataLogo from '@/components/DisplayField/OverviewDataLogo.vue';
import OverviewDataTable from '@/components/DisplayField/OverviewDataTable.vue';
import OverviewDataTag from '@/components/DisplayField/OverviewDataTag.vue';

import GeneralMixins from '@/mixins/GeneralDisplay';

export default {
    components: {
        OverviewDataTable,
        OverviewDataTag,
        OverviewDataLogo,
        OverviewDataGraphic
    },
    mixins: [GeneralMixins]
};
</script>
