<template>
    <div class="overview-data-tag max-sm:justify-center">
        <div v-for="(cell, i) in config" :key="i" class="cell">
            <div class="tag">
                <span class="overflow-ellipsis overflow-hidden whitespace-nowrap">
                    <template v-if="cell && typeof cell === 'object' && cell.type === 'constant'">{{ cell.label }}</template>
                    <template v-else>{{ value[cell] }}</template>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style lang="scss" scoped>
.overview-data-tag {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .cell {
        height: fit-content;
    }
    .tag {
        margin-left: 5px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 145px;
        height: 30px;
        border-radius: 20px;
        font-size: 15px;
        padding: 0 5px;
    }
}
</style>
