<template>
    <div class="project-veeva-code">{{ code }}</div>
</template>
<script>
export default {
    name: 'VeevaCode',
    computed: {
        code() {
            const disease = this.$route.params.disease;
            console.log('disease', disease);
            if (disease === 'lc') return 'M-TW-00004823';
            else return 'M-TW-00002257';
        }
    }
};
</script>
<style lang="scss" scoped>
.project-veeva-code {
    color: white;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 10;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}
</style>
