<template>
    <div class="pop-up" :class="$route.params.disease">
        <div
            class="mask"
            :style="{
                opacity: opacity,
                'background-color': backgroundColor
            }"
        ></div>
        <div
            class="content"
            :style="{
                width
            }"
        >
            <div
                class="card"
                :style="{
                    'background-color': cardColor
                }"
            >
                <i v-if="onClose" class="close el-icon-close" @click="onClose"> </i>
                <div class="slot">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        opacity: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: '100%'
        },
        width: {
            type: String,
            default: '500px'
        },
        backgroundColor: {
            type: String,
            default: ''
        },
        cardColor: {
            type: String,
            default: ''
        },
        onClose: {
            type: Function,
            default: undefined
        }
    }
};
</script>

<style lang="scss" scoped>
.pop-up {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    .mask {
        width: 100%;
        height: 100%;
    }
    .close {
        cursor: pointer;
        width: fit-content;
        align-self: flex-end;
    }
    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 90%;
    }
    .card {
        background: #fff;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
    }
    .slot {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        word-break: break-all;
    }
}
</style>
