<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field"
        :class="{ 'col-text-area': config.column }"
    >
        <div v-if="config.label" class="label" :class="{ 'w-auto': config.column }">
            <div class="main-label">
                <span>{{ config.label }}</span>
                <span v-if="config.rules && config.rules.required" class="star">
                    *
                </span>
            </div>
            <div
                class="description-label"
                :style="{
                    'font-size': `${config.supplement && config.supplement.fontSize}px`
                }"
            >
                {{ config.supplement && config.supplement.label }}
            </div>
        </div>
        <el-form-item v-if="isEditMode" style="width: 100%" :prop="config.fieldName" :rules="rules">
            <el-input
                :value="value"
                type="textarea"
                :readonly="!isEditMode"
                :rows="config.rows || 5"
                :placeholder="config.placeholder || '請輸入'"
                @input="$emit('input', $event)"
                @change="$emit('change', value)"
            ></el-input>
        </el-form-item>
        <div v-else-if="value" class="input-preview">
            <div v-html="previewText()" class="test"></div>
        </div>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralQuestionField';

export default {
    mixins: [GeneralMixins],
    methods: {
        previewText() {
            const RES = this.value.replaceAll('\n', '<br />');
            return RES;
        }
    }
};
</script>

<style lang="scss" scoped>
.col-text-area {
    flex-direction: column;
    .label {
        margin-bottom: 20px;
    }
}
.text {
    word-break: break-word;
}
</style>
