import Vue from 'vue';

import ButtonWrapper from '@/components/Button/ButtonWrapper.vue';

import DatePicker from '@/components/QuestionField/DatePicker.vue';
import InputBooleanField from '@/components/QuestionField/InputBooleanField.vue';
import NumberField from '@/components/QuestionField/NumberField.vue';
import TextField from '@/components/QuestionField/TextField.vue';

import AlbumPicker from '@/components/QuestionField/AlbumPicker.vue';
import AmountChangeField from '@/components/QuestionField/AmountChangeField.vue';
import AmountChangeableCheckboxSelect from '@/components/QuestionField/AmountChangeableCheckboxSelect.vue';
import AvatarPicker from '@/components/QuestionField/AvatarPicker.vue';
import CheckboxSelect from '@/components/QuestionField/CheckboxSelect.vue';
import ComboField from '@/components/QuestionField/ComboField.vue';
import DropDown from '@/components/QuestionField/DropDown.vue';
import PicturePicker from '@/components/QuestionField/PicturePicker.vue';
import RadioSelect from '@/components/QuestionField/RadioSelect.vue';
import TextArea from '@/components/QuestionField/TextArea.vue';

import AlbumDisplay from '@/components/DisplayField/AlbumDisplay.vue';
import ArrayData from '@/components/DisplayField/ArrayData.vue';
import DescBlock from '@/components/DisplayField/DescBlock.vue';
import OriginalData from '@/components/DisplayField/OriginalData.vue';
import OverviewData from '@/components/DisplayField/OverviewData.vue';
import SubtitleBlock from '@/components/DisplayField/SubtitleBlock.vue';
import TitleBlock from '@/components/DisplayField/TitleBlock.vue';

import KeyMessageDisplayBlock from '@/components/DisplayField/KeyMessageDisplayBlock.vue';

// UC Customize
import UCOverviewData from '@/components/UC/OverviewData.vue';
import UCPrePostCompare from '@/components/UC/PrePostCompare.vue';
import UCRunningSelect from '@/components/UC/RunningSelect.vue';
// End

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import InfiniteLoading from 'vue-infinite-loading';

import Clipboard from 'v-clipboard';

import App from './App.vue';
import router from './router';
import store from './store';

import "tailwindcss/tailwind.css";

Vue.config.productionTip = false;

Vue.component('button-wrapper', ButtonWrapper);

Vue.component('date-picker', DatePicker);
Vue.component('text-field', TextField);
Vue.component('number-field', NumberField);
Vue.component('input-boolean-field', InputBooleanField);
Vue.component('text-area', TextArea);
Vue.component('combo-field', ComboField);
Vue.component('picture-picker', PicturePicker);
Vue.component('avatar-picker', AvatarPicker);
Vue.component('radio-select', RadioSelect);
Vue.component('check-select', CheckboxSelect);
Vue.component('drop-down', DropDown);
Vue.component('amount-change-field', AmountChangeField);
Vue.component('amount-changeable-check-select', AmountChangeableCheckboxSelect);
Vue.component('album-picker', AlbumPicker);

Vue.component('title-block', TitleBlock);
Vue.component('subtitle-block', SubtitleBlock);
Vue.component('desc-block', DescBlock);
Vue.component('overview-data', OverviewData);
Vue.component('original-data', OriginalData);
Vue.component('array-data', ArrayData);
Vue.component('album-display', AlbumDisplay);
Vue.component('key-message-display-block', KeyMessageDisplayBlock);

// UC Customize
Vue.component('uc-overview-data', UCOverviewData);
Vue.component('uc-pre-post-compare', UCPrePostCompare);
Vue.component('uc-running-select', UCRunningSelect);
// End

Vue.use(ElementUI);
Vue.use(InfiniteLoading, {
    slots: {
        // keep default styles
        noResults: '暫無資料',
        // remove default styles
        noMore: '',
    },
    props: {
        spinner: 'spiral',
    },
    /* options */
});
Vue.use(Clipboard);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
