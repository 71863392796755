var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pop-up",class:_vm.$route.params.disease},[_c('div',{staticClass:"mask",style:({
            opacity: _vm.opacity,
            'background-color': _vm.backgroundColor
        })}),_c('div',{staticClass:"content",style:({
            width: _vm.width
        })},[_c('div',{staticClass:"card",style:({
                'background-color': _vm.cardColor
            })},[(_vm.onClose)?_c('i',{staticClass:"close el-icon-close",on:{"click":_vm.onClose}}):_vm._e(),_c('div',{staticClass:"slot"},[_vm._t("default")],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }