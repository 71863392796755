export default {
    props: {
        value: undefined,
        label: {
            type: String,
            default: '',
        },
        config: {
            type: Object,
            default: () => { },
        },
    },
};
