import question from './question';
export default [
    [
        {
            component: 'overview-data',
            tableData: [
                {
                    label: 'Gender',
                    fieldName: 'gender',
                },
                {
                    label: 'Age',
                    fieldName: 'age',
                },
                {
                    label: '抽菸史',
                    fieldName: 'smokingHistory',
                },
                {
                    label: 'Histological type',
                    fieldName: 'histologicalType',
                },
                {
                    label: 'Size (T descriptor)',
                    fieldName: 'tDescriptorSize',
                },
                {
                    label: 'Staging (per 7th edition)',
                    fieldName: 'pathologyStaging',
                },
                {
                    label: 'Lymph node status(pN)',
                    fieldName: 'pNLaymphNodeStatus',
                },
                {
                    label: 'PD-L1表現量',
                    fieldName: 'pdl1',
                },
                {
                    label: 'EGFR mutation status',
                    fieldName: 'egfrMutationStatus',
                },
                {
                    label: '手術時間/術式',
                    fieldName: 'operationTimeAndMethod',
                    formatter: ({ time, method }) => (`${time}年, ${method}`),
                },
                {
                    label: 'Adjuvant chemotherapy',
                    fieldName: 'adjuvantChemotherapy',
                },
                {
                    label: 'Adjuvant chemotherapy cycles',
                    fieldName: 'adjuvantChemotherapyCycles',
                },
            ],
            tags: [{ type: 'constant', label: 'The Goalkeeper' }],
            logo: true,
            graphic: {
                image: true,
                highlight: {
                    // color: '#ffffff',
                    bg_color: '#EABB75',
                },
                data: [],
            },
        },
    ],
    question[1],
    question[2],
];
