<template>
    <img v-if="config" :src="require(`@/assets/view/${disease}/logos.png`)" alt="" class="w-full" />
</template>

<script>
export default {
    props: {
        config: Boolean
    },
    computed: {
        disease() {
            return this.$route.params.disease;
        }
    }
};
</script>
