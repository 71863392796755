export default [
    [
        {
            fieldName: 'date',
            component: 'date-picker',
            label: '日期',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'CTNumber',
            component: 'original-data',
            label: 'CT Number',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'doctorAvatar',
            component: 'avatar-picker',
            label: '醫師照片',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'doctorName',
            component: 'text-field',
            label: '醫師姓名',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'hospital',
            component: 'text-field',
            label: '醫院',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'title',
            component: 'text-field',
            label: '案例標題',
            column: true,
            width: 400,
            rules: {
                required: true,
            },
        },
        {
            component: 'desc-block',
            label:
                '您好, 請問您要登入 Tecentriq 臨床案例為下列哪一類型?*',
        },
        {
            fieldName: 'frontLineType',
            component: 'radio-select',
            label: '',
            column: true,
            selections: [
                {
                    label: '單用於含鉑化療失敗的局部晚期或轉移性泌尿道上皮癌',
                    value: '單用於含鉑化療失敗的局部晚期或轉移性泌尿道上皮癌',
                },
                {
                    label: '單用於不適合含cisplatin療法且腫瘤PD-L1表≥5% 的局部晚期或轉移性泌尿道上皮癌',
                    value: '單用於不適合含cisplatin療法且腫瘤PD',
                },
                {
                    label: '單用於不適合任何含鉑化療的局部晚期或轉移性泌尿道上皮癌',
                    value: '單用於不適合任何含鉑化療的局部晚期或轉移性泌尿道上皮癌',
                },
            ],
        },
        {
            fieldName: 'category',
            component: 'radio-select',
            label: '此病人是屬於哪一類？',
            selections: [
                {
                    label: '腎',
                    value: '腎',
                },
                {
                    label: '上',
                    value: '上',
                },
                {
                    label: '老',
                    value: '老',
                },
                {
                    label: '人',
                    value: '人',
                },
                {
                    label: 'CR',
                    value: 'CR',
                },
                {
                    label: 'PR',
                    value: 'PR',
                },
            ],
        },
        {
            fieldName: '1L2L',
            component: 'radio-select',
            label: '1L/2L',
            selections: [
                {
                    label: '1L',
                    value: '1L',
                },
                {
                    label: '2L',
                    value: '2L',
                },
            ],
        },
        {
            fieldName: 'PFS',
            component: 'number-field',
            label: 'PFS(months)',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'OS',
            component: 'input-boolean-field',
            label: 'OS(months)',
            type: 'number',
            booleanLabel: 'Still alive',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'age',
            component: 'number-field',
            label: 'Age',
            placeholder: '請輸入',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'gender',
            component: 'radio-select',
            label: 'Gender',
            selections: [
                {
                    label: 'Male',
                    value: 'Male',
                },
                {
                    label: 'Female',
                    value: 'Female',
                },
            ],
        },
        {
            fieldName: 'otherMedicalHistory',
            component: 'text-field',
            label: 'Other medical history',
        },
        {
            fieldName: 'eGFR',
            component: 'number-field',
            label: 'eGFR',
            placeholder: '自行填寫數字',
        },
        {
            fieldName: 'ECOGPS',
            component: 'radio-select',
            label: 'ECOG PS',
            selections: [
                {
                    label: '0',
                    value: 0,
                },
                {
                    label: '1',
                    value: 1,
                },
                {
                    label: '2',
                    value: 2,
                },
                {
                    label: '3',
                    value: 3,
                },
                {
                    label: '4',
                    value: 4,
                },
            ],
        },
        {
            fieldName: 'PDL1SP142IC',
            component: 'radio-select',
            label: 'PDL1 SP142 IC',
            selections: [
                {
                    label: 'Unknown',
                    value: 'Unknown',
                },
                {
                    label: '',
                    type: 'number',
                    value: '其他',
                    placeholder: '請輸入',
                },
            ],
        },
        {
            fieldName: 'site',
            component: 'radio-select',
            label: 'Site',
            selections: [
                {
                    label: 'UTUC',
                    value: 'UTUC',
                },
                {
                    label: 'UBUC',
                    value: 'UBUC',
                },
            ],
        },
        {
            fieldName: 'pathology',
            component: 'number-field',
            label: 'Pathology',
        },
        {
            fieldName: 'running',
            component: 'uc-running-select',
            selections: [
                {
                    value: 'run_man',
                },
                {
                    value: 'run_woman',
                },
            ],
        },
        {
            fieldName: 'dateDisplayAbove',
            component: 'date-picker',
            label: '上方日期(From)',
            type: 'month',
            placeholder: '月份/年份',
        },
        {
            component: 'title-block',
            label: 'Treatment history',
        },
        {
            fieldName: 'treatmentHistory',
            component: 'amount-change-field',
            label: 'Treatment history',
            column: true,
            addButtonLabel: '新增一列輸入格',
            rules: {
                required: true,
            },
            children: [
                {
                    fieldName: 'treatmentHistory_item1',
                    component: 'date-picker',
                    placeholder: '月份/年份',
                    type: 'month',
                    width: 170,
                },
                {
                    fieldName: 'treatmentHistory_item2',
                    component: 'text-area',
                    placeholder: '自行輸入任何文字',
                    rows: 3,
                },
            ],
        },
    ],
    [
        {
            component: 'title-block',
            label: 'Pre-treat',
        },
        {
            fieldName: 'preCT_MRI',
            component: 'album-picker',
            label: 'CT/MRI(至少上傳一張)',
            supplement: {
                label: '請確認圖片上所有病患資訊已去除',
                fontSize: 14,
            },
            rules: {
                required: true,
            },
            children: [
                {
                    fieldName: 'preCT_MRI1',
                    component: 'picture-picker',
                    dateType: 'month',
                    placeholder: '月份/年份',
                    date: true,
                },
                {
                    fieldName: 'preCT_MRI2',
                    component: 'picture-picker',
                    dateType: 'month',
                    placeholder: '月份/年份',
                    date: true,
                },
                {
                    fieldName: 'preCT_MRI3',
                    component: 'picture-picker',
                    dateType: 'month',
                    placeholder: '月份/年份',
                    date: true,
                },
                {
                    fieldName: 'preCT_MRI4',
                    component: 'picture-picker',
                    dateType: 'month',
                    placeholder: '月份/年份',
                    date: true,
                },
            ],
        },
        {
            component: 'title-block',
            label: 'Post-treat',
        },
        {
            fieldName: 'postCT_MRI',
            component: 'album-picker',
            label: 'CT/MRI(至少上傳一張)',
            supplement: {
                label: '請確認圖片上所有病患資訊已去除',
                fontSize: 14,
            },
            rules: {
                required: true,
            },
            children: [
                {
                    fieldName: 'postCT_MRI1',
                    component: 'picture-picker',
                    dateType: 'month',
                    placeholder: '月份/年份',
                    date: true,
                },
                {
                    fieldName: 'postCT_MRI2',
                    component: 'picture-picker',
                    dateType: 'month',
                    placeholder: '月份/年份',
                    date: true,
                },
                {
                    fieldName: 'postCT_MRI3',
                    component: 'picture-picker',
                    dateType: 'month',
                    placeholder: '月份/年份',
                    date: true,
                },
                {
                    fieldName: 'postCT_MRI4',
                    component: 'picture-picker',
                    dateType: 'month',
                    placeholder: '月份/年份',
                    date: true,
                },
            ],
        },
    ],
    [
        {
            fieldName: 'impression',
            component: 'text-area',
            placeholder: '我對於這位病人治療後的描述及感動',
            label: '我有話想說',
            column: true,
            rows: 10,
        },
    ],
];
