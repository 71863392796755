export default {
    namespaced: true,
    state: {
        question: undefined,
        view: undefined,
        plugins: undefined,

        leaderBoard: undefined,
        excelLabel: undefined,
        filterTag: undefined,

        defaultPage: undefined,
    },
    mutations: {
        setQuestion(state, data) {
            state.question = data;
        },
        setView(state, data) {
            state.view = data;
        },
        setPlugins(state, data) {
            state.plugins = data;
        },

        setLeaderBoard(state, data) {
            state.leaderBoard = data;
        },
        setExcelLabel(state, data) {
            state.excelLabel = data;
        },
        setFilterTag(state, data) {
            state.filterTag = data;
        },

        setDefaultPage(state, data) {
            state.defaultPage = data;
        },

        init(state, disease) {
            const config = require(`@/config/${disease}/index`).default;
            state.question = config.question;
            state.view = config.view;
            state.plugins = config.plugins;
            state.leaderBoard = config.leaderBoard;
            state.excelLabel = config.excelLabel;
            state.filterTag = config.filterTag;
            state.defaultPage = config.defaultPage;
        }
    },
};
