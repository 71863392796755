<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field"
    >
        <div v-if="config.label" class="label">
            <div class="main-label">
                <span>{{ config.label }}</span>
                <span v-if="config.rules && config.rules.required" class="star">
                    *
                </span>
            </div>
            <div
                class="description-label"
                :style="{
                    'font-size': `${
                        config.supplement && config.supplement.fontSize
                    }px`,
                }"
            >
                {{ config.supplement && config.supplement.label }}
            </div>
        </div>
        <div v-if="config.prefix" class="prefix" v-html="config.prefix"></div>

        <div class="avatar-uploader" v-if="isEditMode">
            <img class="avatar" :src="value || require('@/assets/logo.png')" />
            <el-upload
                action
                :http-request="uploadImage"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
            >
                <div class="avatar-action">照片上傳</div>
            </el-upload>
        </div>
        <div v-else class="input-answer">
            <img :src="value || require('@/assets/logo.png')" class="avatar" />
        </div>
        <div v-if="config.suffix" class="suffix" v-html="config.suffix"></div>
        <transition name="fade" ease="in-out">
            <Cropper
                v-if="cropperPopup"
                :img="cropperImg"
                :fileName="fileName"
                @confirm="onConfirm"
                @close="cropperPopup = false"
            />
        </transition>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralQuestionField';
import Cropper from '@/components/Popup/Cropper.vue';

export default {
    components: { Cropper },
    mixins: [GeneralMixins],
    data() {
        return {
            cropperImg: '',
            cropperPopup: false,
            fileName: '',
        };
    },
    methods: {
        async uploadImage(raw) {
            const reader = new FileReader();
            reader.readAsDataURL(raw.file);
            reader.onload = () => {
                this.cropperImg = reader.result;
                this.cropperPopup = true;
                this.fileName = raw.file.name;
            };
        },
        async onConfirm(url) {
            this.$emit('change', url);
            this.$emit('input', url);
            this.cropperPopup = false;
        },
        beforeAvatarUpload(file) {
            const LIMIT_SIZE = 15;
            const isValidateFormat = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < LIMIT_SIZE;

            if (!isValidateFormat) {
                this.$message.error('請選擇jpg或png格式');
            }
            if (!isLt2M) {
                this.$message.error(`檔案大小不可超過 ${LIMIT_SIZE}MB!`);
            }
            return isValidateFormat && isLt2M;
        },
    },
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
}
.avatar-action {
    width: 115px;
    height: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #556080;
    color: #fff;
}
.avatar {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
    border: 1px solid;
    width: 95px;
    height: 95px;
    display: block;
}
</style>
