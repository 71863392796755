export default [
    {
        fieldName: 'date',
        label: '日期',
    },
    {
        fieldName: 'CTNumber',
        label: 'CT Number',
    },
    {
        fieldName: 'doctorAvatar',
        label: '醫師照片',
    },
    {
        fieldName: 'doctorName',
        label: '醫師姓名',
    },
    {
        fieldName: 'hospital',
        label: '醫院',
    },
    {
        fieldName: 'title',
        label: '文章標題',
    },
    {
        fieldName: 'category',
        label: 'Q：此病人是屬於哪一類？',
    },
    {
        fieldName: 'gender',
        label: 'Gender',
    },
    {
        fieldName: 'age',
        label: 'Age',
    },
    {
        fieldName: 'diagnoseCancer',
        label: '診斷肝癌的日期',
    },
    {
        fieldName: 'cancerCategory',
        label: 'Etiology',
    },
    {
        fieldName: 'preTACE',
        label: 'TACE',
        children: [
            {
                fieldName: 'preTACE_item1',
            },
        ],
    },
    {
        fieldName: 'preRFA',
        label: 'RFA',
        children: [
            {
                fieldName: 'preRFA_item1',
            },
        ],
    },
    {
        fieldName: 'preSurgery',
        label: 'Surgery',
        children: [
            {
                fieldName: 'preSurgery_item1',
            },
        ],
    },
    {
        fieldName: 'preOther',
        label: '其他',
        children: [
            {
                fieldName: 'preOther_item2',
            },
            {
                fieldName: 'preOther_item_text',
                component: 'text-field',
            },
        ],
    },
    {
        fieldName: 'history',
        label: '肝臟相關病史',
    },
    {
        fieldName: 'afp',
        label: 'AFP',
        suffix: 'ng/mL',
    },
    {
        fieldName: 'albumin',
        label: 'Albumin',
        suffix: 'g/dl',
    },
    {
        fieldName: 'wbc',
        label: 'WBC',
        suffix: '10<sup>3</sup>/mm<sup>3</sup>',
    },
    {
        fieldName: 'plt',
        label: 'PLT',
        suffix: '10<sup>3</sup>/uL',
    },
    {
        fieldName: 'totalBilirubin',
        label: 'Total Bilirubin',
        suffix: 'mg/dL',
    },
    {
        fieldName: 'ast',
        label: 'AST',
        suffix: 'U/L',
    },
    {
        fieldName: 'alt',
        label: 'ALT',
        suffix: 'U/L',
    },
    {
        fieldName: 'ptInr',
        label: 'PT-INR',
        suffix: 'U/L',
    },
    {
        fieldName: 'BCLC',
        label: 'BCLC',
    },
    {
        fieldName: 'esophagealVarices',
        label: 'Esophageal Varices',
    },
    {
        fieldName: 'liverTumorSize',
        label: 'Liver Tumor size',
        suffix: 'cm',
    },
    {
        fieldName: 'numbersOfTumor',
        label: '肝臟內的腫瘤數目',
        suffix: '顆',
    },
    {
        fieldName: 'site',
        label: '是否有肝外轉移的器官',
    },
    {
        fieldName: 'tumorSize',
        label: '轉移器官處的tumor size',
        suffix: 'cm',
    },
    {
        fieldName: 'preCT_MRI',
        label: 'CT/MRI',
        children: [
            {
                fieldName: 'preCT_MRI1',
            },
            {
                fieldName: 'preCT_MRI2',
            },
            {
                fieldName: 'preCT_MRI3',
            },
        ],
    },
    {
        fieldName: 'cycles',
        label: 'Cycles',
        children: [
            {
                fieldName: 'cycles_item1',
                suffix: 'AFP',
            },
            {
                fieldName: 'cycles_item2',
                suffix: 'ng/ml',
            },
            {
                fieldName: 'cycles_item3',
                suffix: 'PIVKA',
            },
            {
                fieldName: 'cycles_item4',
                suffix: 'mAU/mL',
            },
        ],
    },
    {
        fieldName: 'cycleTimes',
        label: 'Total cycles',
        suffix: 'times',
    },
    {
        fieldName: 'response',
        label: '病人產生response的時間日期',
    },
    {
        fieldName: 'postAfp',
        label: 'AFP',
        suffix: 'ng/mL',
    },
    {
        fieldName: 'postAlbumin',
        label: 'Albumin',
        suffix: 'g/dl',
    },
    {
        fieldName: 'postWbc',
        label: 'WBC',
        suffix: '10<sup>3</sup>/mm<sup>3</sup>',
    },
    {
        fieldName: 'postPlt',
        label: 'PLT',
        suffix: '10<sup>3</sup>/uL',
    },
    {
        fieldName: 'postTotalBilirubin',
        label: 'Total Bilirubin',
        suffix: 'mg/dL',
    },
    {
        fieldName: 'postAst',
        label: 'AST',
        suffix: 'U/L',
    },
    {
        fieldName: 'postAlt',
        label: 'ALT',
        suffix: 'U/L',
    },
    {
        fieldName: 'postPtInr',
        label: 'PT-INR',
        suffix: 'U/L',
    },
    {
        fieldName: 'postLiverTumorSize',
        label: 'Liver Tumor size',
        suffix: 'cm',
    },
    {
        fieldName: 'postNumbersOfTumor',
        label: '肝臟內的腫瘤數目',
        suffix: '顆',
    },
    {
        fieldName: 'postSite',
        label: '是否有肝外轉移的器官',
    },
    {
        fieldName: 'postTumorSize',
        label: '轉移器官處的tumor size',
        suffix: 'cm',
    },
    {
        fieldName: 'postCT_MRI',
        label: 'CT/MRI',
        children: [
            {
                fieldName: 'postCT_MRI1',
            },
            {
                fieldName: 'postCT_MRI2',
            },
            {
                fieldName: 'postCT_MRI3',
            },
        ],
    },
    {
        fieldName: 'combineTACE',
        label: 'TACE',
        children: [
            {
                fieldName: 'combineTACE_item1',
            },
        ],
    },
    {
        fieldName: 'combineRFA',
        label: 'RFA',
        children: [
            {
                fieldName: 'combineRFA_item1',
            },
        ],
    },
    {
        fieldName: 'combineSurgery',
        label: 'Surgery',
        children: [
            {
                fieldName: 'combineSurgery_item1',
            },
        ],
    },
    {
        fieldName: 'combineOther',
        label: '其他',
        children: [
            {
                fieldName: 'combineOther_item2',
            },
            {
                fieldName: 'combineOther_item_text',
                component: 'text-field',
            },
        ],
    },
    {
        fieldName: 'combineCT_MRI',
        label: 'CT/MRI',
        children: [
            {
                fieldName: 'combineCT_MRI1',
            },
            {
                fieldName: 'combineCT_MRI2',
            },
            {
                fieldName: 'combineCT_MRI3',
            },
        ],
    },
    {
        fieldName: 'impression',
        label: '我有話想說',
    },
];
