export default {
    namespaced: true,
    state: {
        target: undefined,
        aeConfirm: undefined,
        validateFunction: undefined,
        isStored: true,
        mode: 'EDIT', // EDIT / VIEW
    },
    mutations: {
        setTarget(state, change) {
            state.target = change;
        },
        setAEConfirm(state, change) {
            state.aeConfirm = change;
        },
        setFormStoredState(state, change) {
            state.isStored = change;
        },
        setMode(state, change) {
            state.mode = change;
        },
        setFormValidateFunction(state, change) {
            state.validateFunction = change;
        },
    },
};
