<template>
    <div
        v-if="!depend || (depend.data && depend.data.match(depend.rule))"
        :id="config.fieldName"
        class="question-field"
    >
        <div v-if="config.label" class="label">{{ config.label }}</div>
        <div v-if="config.prefix" class="prefix" v-html="config.prefix"></div>
        <el-select
            v-if="isEditMode"
            :value="value"
            class="input"
            :style="{ width: `${config.width}px` }"
            :placeholder="config.placeholder || ''"
            @input="$emit('input', $event)"
            @change="$emit('change', $event)"
        >
            <el-option
                v-for="item in config.selections"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
        </el-select>
        <div v-else-if="value || value === 0" class="input-preview">
            <div>{{ value }}</div>
        </div>
        <div v-if="config.suffix" class="suffix" v-html="config.suffix"></div>
    </div>
</template>

<script>
import GeneralMixins from '@/mixins/GeneralQuestionField';

export default {
    mixins: [GeneralMixins],
    created() {
        if (this.value === undefined) this.$emit('input', this.config.selections[0].value);
    }
};
</script>
