export default [
    [
        {
            component: 'overview-data',
            tableData: [
                {
                    label: 'Gender',
                    fieldName: 'gender',
                },
                {
                    label: 'Age',
                    fieldName: 'age',
                },
                {
                    label: '診斷肝癌的日期',
                    fieldName: 'diagnoseCancer',
                },
                {
                    label: 'Etiology',
                    fieldName: 'cancerCategory',
                },
                {
                    label: '先前做過治療',
                    type: 'exist',
                    sample: [
                        {
                            fieldName: 'preTACE',
                            label: 'TACE',
                        },
                        {
                            fieldName: 'preRFA',
                            label: 'RFA',
                        },
                        {
                            fieldName: 'preSurgery',
                            label: 'Surgery',
                        },
                        {
                            fieldName: 'preOther',
                            label: '其他',
                        },
                    ],
                },
                {
                    label: '肝臟相關病史',
                    fieldName: 'history',
                },
                {
                    label: 'BCLC',
                    fieldName: 'BCLC',
                },
                {
                    label: 'Esophageal Varices',
                    fieldName: 'esophagealVarices',
                },
                {
                    label: 'Liver Tumor size',
                    fieldName: 'liverTumorSize',
                    suffix: 'cm',
                },
                {
                    label: 'Numbers of tumor',
                    fieldName: 'numbersOfTumor',
                    suffix: '顆',
                },
                {
                    label: '是否有肝外轉移',
                    fieldName: 'site',
                },
                {
                    label: 'Tumor size',
                    fieldName: 'tumorSize',
                    suffix: 'cm',
                },
            ],
            tags: ['category'],
            logo: true,
            graphic: {
                image: true,
                highlight: {
                    // color: '#ffffff',
                    bg_color: '#EABB75',
                },
                data: [
                    {
                        fieldName: 'cycleTimes',
                        label: 'Total cycles = ',
                        type: 'number',
                    },
                    {
                        label: 'Maximize<br />Meaningful<br />Survival',
                        type: 'description',
                    },
                ],
            },
        },
    ],
    [
        {
            component: 'title-block',
            label:
                'Pre-treatment<br />Patient demographic',
        },
        {
            fieldName: 'gender',
            component: 'original-data',
            label: 'Gender',
        },
        {
            fieldName: 'age',
            component: 'original-data',
            label: 'Age',
        },
        {
            fieldName: 'diagnoseCancer',
            component: 'original-data',
            label: '診斷肝癌的日期',
        },
        {
            fieldName: 'cancerCategory',
            component: 'original-data',
            label: 'Etiology',
        },
        {
            component: 'desc-block',
            label:
                '先前做過的治療：',
        },
        {
            fieldName: 'preTACE',
            component: 'array-data',
            indexLabel: true,
            label: 'TACE',
            children: [
                {
                    fieldName: 'preTACE_item1',
                },
            ],
        },
        {
            fieldName: 'preRFA',
            component: 'array-data',
            indexLabel: true,
            label: 'RFA',
            children: [
                {
                    fieldName: 'preRFA_item1',
                },
            ],
        },
        {
            fieldName: 'preSurgery',
            component: 'array-data',
            indexLabel: true,
            label: 'Surgery',
            children: [
                {
                    fieldName: 'preSurgery_item1',
                },
            ],
        },
        {
            fieldName: 'preOther',
            component: 'array-data',
            indexLabel: true,
            label: '其他',
            children: [
                {
                    fieldName: 'preOther_item2',
                },
                {
                    fieldName: 'preOther_item_text',
                },
            ],
        },
        {
            fieldName: 'history',
            label: '肝臟相關病史',
            component: 'array-data',
            type: 'simple',
        },
        {
            component: 'title-block',
            label:
                '接受Tecentriq + Avastin治療之前的病人表徵',
        },
        {
            fieldName: 'afp',
            component: 'original-data',
            label: 'AFP',
            suffix: 'ng/mL',
        },
        {
            fieldName: 'albumin',
            component: 'original-data',
            label: 'Albumin',
            suffix: 'g/dl',
        },
        {
            fieldName: 'wbc',
            component: 'original-data',
            label: 'WBC',
            suffix: '10<sup>3</sup>/mm<sup>3</sup>',
        },
        {
            fieldName: 'plt',
            component: 'original-data',
            label: 'PLT',
            suffix: '10<sup>3</sup>/uL',
        },
        {
            fieldName: 'totalBilirubin',
            component: 'original-data',
            label: 'Total Bilirubin',
            suffix: 'mg/dL',
        },
        {
            fieldName: 'ast',
            component: 'original-data',
            label: 'AST',
            suffix: 'U/L',
        },
        {
            fieldName: 'alt',
            component: 'original-data',
            label: 'ALT',
            suffix: 'U/L',
        },
        {
            fieldName: 'ptInr',
            component: 'original-data',
            label: 'PT-INR',
            placeholder: '請輸入',
            suffix: 'U/L',
        },
        {
            fieldName: 'BCLC',
            component: 'original-data',
            label: 'BCLC',
        },
        {
            fieldName: 'esophagealVarices',
            component: 'original-data',
            label: 'Esophageal Varices',
        },
        {
            fieldName: 'liverTumorSize',
            component: 'original-data',
            label: 'Liver Tumor size',
            supplement: {
                label: '(請填最大顆腫瘤的size)',
            },
            suffix: 'cm',
        },
        {
            fieldName: 'numbersOfTumor',
            component: 'original-data',
            label: '肝臟內的腫瘤數目',
            suffix: '顆',
        },
        {
            fieldName: 'site',
            component: 'array-data',
            label: '是否有肝外轉移的器官',
            type: 'simple',
        },
        {
            fieldName: 'tumorSize',
            component: 'original-data',
            label: '轉移器官處的tumor size',
            suffix: 'cm',
        },
        {
            fieldName: 'preCT_MRI',
            component: 'album-display',
            label: 'CT/MRI',
            children: [
                {
                    fieldName: 'preCT_MRI1',
                    date: true,
                },
                {
                    fieldName: 'preCT_MRI2',
                    date: true,
                },
                {
                    fieldName: 'preCT_MRI3',
                    date: true,
                },
            ],
        },
    ],
    [
        {
            component: 'title-block',
            label:
                '接受Tecentriq + Avastin治療期間AFP 或 PIVKA之變化',
            depend: ['cycles', 'cycleTimes'],
        },
        {
            fieldName: 'cycles',
            component: 'array-data',
            label: 'Cycles',
            indexLabel: true,
            children: [
                {
                    fieldName: 'cycles_item1',
                },
                {
                    fieldName: 'cycles_item2',
                    prefix: 'AFP',
                    suffix: 'ng/ml',
                },
                {
                    fieldName: 'cycles_item3',
                },
                {
                    fieldName: 'cycles_item4',
                    prefix: 'PIVKA',
                    suffix: 'mAU/mL',
                },
            ],
        },
        {
            fieldName: 'cycleTimes',
            component: 'original-data',
            label: 'Total cycles',
            suffix: 'times',
        },
        {
            component: 'title-block',
            label:
                '接受Tecentriq + Avastin治療之後的病人表徵',
        },
        {
            fieldName: 'response',
            component: 'original-data',
            label: '病人產生response的時間日期',
        },
        {
            component: 'desc-block',
            label:
                '病人產生response時的生化數值：',
        },
        {
            fieldName: 'postAfp',
            component: 'original-data',
            label: 'AFP',
            suffix: 'ng/mL',
        },
        {
            fieldName: 'postAlbumin',
            component: 'original-data',
            label: 'Albumin',
            suffix: 'g/dl',
        },
        {
            fieldName: 'postWbc',
            component: 'original-data',
            label: 'WBC',
            suffix: '10<sup>3</sup>/mm<sup>3</sup>',
        },
        {
            fieldName: 'postPlt',
            component: 'original-data',
            label: 'PLT',
            suffix: '10<sup>3</sup>/uL',
        },
        {
            fieldName: 'postTotalBilirubin',
            component: 'original-data',
            label: 'Total Bilirubin',
            suffix: 'mg/dL',
        },
        {
            fieldName: 'postAst',
            component: 'original-data',
            label: 'AST',
            suffix: 'U/L',
        },
        {
            fieldName: 'postAlt',
            component: 'original-data',
            label: 'ALT',
            suffix: 'U/L',
        },
        {
            fieldName: 'postPtInr',
            component: 'original-data',
            label: 'PT-INR',
            placeholder: '請輸入',
            suffix: 'U/L',
        },
        {
            fieldName: 'postLiverTumorSize',
            component: 'original-data',
            label: 'Liver Tumor size',
            suffix: 'cm',
        },
        {
            fieldName: 'postNumbersOfTumor',
            component: 'original-data',
            label: '肝臟內的腫瘤數目',
            suffix: '顆',
        },
        {
            fieldName: 'postSite',
            label: '是否有肝外轉移的器官',
            component: 'array-data',
            type: 'simple',
        },
        {
            fieldName: 'postTumorSize',
            component: 'original-data',
            label: '轉移器官處的tumor size',
            suffix: 'cm',
            rules: {
                required: true,
            },
        },
        {
            fieldName: 'postCT_MRI',
            component: 'album-display',
            label: 'CT/MRI',
            children: [
                {
                    fieldName: 'postCT_MRI1',
                    date: true,
                },
                {
                    fieldName: 'postCT_MRI2',
                    date: true,
                },
                {
                    fieldName: 'postCT_MRI3',
                    date: true,
                },
            ],
        },
        {
            component: 'desc-block',
            label:
                'T+A治療期間是否合併其他療法：',
        },
        {
            fieldName: 'combineTACE',
            component: 'array-data',
            indexLabel: true,
            label: 'TACE',
            children: [
                {
                    fieldName: 'combineTACE_item1',
                },
            ],
        },
        {
            fieldName: 'combineRFA',
            component: 'array-data',
            indexLabel: true,
            label: 'RFA',
            children: [
                {
                    fieldName: 'combineRFA_item1',
                },
            ],
        },
        {
            fieldName: 'combineSurgery',
            component: 'array-data',
            indexLabel: true,
            label: 'Surgery',
            children: [
                {
                    fieldName: 'combineSurgery_item1',
                },
            ],
        },
        {
            fieldName: 'combineOther',
            component: 'array-data',
            indexLabel: true,
            label: '其他',
            children: [
                {
                    fieldName: 'combineOther_item2',
                },
                {
                    fieldName: 'combineOther_item_text',
                },
            ],
        },
        {
            fieldName: 'combineCT_MRI',
            component: 'album-display',
            label: 'CT/MRI',
            children: [
                {
                    fieldName: 'combineCT_MRI1',
                    date: true,
                },
                {
                    fieldName: 'combineCT_MRI2',
                    date: true,
                },
                {
                    fieldName: 'combineCT_MRI3',
                    date: true,
                },
            ],
        },
        {
            fieldName: 'impression',
            component: 'original-data',
            label: '我有話想說',
            column: true,
        },
    ],
];
