export default [
    {
        fieldName: 'date',
        label: '紀錄日期',
    },
    {
        fieldName: 'CTNumber',
        label: 'CT Number',
    },
    {
        fieldName: 'doctorAvatar',
        label: '醫師照片',
    },
    {
        fieldName: 'doctorName',
        label: '醫師姓名',
    },
    {
        fieldName: 'hospital',
        label: '醫院',
    },
    {
        fieldName: 'specialty',
        label: '科別',
    },
    {
        fieldName: 'title',
        label: '文章標題',
    },
    {
        fieldName: 'gender',
        label: 'Gender',
    },
    {
        fieldName: 'age',
        label: 'Age',
    },
    {
        fieldName: 'diagnoseCancer',
        label: '診斷肺癌的時間(年)',
    },
    {
        fieldName: 'ecog',
        label: 'ECOG',
    },
    {
        fieldName: 'smokingHistory',
        label: '抽菸史',
    },
    {
        fieldName: 'histologicalType',
        label: 'Histological Typ',
    },
    {
        fieldName: 'tDescriptorSize',
        label: 'Size (T descriptor)',
    },
    {
        fieldName: 'clinicalStaging',
        label: 'Staging (Clinical)(per 7th edition)',
    },
    {
        fieldName: 'pathologyStaging',
        label: 'Staging (Pathology)(per 7th edition)',
    },
    {
        fieldName: 'cNLaymphNodeStatus',
        label: 'Lymph node status(cN)*',
    },
    {
        fieldName: 'cNLaymphNodeStatus',
        label: 'Lymph node status(pN)*',
    },
    {
        fieldName: 'lymphNodeCount',
        label: '淋巴結數量/顆',
    },
    {
        fieldName: 'location',
        label: 'Location(依照病理報告記載)',
    },
    {
        fieldName: 'highRisk',
        label: 'Invades visceral pleura',
        children: [
            {
                fieldName: 'InvadesVisceralPleura',
            },
        ],
    },
    {
        fieldName: 'nerveInvasion',
        label: 'Nerve invasion',
    },
    {
        fieldName: 'vascularInvasion',
        label: 'Vascular invasion',
    },
    {
        fieldName: 'pdl1',
        label: 'PD-L1表現量',
        suffix: '%',
    },
    {
        fieldName: 'egfrMutationStatus',
        label: 'EGFR mutation status',
    },
    {
        fieldName: 'otherGenomicAlteration',
        label: 'Other genomic alteration',
    },
    {
        fieldName: 'operationTimeAndMethod',
        label: '手術時間',
        children: [
            {
                fieldName: 'time',
            },
        ],
    },
    {
        fieldName: 'operationTimeAndMethod',
        label: '手術術式',
        children: [
            {
                fieldName: 'method',
            },
        ],
    },
    {
        fieldName: 'adjuvantChemotherapy',
        label: 'Adjuvant chemotherapy',
    },
    {
        fieldName: 'adjuvantChemotherapyCycles',
        label: 'Adjuvant chemotherapy cycles',
    },
    {
        fieldName: 'tumorIndex',
        label: '術後化學輔助療法後之腫瘤指數(癌自禦術後免疫輔助療法前)', // TODO 待確認
    },
    {
        fieldName: 'atezolizumabCyclesAmount',
        label: 'Atezolizumab 治療cycle數',
    },
    {
        fieldName: 'cycles',
        label: 'Cycles',
        children: [
            {
                fieldName: 'cyclesNo',
                prefix: 'Cycle數',
            },
            {
                fieldName: 'tumorIndex',
            },
        ],
    },
    {
        fieldName: 'caseInquiry1',
        label: '個案提問1',
    },
    {
        fieldName: 'caseInquiry2',
        label: '個案提問2',
    },
    {
        fieldName: 'caseInquiry3',
        label: '個案提問3',
    },
    {
        fieldName: 'caseInquiry4',
        label: '個案提問4',
    },
    {
        fieldName: 'caseInquiry5',
        label: '個案提問5',
    },
];
