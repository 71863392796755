<template>
    <div v-if="validData.length" class="display-field combo-field album-picker column">
        <div v-if="config.label" class="label">
            <div class="main-label">
                <span>{{ config.label }}</span>
            </div>
            <div
                class="description-label"
                :style="{
                    'font-size': `${config.supplement && config.supplement.fontSize}px`
                }"
            >
                {{ config.supplement && config.supplement.label }}
            </div>
        </div>
        <div class="album-preview">
            <div
                v-for="(render, i) in validData"
                :key="i"
                class="combo-child"
                :class="{ fluid: validData.length === 1 }"
                @change="handleChange"
            >
                <div v-if="render.config.date" class="date">
                    {{ (render.value && render.value.date) || '-' }}
                </div>
                <img
                    v-if="render.value && render.value.url"
                    :src="render.value && render.value.url"
                    class="avatar"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    props: {
        value: undefined,
        mode: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            default: () => {}
        },
        depend: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        isEditMode() {
            return this.mode === 'EDIT';
        },
        validData() {
            const VALID = [];
            this.config.children.forEach(item => {
                if (this.data[item.fieldName]) {
                    VALID.push({
                        value: this.data[item.fieldName],
                        config: item
                    });
                }
            });
            return VALID;
        }
    },
    data() {
        return {
            data: {}
        };
    },
    created() {
        _.forEach(this.value, (value, key) => {
            this.$set(this.data, `${key}`, value);
        });
    },
    methods: {
        handleChange() {
            this.$emit('change', this.data);
        }
    }
};
</script>

<style lang="scss" scoped>
.album-picker {
    &.column {
        display: flex;
        flex-direction: column;
    }
    .album-preview {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .combo-child {
            margin: 0;
            width: 100%;
            @media screen and (min-width: 640px) {
                width: calc(50% - 10px);
            }
            &.fluid {
                width: 100%;
            }
            .date {
                width: 100%;
                margin-bottom: 20px;
                text-align: center;
            }
            img {
                width: 100%;
            }
        }
    }
}
</style>
