<template>
    <div class="key-message-display-block">
        <div class="inner">
            <div
                v-for="(render, i) in keyMessages"
                :key="i"
                class="child"
                :class="{ fluid: keyMessages.length === 1 }"
            >
                <img :src="render && render.imgUrl" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    created() {
        this.getKeyMessageList();
    },
    computed: {
        ...mapState({
            keyMessages: (state) => state.keyMessage.list,
        }),
    },
    methods: {
        ...mapActions({
            getKeyMessageList: 'keyMessage/getKeyMessageList',
        }),
    },
};
</script>

<style lang="scss" scoped>
.key-message-display-block {
    display: flex;
    flex-direction: column;
    .inner {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }
    .child {
        margin: 0;
        width: 48%;
        &.fluid {
            width: 100%;
        }
        img {
            width: 100%;
        }
    }
}
</style>
