import Api from '@/models/api';

export default {
    namespaced: true,
    state: {
        user: {},
        token: undefined,
    },
    mutations: {
        setUser(state, data) {
            state.user = data;
        },
        setToken(state, token) {
            state.token = token;
        },
    },
    actions: {
        setUserInformation({ commit }, data) {
            commit('setUser', data.user);
            commit('setToken', data.token);
            window.localStorage.setItem('token', data.token);
        },
        clearUserInformation({ commit }) {
            commit('setUser', {});
            commit('setToken', undefined);
            window.localStorage.clear();
        },
        async lineUserLogin({ dispatch, commit }, { idToken, accessToken }) {
            const RES = await Api.Auth.LineLogin({ idToken, accessToken });
            dispatch('setUserInformation', { user: RES.data.accountData, token: RES.data.token });
            commit('api/setToken', RES.data.token, { root: true });
        }
    },
    getters: {
        hasLogin(state) {
            return state.token !== undefined;
        }
    }
};
