import _ from 'lodash';

const LIMIT = 20;

export default {
    namespaced: true,
    state: {
        // sales
        caseList: [],
        // general
        query: '',
        target: undefined,
        page: 1,
        infiniteId: +new Date(),
        // filter
        filterData: {
            data: {
                normal: {},
                operator: [],
                multi: [],
            },
        },
        filterText: {
            label: 'text',
            value: undefined,
        },
    },
    mutations: {
        updateFilterText(state, change) {
            state.filterText.value = change;
        },
        setCaseList(state, data) {
            state.caseList.push(...data);
        },
        updateQuery(state, query) {
            state.query = query;
        },
        updateFilterData(state, data) {
            if (data.normal) {
                const KEY = Object.keys(data.normal)[0];
                const VALUE = data.normal[KEY];
                if (VALUE) state.filterData.data.normal[KEY] = VALUE;
                else delete state.filterData.data.normal[KEY];
            } else if (data.operator) {
                const INDEX = _.findIndex(state.filterData.data.operator,
                    (o) => o.fieldName === data.operator.fieldName);
                const VALUE = data.operator.value;
                if (INDEX >= 0) {
                    if (VALUE) state.filterData.data.operator.splice(INDEX, 1, data.operator);
                    else state.filterData.data.operator.splice(INDEX, 1);
                } else state.filterData.data.operator.push(data.operator);
            } else if (data.multi) {
                if (data.multi[0].value.length) state.filterData.data.multi = data.multi;
                else state.filterData.data.multi = [];
            }
        },
        increasePage(state) {
            state.page += 1;
        },
        updateInfiniteId(state) {
            state.infiniteId += 1;
        },
        setTarget(state, change) {
            state.target = change;
        },
        clear(state) {
            // sales
            state.caseList = [];
            // general
            state.query = '';
            state.filterData = {
                data: {
                    normal: {},
                    operator: [],
                    multi: [],
                },
            };
            state.filterText = {
                label: 'text',
                value: undefined,
            };
            state.target = undefined;
            state.page = 1;
            state.infiniteId = +new Date();
        },
        resetList(state) {
            state.page = 1;
            state.caseList = [];
            state.query = `?limit=${LIMIT}&page=${state.page}`;
        },
    },
    actions: {
        async onSearchDisplayCase({ state, commit, dispatch }) {
            const PAYLOAD = {
                category: 'Case',
                function: 'searchSalesDisplayCase',
                query: { query: state.query, data: state.filterData },
            };
            const RES = await dispatch('api/invokeSearch', PAYLOAD, { root: true });
            commit('setCaseList', RES.data.rows);
            return RES.data.rows.length;
        },
        fetchQuery({ state, commit }) {
            let text = `?limit=${LIMIT}&page=${state.page}`;
            const ITEMS = [
                state.filterText,
            ];
            ITEMS.forEach((item) => {
                if (item.label !== 'organizationId' && !item.value) return;
                if (text.length !== 1) text += `&${item.label}=${item.value}`;
                else text += `${item.label}=${item.value}`;
            });
            commit('updateQuery', text);
        },
        updateFilter({ commit, dispatch }) {
            commit('resetList');
            commit('updateInfiniteId');
            dispatch('fetchQuery');
        },
    },
};
