export default [
    {
        fieldName: 'date',
        label: '日期',
    },
    {
        fieldName: 'CTNumber',
        label: 'CT Number',
    },
    {
        fieldName: 'doctorAvatar',
        label: '醫師照片',
    },
    {
        fieldName: 'doctorName',
        label: '醫師姓名',
    },
    {
        fieldName: 'hospital',
        label: '醫院',
    },
    {
        fieldName: 'title',
        label: '案例標題',
    },
    {
        fieldName: 'frontLineType',
        label: '您好, 請問您要登入 Tecentriq 臨床案例為下列哪一類型?',
    },
    {
        fieldName: 'category',
        label: '此病人是屬於哪一類？',
    },
    {
        fieldName: '1L2L',
        label: '1L/2L',
    },
    {
        fieldName: 'PFS',
        label: 'PFS(months)',
    },
    {
        fieldName: 'OS',
        label: 'OS(months)',
        booleanLabel: 'Still alive',
    },
    {
        fieldName: 'age',
        label: 'Age',
    },
    {
        fieldName: 'gender',
        label: 'Gender',
    },
    {
        fieldName: 'otherMedicalHistory',
        label: 'Other medical history',
    },
    {
        fieldName: 'eGFR',
        label: 'eGFR',
    },
    {
        fieldName: 'ECOGPS',
        label: 'ECOG PS',
    },
    {
        fieldName: 'PDL1SP142IC',
        label: 'PDL1 SP142 IC',
    },
    {
        fieldName: 'site',
        label: 'Site',
    },
    {
        fieldName: 'pathology',
        label: 'Pathology',
    },
    {
        fieldName: 'running',
    },
    {
        fieldName: 'dateDisplayAbove',
        label: '上方日期(From)',
    },
    {
        fieldName: 'treatmentHistory',
        label: 'Treatment history',
        children: [
            {
                fieldName: 'treatmentHistory_item1',
            },
            {
                fieldName: 'treatmentHistory_item2',
            },
        ],
    },
    {
        fieldName: 'preCT_MRI',
        label: 'CT/MRI(至少上傳一張)',
        children: [
            {
                fieldName: 'preCT_MRI1',
            },
            {
                fieldName: 'preCT_MRI2',
            },
            {
                fieldName: 'preCT_MRI3',
            },
            {
                fieldName: 'preCT_MRI4',
            },
        ],
    },
    {
        fieldName: 'postCT_MRI',
        label: 'CT/MRI(至少上傳一張)',
        children: [
            {
                fieldName: 'postCT_MRI1',
            },
            {
                fieldName: 'postCT_MRI2',
            },
            {
                fieldName: 'postCT_MRI3',
            },
            {
                fieldName: 'postCT_MRI4',
            },
        ],
    },
    {
        fieldName: 'impression',
        label: '我有話想說',
    },

];
